import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { FemaleSpecificClass } from "../../../../model/FemaleSpecific.class";
import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const GynecologicalQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [testDate, setTestDate] = useState("");
  const [testType, setTestType] = useState("");
  const [testResult, setTestResult] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [
    nameAddressOfDoctorConsulted,
    setNameAddressOfDoctorConsulted
  ] = useState(undefined);

  const [testDateError, setTestDateError] = useState(false);
  const [testTypeError, setTestTypeError] = useState(false);
  const [testResultError, setTestResultError] = useState(false);
  const [diagnosisError, setDiagnosisError] = useState(false);
  const [treatmentTypeError, setTreatmentTypeError] = useState(false);
  const [
    nameAddressOfDoctorConsultedError,
    setNameAddressOfDoctorConsultedError
  ] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.femaleInsuredOnly?.gynecological?.testDate &&
      setTestDate(user.femaleInsuredOnly.gynecological.testDate);
    user?.femaleInsuredOnly?.gynecological?.testResult &&
      setTestResult(user.femaleInsuredOnly.gynecological.testResult);
    user?.femaleInsuredOnly?.gynecological?.testType &&
      setTestType(user.femaleInsuredOnly.gynecological.testType);
    user?.femaleInsuredOnly?.gynecological?.diagnosis &&
      setDiagnosis(user.femaleInsuredOnly.gynecological.diagnosis);
    user?.femaleInsuredOnly?.gynecological?.treatmentType &&
      setTreatmentType(user.femaleInsuredOnly.gynecological.treatmentType);
    user?.femaleInsuredOnly?.gynecological?.nameAddressOfDoctorConsulted &&
      setNameAddressOfDoctorConsulted(
        user.femaleInsuredOnly.gynecological.nameAddressOfDoctorConsulted
      );

    let validateSuccess = validateFields();
    if (user?.femaleInsuredOnly?.gynecological || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [
      testDate,
      testType,
      testResult,
      diagnosis,
      treatmentType,
      nameAddressOfDoctorConsulted
    ];

  const validateFields = () => {
    let validateSuccess = true;

    setTestDateError(!testDate);
    setTestTypeError(!testType);
    setTestResultError(!testResult);
    setDiagnosisError(!diagnosis);
    setTreatmentTypeError(!treatmentType);
    setNameAddressOfDoctorConsultedError(!nameAddressOfDoctorConsulted);

    if (
      !testDate ||
      !testType ||
      !testResult ||
      !diagnosis ||
      !treatmentType ||
      !nameAddressOfDoctorConsulted
    ) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const femaleSpecific = new FemaleSpecificClass(
        testDate,
        testType,
        testResult,
        diagnosis,
        treatmentType,
        nameAddressOfDoctorConsulted
      );
      const femaleSpecificObject = {};
      femaleSpecificObject[subKey] = femaleSpecific.getFemaleSpecificDetails();

      const updatedUser = {
        ...user,
        [key]: { ...user[key], ...femaleSpecificObject }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Date of test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <DatePicker
                key={testDate}
                date={testDate}
                onDateChange={event => {
                  setTestDate(event);
                }}
              />
              {testDateError && (
                <FormHelperText style={{ color: "red" }}>
                  This field is mandatory.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={testTypeError}
                helperText={testTypeError && "This field is mandatory."}
                onChange={event => {
                  setTestType(event.target.value);
                }}
                value={testType}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Result of the test done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={testResultError}
                helperText={testResultError && "This field is mandatory."}
                onChange={event => {
                  setTestResult(event.target.value);
                }}
                value={testResult}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Diagnosis</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={diagnosisError}
                helperText={diagnosisError && "This field is mandatory."}
                onChange={event => {
                  setDiagnosis(event.target.value);
                }}
                value={diagnosis}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of Treatment</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={treatmentTypeError}
                helperText={treatmentTypeError && "This field is mandatory."}
                onChange={event => {
                  setTreatmentType(event.target.value);
                }}
                value={treatmentType}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">
                Name & Address of Doctors / Clinics / Hospitals
              </Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={nameAddressOfDoctorConsultedError}
                helperText={
                  nameAddressOfDoctorConsultedError &&
                  "This field is mandatory."
                }
                onChange={event => {
                  setNameAddressOfDoctorConsulted(event.target.value);
                }}
                value={nameAddressOfDoctorConsulted}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
