import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";

import UpdatePlanSteps from "./UpdatePlanSteps";

class UpdatePlan extends Component {
  constructor(props) {
    super(props);
    this.mainRef = element => {
      this.main = element;
    };
  }

  render() {
    const { navDrawerOpen, history } = this.props;
    return (
      <main ref={this.mainRef}>
        <UpdatePlanSteps navDrawerOpen={navDrawerOpen} history={history} />
      </main>
    );
  }
}

const styles = theme => ({
  root: {}
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UpdatePlan));
