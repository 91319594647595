const CIAM_RESPONSE_CODE = {
  OTP_REQ_FAILED:
    "OTP request failed. Please cancel and retry logging in for a new OTP.",
  OTP_CODE: "OTP00",
  CIAM_KEYWORD: "_FR_CIAM_CODE_",
  NOT_AUTHENTICATED: "Not authenticated",
  UNAUTHORIZED: "unauthorized",
  GENERAL_MESSAGE: `Username or password incorrect. For a forgotten password, click "Forgot Password" to reset. If you use the common username for HR, Employee, or enrollment accounts, the password is same for all.`,

  PRUWORKS_EN_CODE: {
    code: "PW_ER_001",
    description: "You cannot log in as the enrolment exercise is closed."
  },
  INVALID_OTP: {
    code: "FR309",
    description: "Invalid OTP. Please enter the correct OTP."
  },
  MAX_OTP_FAILED: {
    code: "FR100",
    description:
      "Your account has been locked for 15mins due to incorrect OTP" +
      "\n" +
      "after 10 attempts. Please try to log in again later.",
    description_reset_password:
      "Your account has been locked for 15mins due to incorrect OTP after 10 attempts. Please try to submit your request again later."
  },
  OTP_EXPIRED: {
    code: "FR313",
    code_old: "FR105",
    description:
      "The OTP you have entered has expired." +
      "\n" +
      "Please click “Resend OTP” to request for a new OTP.",
    description_reset_password:
      "The OTP you have entered has expired." +
      "\n " +
      "Please click “Resend OTP” to request for a new OTP."
  },
  RESETPW_OTP_EXPIRED: {
    code: "PR105",
    description:
      "The OTP you have entered has expired." +
      "\n" +
      "Please cancel and reset again "
  },
  INCORRECT_PW: {
    code: "FR302"
  },
  ACCOUNT_BLOCK: {
    code: "FR109",
    description:
      "Your account has been locked for 15mins as you have exceeded the maximum attempts. Please try to log in again later.",
    description_reset_password:
      "Your account has been locked for 15mins as you have exceeded the maximum attempts. Please try to submit your request again later."
  },
  FORGEROCK_ERROR: {
    code: "FR999",
    codeReqOtp: "PW999",
    description_reqOtp:
      "OTP request failed. Please log in again to request a new OTP",
    description: "Unauthorized try to log in or reset password",
    description_otp:
      "OTP verification failed. Please log in" +
      "\n" +
      "again to request a new OTP.",
    description_reset_password:
      "OTP request failed. Please submit your request again for new OTP."
  },
  A_SUSPEND_INACTIVE: {
    pw_expired: "FR312",
    in_active_code: "FR110",
    suspend_code: "HSM03",
    description: `The password you have entered has expired. Please click on “Forgot password” below to set a new password.`,
    description_sale: `The password you have entered has expired. Please re-set your password at <a target="_blank" href="https://pse.prudential.com.sg">https://pse.prudential.com.sg</a>`
  },
  NOT_MATCH_PASSWORD_POLICY: {
    code: "FR102",
    description_login: `Your password does not meet the password policy. Please click on "Forgot Password" below to set a new password.`,
    description:
      "Unable to update the password as your password does not meet our password policy. Please follow the below criteria to set your new password."
  },
  ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD: {
    code: "PR109",
    description:
      "Your account has been blocked for 15minutes due to incorrect OTP. Please try again later."
  },
  EXCEEDED_OTP_REGENERATION: {
    code: "FR106",
    description:
      "You have reached the OTP request limit, please try to log in again",
    description_reset_password:
      "You have reached the OTP request limit, please submit your request again for new OTP"
  }
};

export default Object.freeze(CIAM_RESPONSE_CODE);
