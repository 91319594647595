import React from "react";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputAdornment,
  Input,
  TextField
} from "@mui/material";
import { questionnairesStyles } from "../questionnaire.styles";

import { COUNTRIES } from "../../../../config/app.constants";
import useForm from "./hooks/useForm";
import { InputFieldWrapper } from "../components/InputFieldWrapper.component";

/**
  @typedef {{
    nameOfActivity: string;
    country: string;
    frequency: string;
    otherCountry: string;
  }} PrivateFlyingValue
 */

/**
  @typedef {Omit<import("./hooks/useForm").FormProps<PrivateFlyingValue, keyof PrivateFlyingValue>, 'changeModifier'|'stateRules'>} PrivateFlyingQuestionnaireFormProps
 */

const activityType = [
  {
    id: 1,
    title: "Pilot"
  },
  {
    id: 2,
    title: "Passenger"
  }
];

export const stateRules = {
  nameOfActivity: "required",
  country: "required",
  frequency: "required",
  otherCountry: (val, data) => {
    if (data.country !== "Others") return true;

    return Boolean(val);
  }
};

/**
  @param {PrivateFlyingQuestionnaireFormProps} props 
 */
export const PrivateFlyingQuestionnaireForm = props => {
  const { textFieldRed } = questionnairesStyles();
  const { state, handleUpdateField, errorFields, handleKeyPress } = useForm({
    ...props,
    stateRules,
    changeModifier: (state, field, value) => {
      if (field === "country" && value !== "Others") {
        state.otherCountry = "";
      }
    }
  });

  return (
    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
      <InputFieldWrapper
        label={<Box fontWeight="bold">Country of Activity</Box>}
      >
        <FormControl variant="standard" sx={{ m: 0 }}>
          <Select
            value={state.country}
            onChange={event => {
              handleUpdateField("country", event.target.value);
            }}
            required
            error={errorFields.country.error}
          >
            {COUNTRIES.map(country => (
              <MenuItem key={country.id} value={country.title}>
                {country.title}
              </MenuItem>
            ))}
          </Select>
          {errorFields.country.error ? (
            <FormHelperText style={{ color: "red" }}>
              This field is mandatory.
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </InputFieldWrapper>
      <InputFieldWrapper
        label={<Box fontWeight="bold">Frequency of Activity</Box>}
      >
        <Input
          variant="standard"
          className={textFieldRed}
          type="number"
          required
          error={errorFields.frequency.error}
          endAdornment={
            <InputAdornment position="end">hours per year</InputAdornment>
          }
          onChange={event => {
            handleUpdateField("frequency", event.target.value);
          }}
          onKeyPress={handleKeyPress}
          value={state.frequency}
          inputProps={{ min: 1 }}
        />
        {errorFields.frequency.error ? (
          <FormHelperText style={{ color: "red" }}>
            This field is mandatory.
          </FormHelperText>
        ) : (
          ""
        )}
      </InputFieldWrapper>
      <InputFieldWrapper label={<Box fontWeight="bold">Role</Box>}>
        <FormControl variant="standard">
          <Select
            value={state.nameOfActivity}
            onChange={event => {
              handleUpdateField("nameOfActivity", event.target.value);
            }}
            required
            error={errorFields.nameOfActivity.error}
          >
            {activityType.map(activity => (
              <MenuItem key={activity.id} value={activity.title}>
                {activity.title}
              </MenuItem>
            ))}
          </Select>
          {errorFields.nameOfActivity.error ? (
            <FormHelperText style={{ color: "red" }}>
              This field is mandatory.
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </InputFieldWrapper>
      <InputFieldWrapper
        label={
          <Box
            fontWeight="bold"
            style={{
              color: state.country !== "Others" ? "grey" : "black"
            }}
          >
            Others, please specify
          </Box>
        }
      >
        <TextField
          variant="standard"
          className={textFieldRed}
          disabled={state.country !== "Others" ? true : false}
          error={errorFields.otherCountry.error}
          onChange={event => {
            handleUpdateField("otherCountry", event.target.value);
          }}
          value={state.otherCountry}
        />
        {errorFields.otherCountry.error ? (
          <FormHelperText style={{ color: "red" }}>
            This field is mandatory.
          </FormHelperText>
        ) : (
          ""
        )}
      </InputFieldWrapper>
    </Box>
  );
};
