import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { OtherMedicalConditionClass } from "../../../../model/OtherMedicalCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const EndocrineQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userEndocrine, setUserEndocrine] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.otherMedicalConditions?.endocrine &&
      setUserEndocrine(user.otherMedicalConditions.endocrine);

    let validateSuccess = validateEndocrine();
    let medicalCondition = user?.otherMedicalConditions?.endocrine;

    if (!medicalCondition || medicalCondition.length == 0) {
      setError(true);
      handleAddEndocrine([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserEndocrine(medicalCondition);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    if (userEndocrine.length > 0) {
      updateUserHealthDeclaration();
    }
  }, [userEndocrine.length]);

  const handleAddEndocrine = () => {
    setUserEndocrine([
      ...userEndocrine,
      {
        nameOfCondition: "",
        nameOfConditionError: false,
        startDate: "",
        startDateError: false,
        endDate: "",
        endDateError: false,
        testTypeAndResult: "",
        testTypeAndResultError: false,
        treatmentType: "",
        treatmentTypeError: false,
        nameAddressOfDoctorConsulted: "",
        nameAddressOfDoctorConsultedError: false
      }
    ]);
  };

  const handleUpdateField = (field, index, value) => {
    userEndocrine[index][field] = value;
    setUserEndocrine([...userEndocrine]);
  };

  const handleUpdateDate = (field, index, event) => {
    userEndocrine[index][field] = event;
    setUserEndocrine([...userEndocrine]);
  };

  const validateEndocrine = () => {
    let validateSuccess = true;
    let errorDisplay = "";

    if (userEndocrine.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, subKey, true);
      errorDisplay = "Please enter at least 1 medical condition";
    }

    //mark field error
    userEndocrine.map(userEndocrine => {
      userEndocrine.nameOfConditionError = false;
      userEndocrine.startDateError = false;
      userEndocrine.endDateError = false;
      userEndocrine.testTypeAndResultError = false;
      userEndocrine.treatmentTypeError = false;
      userEndocrine.nameAddressOfDoctorConsultedError = false;

      if (!userEndocrine.nameOfCondition) {
        userEndocrine.nameOfConditionError = true;
      }

      if (!userEndocrine.startDate) {
        userEndocrine.startDateError = true;
      }

      if (!userEndocrine.endDate) {
        userEndocrine.endDateError = true;
      }

      if (!userEndocrine.testTypeAndResult) {
        userEndocrine.testTypeAndResultError = true;
      }

      if (!userEndocrine.treatmentType) {
        userEndocrine.treatmentTypeError = true;
      }

      if (!userEndocrine.nameAddressOfDoctorConsulted) {
        userEndocrine.nameAddressOfDoctorConsultedError = true;
      }
    });

    let errorObject = userEndocrine.find(userEndocrine => {
      return (
        userEndocrine.nameOfConditionError ||
        userEndocrine.startDateError ||
        userEndocrine.endDateError ||
        userEndocrine.testTypeAndResultError ||
        userEndocrine.treatmentTypeError ||
        userEndocrine.nameAddressOfDoctorConsultedError
      );
    });

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserEndocrine([...userEndocrine]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateEndocrine();
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, subKey, false);

      let endocrine = [];
      let endocrineObject = {};

      userEndocrine.map(userEndocrine => {
        const medicalCondition = new OtherMedicalConditionClass(
          userEndocrine.nameOfCondition,
          userEndocrine.startDate,
          userEndocrine.endDate,
          userEndocrine.testTypeAndResult,
          userEndocrine.treatmentType,
          userEndocrine.nameAddressOfDoctorConsulted
        );
        endocrineObject = medicalCondition.getOtherMedicalConditionDetails();
        endocrine.push(endocrineObject);
      });

      const updatedUser = {
        ...user,
        [key]: { ...user[key], endocrine }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleRemove = index => {
    userEndocrine.splice(index, 1);
    setUserEndocrine([...userEndocrine]);

    validateEndocrine();
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      {userEndocrine.map((endocrine, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Medical Condition {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button onClick={e => handleRemove(index)}>
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Name of Condition</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={endocrine.nameOfConditionError}
                      helperText={
                        endocrine.nameOfConditionError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameOfCondition",
                          index,
                          event.target.value
                        );
                      }}
                      value={endocrine.nameOfCondition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Start Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={endocrine.startDate}
                      date={endocrine.startDate}
                      onDateChange={event => {
                        handleUpdateDate("startDate", index, event);
                      }}
                    />
                    {endocrine.startDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">End Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={endocrine.endDate}
                      date={endocrine.endDate}
                      onDateChange={event => {
                        handleUpdateDate("endDate", index, event);
                      }}
                    />
                    {endocrine.endDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of test(s) done and result</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={endocrine.testTypeAndResultError}
                      helperText={
                        endocrine.testTypeAndResultError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "testTypeAndResult",
                          index,
                          event.target.value
                        );
                      }}
                      value={endocrine.testTypeAndResult}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of Treatment</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={endocrine.treatmentTypeError}
                      helperText={
                        endocrine.treatmentTypeError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "treatmentType",
                          index,
                          event.target.value
                        );
                      }}
                      value={endocrine.treatmentType}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">
                      Name & Address of Doctors/ Clinics/ Hospitals
                    </Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={endocrine.nameAddressOfDoctorConsultedError}
                      helperText={
                        endocrine.nameAddressOfDoctorConsultedError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameAddressOfDoctorConsulted",
                          index,
                          event.target.value
                        );
                      }}
                      value={endocrine.nameAddressOfDoctorConsulted}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}

      <Button
        // className={addPrivateFlying}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddEndocrine}
      >
        <FormHelperText
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "1em",
            textTransform: "none"
          }}
        >
          Add Medical Condition
        </FormHelperText>
      </Button>

      <br />
      <br />
      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>

      <TableRow>
        <br />
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>

      <br />
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
