import { useEffect, useRef, useState } from "react";
import { useValidateFields } from "../../hooks/useValidate";

/**
  @template {object} T
  @template {keyof T} K
  @typedef {{
    value: T;
    stateRules: import("../../hooks/useValidate").StateRules<K, T>
    validateOnMount: boolean;
    onChange: (value: T) => void;
    onValidate: import("../../hooks/useValidate").OnValidate
    changeModifier?: (state: T, field: K, value: unknown) => void
  }} FormProps
 */

/**
  @template {object} T
  @template {keyof T} K
  @param {FormProps<T, K>} props 
 */
const useForm = props => {
  const { validate, errorFields } = useValidateFields(props.stateRules, {
    onValidate: props.onValidate
  });

  const [state, setState] = useState(() => props.value);
  const stateRef = useRef(state);

  /**
   * @param {K} field
   * @param {unknown} value
   */
  const handleUpdateField = (field, value) => {
    const newState = {
      ...state,
      [field]: value
    };
    props?.changeModifier(newState, field, value);

    validate(newState);
    setState(newState);
    props.onChange(newState);
  };

  const handleKeyPress = event => {
    const { key } = event;

    // Disable input of "e" and "-"
    if (key === "e" || key === "-") {
      event.preventDefault();
    }

    // Ensure minimum value is greater than 0
    const currentValue = event.target.value;
    const newValue =
      event.key === "Backspace"
        ? currentValue.slice(0, -1)
        : currentValue + event.key;
    const numericValue = Number(newValue);

    if (numericValue <= 0) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (props.validateOnMount) validate(stateRef.current);
  }, [props.validateOnMount]);

  return {
    errorFields,
    state,
    setState,
    handleUpdateField,
    validate,
    handleKeyPress
  };
};

export default useForm;
