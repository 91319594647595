const ENUMS = {
  SCOPE_TYPE: {
    TC_W: "tc:w",
    OTP_W: "otp:w",
    DASHBOARD_ENROL_R: "dashboard[enrol]:r",
    DOCUMENT_ENROL_R: "document[enrol]:r",
    PLAN_W: "plan:w",
    PLAN_R: "plan:r"
  },
  ACCESSIBLE_ROLES: "ENROLMENT",
  APP_TYPE: {
    ENROLMENT: "ENROLMENT"
  },
  P_CODE: {
    UOB_ASSISTED: "UOB_ASSISTED",
    PRUAGENCY: "PRUAGENCY",
    PRUAGENCYMED: "PRUAGENCYMED",
    SCB_ASSISTED: "SCB_ASSISTED",
    UOB_TEAM_2: "UOB_TEAM_2",
    UOBEXT: "UOBEXT",
    PACS: "PACS",
    UOB: "UOB",
    SIA: "SIA",
    SIA_SAL: "SIA_SAL",
    SIA_SPL: "SIA_SPL",
    SIA_TTTPL: "SIA_TTTPL",
    SIA_SECL: "SIA_SECL",
    SIA_KPL: "SIA_KPL",
    SIA_SCOOT: "SIASCOOT",
    RI: "RI",
    ASSOCIATE: "Associate",
    SENIOR_EXECEUTIVE: "Senior Executive"
  },
  CATEGORY_CODE: {
    VIP: "planvip",
    PLAN1: "plan1",
    PLAN2: "plan2",
    PLAN4A: "plan4a",
    PLAN4B: "plan4b",
    PLAN5A: "plan5a",
    PLAN5B: "plan5b",
    PLAN6A: "plan6a",
    PLAN6B: "plan6b"
  },
  PLANS_TYPE: {
    BENEFITS: "BENEFITS",
    PREMIUM: "PREMIUM",
    GROUP_DISCOUNT: "GROUP_DISCOUNT"
  },
  PLANS_CODE: {
    HDF: "HDF",
    GOSP: "GOSP",
    GCCA: "GCCA",
    GAMR: "GAMR",
    GEMM: "GEMM",
    GTLB: "GTLB",
    GPRU: "GPRU",
    GDEN: "GDEN",
    GHSB: "GHSB",
    GADD: "GADD"
  },
  STAGES: {
    ESTIMATE: "ESTIMATE",
    EMPLOYEE: "EMPLOYEE",
    PROPOSAL: "PROPOSAL",
    SUBMISSION: "SUBMISSION",
    SUBMITTED: "SUBMITTED",
    INFORCE: "INFORCE" //PROCESSED (on local)
  },
  STATUS: {
    PENDING: "PENDING",
    SUBMITTED: "SUBMITTED",
    PENDING_REGISTRATION: "PENDING_REGISTRATION"
  },
  FILE_EXTENSION: {
    PDF: "pdf",
    XLSX: "xlsx",
    OTEHRS: "others"
  },
  COMPONENT: {
    FIND_DOC_GP_TAB: "FIND_DOC_GP_TAB",
    FIND_DOC_SP_TAB: "FIND_DOC_SP_TAB",
    DASHBOARD_MY_BENEFIT_GRID: "DASHBOARD_MY_BENEFIT_GRID"
  },
  VAS_PROMOCODE: {
    EYESIGHT: {
      CODE1: "#eyesightexclusive01",
      CODE2: "#eyesightexclusive02",
      CODE3: "#eyesightexclusive03"
    },
    IWELLNESS: {
      CODE1: "#i-wellnessexclusive01",
      CODE2: "#i-wellnessexclusive02",
      CODE3: "#i-wellnessexclusive03"
    },
    CRAFTNCULTURE: {
      CODE1: "#Craft&Cultureexclusive01",
      CODE2: "#Craft&Cultureexclusive02"
    },
    CLASSPASS: {
      CODE1: "#classpassexclusive01",
      CODE2: "#classpassexclusive02"
    }
  },
  SCREEN_NAME: {
    DOCUMENT: "DOCUMENT",
    REVIEW: "REVIEW"
  },
  VALID_CLIENT_NAME: ["uobext", "default"]
};

export default Object.freeze(ENUMS);
