import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { InsuranceArrangementClass } from "../../../../model/InsuranceArrangement.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const InsuranceArrangementQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [dateOfApplication, setDateOfApplication] = useState("");
  const [typeOfApplication, setTypeOfApplication] = useState("");
  const [reason, setReason] = useState("");

  const [dateOfApplicationError, setDateOfApplicationError] = useState(false);
  const [typeOfApplicationError, setTypeOfApplicationError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.insuranceArrangement?.dateOfApplication &&
      setDateOfApplication(user.insuranceArrangement.dateOfApplication);
    user?.insuranceArrangement?.typeOfApplication &&
      setTypeOfApplication(user.insuranceArrangement.typeOfApplication);
    user?.insuranceArrangement?.reason &&
      setReason(user.insuranceArrangement.reason);

    let validateSuccess = validateFields();

    if (user?.insuranceArrangement || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [dateOfApplication, typeOfApplication, reason];

  const validateFields = () => {
    let validateSuccess = true;

    setDateOfApplicationError(!dateOfApplication);
    setTypeOfApplicationError(!typeOfApplication);
    setReasonError(!reason);

    if (!dateOfApplication || !typeOfApplication || !reason) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const insuranceArrangement = new InsuranceArrangementClass(
        dateOfApplication,
        typeOfApplication,
        reason
      );

      const insuranceArrangementObject = {};
      insuranceArrangementObject[
        subKey
      ] = insuranceArrangement.getInsuranceArrangementDetails();

      const updatedUser = {
        ...user,
        ...insuranceArrangementObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Date of Application</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <DatePicker
                key={dateOfApplication}
                date={dateOfApplication}
                onDateChange={event => {
                  setDateOfApplication(event);
                }}
              />
            </Grid>
            {dateOfApplicationError && (
              <FormHelperText style={{ color: "red" }}>
                This field is mandatory.
              </FormHelperText>
            )}
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of Application</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={typeOfApplicationError}
                value={typeOfApplication}
                helperText={
                  typeOfApplicationError && "This field is mandatory."
                }
                onChange={event => {
                  setTypeOfApplication(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Reason for Special Terms</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={reasonError}
                helperText={reasonError && "This field is mandatory."}
                value={reason}
                onChange={event => {
                  setReason(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
