import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

import { useSelector } from "react-redux";

import { Questionnaires } from "../../../config/Questionnaire.config";

import { selectHealthDeclarationOfUsers } from "../../../redux/health-declaration/hdf.selectors";
import { questionnairesStyles } from "../questionnaire/questionnaire.styles";

export const convertCamelCaseToSentenceCase = camelCaseText => {
  const sentenceString = camelCaseText.replace(/([A-Z])/g, " $1");
  return sentenceString.charAt(0).toUpperCase() + sentenceString.slice(1);
};

function renderSubQ(subQ, subQuestionUserRender, userData) {
  const { full_name } = subQuestionUserRender;
  const specialKeys = {
    nationality: "otherNationality",
    countryOfResidence: "otherCountryOfResidence"
  };
  const excludeDisplayKey = [
    "fullName",
    "dateOfBirth",
    "gender",
    "maritalStatus",
    "idNo",
    "otherNationality",
    "otherCountryOfResidence"
  ];

  let displayName = "[" + full_name + "]: ";

  if (Array.isArray(subQ)) {
    let displayArray = [];
    for (const element of subQ) {
      let value = "";
      Object.keys(element).map(subQKey => {
        let isShowing = true;

        if (subQKey == "otherCountry" || subQKey.slice(-5) === "Error") {
          isShowing = false;
        }

        if (
          isShowing &&
          (element[subQKey] != undefined || element[subQKey] != null)
        ) {
          subQKey === "country" && element[subQKey] === "Others"
            ? (value +=
                element[subQKey] + " - " + element["otherCountry"] + "; ")
            : (value += element[subQKey] + "; ");
        }
      });
      value = value.substring(0, value.length - 2);
      displayArray.push(value);
    }

    return (
      <Typography>
        {displayName}

        {displayArray.map((e, i) => (
          <div key={i}>
            {e}
            <br />
          </div>
        ))}
      </Typography>
    );
  } else {
    let value = "";
    Object.keys(subQ).map(subQKey => {
      let isShowing = true;
      const specialKey = specialKeys[subQKey];

      if (excludeDisplayKey.includes(subQKey)) {
        isShowing = false;
      } else if (subQKey == "occupation") {
        isShowing = subQ["fullName"] == userData.fullName ? false : true;
      }

      if (isShowing && (subQ[subQKey] != undefined || subQ[subQKey] != null)) {
        if (specialKey && subQ[subQKey] === "Others") {
          value += `${subQ[subQKey]} - ${subQ[specialKey]};`;
        } else {
          value += `${subQ[subQKey]};`;
        }
      }
    });
    value = value.substring(0, value.length - 2);
    return <Typography>{displayName + value}</Typography>;
  }
}

export const SummaryTable = props => {
  const { summarySubQuestions } = questionnairesStyles();
  const { user, userData } = props;
  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);

  return (
    <Table id="summary-table">
      <TableHead id="summary-table-header">
        <TableRow>
          <TableCell style={{ width: "60%" }}>
            <Typography>Questions</Typography>
          </TableCell>

          {/* <TableCell>
            <Typography>
              {user.fullName}
              <br />
              {"(Self)"}
            </Typography>
          </TableCell> */}
          {user.length > 0 &&
            user.map(hdfUser => {
              const { full_name, id, person_type } = hdfUser;
              return (
                <TableCell key={id}>
                  <Typography align="center">
                    {full_name}
                    <br />
                    {person_type === "EMPLOYEE"
                      ? "(Self)"
                      : person_type === "SPOUSE"
                      ? "(Spouse)"
                      : "(Child)"}
                  </Typography>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>

      <TableBody id="summary-table-body">
        {Questionnaires.map(Questionnaire => {
          const { id, title, subQuestions, key: questionKey } = Questionnaire;
          return (
            <>
              <TableRow key={id}>
                <TableCell>
                  <Typography>
                    <b>{title}</b>
                  </Typography>
                </TableCell>
              </TableRow>
              {subQuestions.length > 0 &&
                subQuestions.map(subQuestion => {
                  const { id, title, key } = subQuestion;
                  let subQuestionRender = [];
                  let subQuestionUserRender = [];
                  return (
                    <>
                      <TableRow key={id} sx={{ "& td": { border: 0 } }}>
                        <TableCell
                          id="sub-question-title"
                          style={{ width: "60%" }}
                        >
                          {title}
                        </TableCell>

                        {healthDeclarationOfUsers.map(user => {
                          if (
                            questionKey == "extremeSportsEngagement" ||
                            questionKey == "otherMedicalConditions" ||
                            questionKey == "femaleInsuredOnly"
                          ) {
                            if (user[questionKey]?.[key]) {
                              subQuestionUserRender.push(user);
                              subQuestionRender.push(user[questionKey][key]);
                              return <TableCell align="center">YES</TableCell>;
                            }
                          } else if (user[questionKey]) {
                            subQuestionUserRender.push(user);
                            subQuestionRender.push(user[questionKey]);
                            return questionKey != "personalDetails" ? (
                              <TableCell align="center">YES</TableCell>
                            ) : (
                              <></>
                            );
                          }
                          return <TableCell align="center">NO</TableCell>;
                        })}
                      </TableRow>
                      {subQuestionRender.length > 0 && (
                        <TableRow key={id} sx={{ "& td": { border: 0 } }}>
                          <TableCell
                            id="sub-question-title"
                            style={{ width: "60%" }}
                          ></TableCell>
                          <TableCell
                            colSpan={64}
                            className={summarySubQuestions}
                          >
                            {subQuestionRender.map((subQ, idx) => {
                              return renderSubQ(
                                subQ,
                                subQuestionUserRender[idx],
                                userData
                              );
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};
