export class PregnancyConditionClass {
  constructor(months) {
    this.months = months;
  }

  getPregnancyConditionDetails = () => {
    return {
      months: this.months
    };
  };
}
