import Login from "../containers/Login/Login";
import ResetPassword from "../containers/Login/ResetPassword";
import Register from "../containers/Login/Register";
//Authenticated
import Enums from "../utils/Enums";
import Auth from "../containers/Auth";

import Dashboard from "../containers/Auth/Dashboard";
import DashboardMain from "../containers/Auth/Dashboard/DashboardMain";
import EnroleDetails from "../containers/Auth/EnroleDetails";
import { menuIcons } from "../assets";
import UpdatePlan from "../containers/Auth/UpdatePlan";
import ViewPlan from "../containers/Auth/ViewPlan";
import Confirmation from "../containers/Auth/UpdatePlan/UpdatePlanSteps/containers/Confirmation";

//TODO: to move pages out of _Placeholder as we build the actual
import NotFound from "../containers/Auth/_Placeholder/NotFound";
import MyDocuments from "../containers/Auth/MyDocuments";
import MaintenancePage from "../containers/MaintenancePage";

const routes = [
  {
    path: "/",
    exact: true,
    component: Login
  },
  {
    path: "/resetPassword",
    component: ResetPassword
  },
  {
    path: "/createPassword",
    component: ResetPassword
  },
  {
    path: "/maintenance",
    component: MaintenancePage
  },
  {
    path: "/registration/:id",
    component: Register
  },
  {
    path: "/auth",
    component: Auth,
    routes: [
      {
        path: "/auth/dashboard",
        name: "Dashboard",
        component: Dashboard,
        access: Enums.SCOPE_TYPE.DASHBOARD_ENROL_R,
        menu: {
          show: true,
          image: menuIcons.home
        },
        routes: [
          {
            path: "/auth/dashboard",
            name: "Dashboard",
            exact: true,
            access: Enums.SCOPE_TYPE.DASHBOARD_ENROL_R,
            component: DashboardMain
          },
          {
            path: "/auth/dashboard/updatePlan",
            name: "UpdatePlan",
            exact: true,
            access: Enums.SCOPE_TYPE.PLAN_W,
            component: UpdatePlan
          },
          {
            path: "/auth/dashboard/enroleDetails",
            name: "EnroleDetails",
            exact: true,
            access: Enums.SCOPE_TYPE.DASHBOARD_ENROL_R,
            component: EnroleDetails
          }
        ]
      },
      {
        path: "/auth/viewPlan",
        name: "View Your Plan",
        component: ViewPlan,
        access: Enums.SCOPE_TYPE.PLAN_R
      },
      {
        path: "/auth/registerConfirmation",
        name: "Registration Confirmation",
        component: Confirmation,
        access: Enums.SCOPE_TYPE.PLAN_R
      },
      {
        path: "/auth/myDocuments",
        name: "Your Documents",
        component: MyDocuments,
        access: Enums.SCOPE_TYPE.DOCUMENT_ENROL_R,
        menu: {
          show: true,
          image: menuIcons.viewDoc
        }
      }
    ]
  },
  {
    component: NotFound
  }
];

export default routes;
