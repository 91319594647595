import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

const styles = theme => ({
  root: { padding: 30 },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },

  field: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000"
  },
  header: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000",
    fontWeight: "bold"
  },

  label: {
    fontSize: "18px",
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.388636px",
    color: "rgba(0, 0, 0, 0.54)"
    // color: '#6B6A6D'
  }
});

class MyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  render() {
    const { classes, user } = this.props;
    const gender = gender => {
      switch (gender) {
        case "M":
          return "Male";
          break;
        case "F":
          return "Female";
          break;
        default:
          return "No Status Found";
      }
    };
    const marriedStatus = status => {
      switch (status) {
        case "M":
          return "Married";
          break;
        case "S":
          return "Single";
          break;
        case "W":
          return "Widowed";
          break;
        case "D":
          return "Divorced";
          break;
        default:
          return "No Status Found";
      }
    };
    const parseNationality = (nationality, props) => {
      return (
        props.intl.messages["user.nationality." + nationality] || nationality
      );
    };

    return (
      <div className={classes.root}>
        <div>
          <Typography
            component={"span"}
            className={classes.header}
            style={{ width: "50%", display: "block", float: "left" }}
          >
            Your Information
          </Typography>
          {user.enable_self_registration && (
            <Tooltip title="Edit" placement="bottom">
              <Typography
                component={"span"}
                className={classes.header}
                style={{ width: "10%", display: "block", float: "right" }}
              >
                <Button
                  variant="contained"
                  style={{ float: "right" }}
                  aria-label="edit"
                  className={classes.button}
                  onClick={() => {
                    this.props.history.push(
                      "/auth/dashboard/enroleDetails",
                      "Save"
                    );
                  }}
                >
                  <EditIcon />
                </Button>
              </Typography>
            </Tooltip>
          )}
        </div>

        <Grid container spacing={"40px"}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={classes.label}>
              Employee Full Name
            </Typography>
            <Typography component={"span"} className={classes.field}>
              {user.fullName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={classes.label}>
              NRIC/FIN
            </Typography>
            <Typography component={"span"} className={classes.field}>
              {user.passport_nric}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={classes.label}>
              Date of Birth
            </Typography>
            <Typography component={"span"} className={classes.field}>
              {user.dob}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={"40px"}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={classes.label}>
              Email Address
            </Typography>
            <Typography component={"span"} className={classes.field}>
              {user.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography component={"span"} className={classes.label}>
              Marital Status
            </Typography>
            <Typography component={"span"} className={classes.field}>
              {marriedStatus(user.marital_status)}
            </Typography>
          </Grid>
          {user.client_name.config.hideNoOfDependant !== "True" && (
            <Grid item xs={12} sm={6} md={4}>
              <Typography component={"span"} className={classes.label}>
                No. of Dependants
              </Typography>
              <Typography component={"span"} className={classes.field}>
                {user.dependencies.length}
              </Typography>
            </Grid>
          )}
        </Grid>
        {user.enable_self_registration && (
          <Grid container spacing={"40px"}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography component={"span"} className={classes.label}>
                Gender
              </Typography>
              <Typography component={"span"} className={classes.field}>
                {gender(user.gender)}
              </Typography>
            </Grid>
            {this.props.user.client_name.config.hideEmployeeNo !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Employee No.
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {user.employee_no}
                </Typography>
              </Grid>
            )}
            {this.props.user.client_name.config.hideCountry !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Country of Citizenship
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {parseNationality(user.nationality, this.props)}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {user.enable_self_registration && (
          <Grid container spacing={"40px"}>
            {user.client_name.config.hideWeightAndHeight !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Height (in cm)
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {user.height}
                </Typography>
              </Grid>
            )}
            {user.client_name.config.hideWeightAndHeight !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Weight (in kg)
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {user.weight}
                </Typography>
              </Grid>
            )}
            {user.client_name.config.hideOccupation !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Occupation
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {user.occupation}
                </Typography>
              </Grid>
            )}

            {user.client_name.config.showDesignation === "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography component={"span"} className={classes.label}>
                  Designation
                </Typography>
                <Typography component={"span"} className={classes.field}>
                  {user.designation === "FC"
                    ? "FC/WM/AL/FAL"
                    : user.designation}
                </Typography>
              </Grid>
            )}
            {user.client_name.config.showDesignation === "True" &&
              (user.designation === "FC" || user.designation === "IBFC") && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography component={"span"} className={classes.label}>
                    FC Code
                  </Typography>
                  <Typography component={"span"} className={classes.field}>
                    {user.fc_code}
                  </Typography>
                </Grid>
              )}
            {user.client_name.config.showDesignation === "True" &&
              user.designation === "Secretary & Admin Staff" && (
                <Grid item xs={12} sm={6} md={4}>
                  <Typography component={"span"} className={classes.label}>
                    Agency Unit
                  </Typography>
                  <Typography component={"span"} className={classes.field}>
                    {user.agency_unit}
                  </Typography>
                </Grid>
              )}
          </Grid>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(MyDetails)));
