import React, { ChangeEventHandler } from "react";
import { Box, FormHelperText, TextField } from "@mui/material";
import { questionnairesStyles } from "../questionnaire.styles";
import { InputFieldWrapper } from "./InputFieldWrapper.component";

/**
  @typedef {{
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    error?: boolean;
  }} OtherFieldProps
 */

/**
 * @param {OtherFieldProps} props
 */
const OtherField = ({ value, onChange, disabled, error }) => {
  const { textFieldRed } = questionnairesStyles();

  const labelColor = disabled ? "grey" : "black";

  /** @type {ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>}  */
  const handleChange = ev => {
    onChange?.(ev.currentTarget.value);
  };

  return (
    <InputFieldWrapper
      label={
        <Box fontWeight="bold" style={{ color: labelColor }}>
          Others, please specify
        </Box>
      }
    >
      <TextField
        variant="standard"
        className={textFieldRed}
        disabled={disabled}
        error={error}
        onChange={handleChange}
        value={value}
      />
      {error ? (
        <FormHelperText style={{ color: "red" }}>
          This field is mandatory.
        </FormHelperText>
      ) : (
        ""
      )}
    </InputFieldWrapper>
  );
};

export default OtherField;
