import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { alert } from "../assets";

class AlertTopBar extends Component {
  constructor(props) {
    super(props);
  }

  alertType = classes => {
    let type = {
      error: {
        text: "Error",
        icon: (
          <Icon
            classes={{
              root: classes.icon
            }}
          >
            warning
          </Icon>
        )
      },
      tip: {
        text: "Tips",
        icon: (
          <Avatar
            classes={{
              root: classes.icon
            }}
            alt="tips-icon"
            src={alert.tip}
          />
        )
      }
    };
    return { TYPE: type };
  };

  iconStyle = (type, isBanner) => {
    const { classes } = this.props;
    if (isBanner == true) {
      return classes.iconTipBanner;
    }
    switch (type) {
      case "tip":
        return classes.iconTip;
      case "error":
        return classes.iconError;
    }
  };

  renderListDescription = () => {
    const { listDescription, type } = this.props;
    let listDescriptionComponent = listDescription.map((value, index) => {
      if (type == "tip") {
        return (
          <li key={index}>
            <Typography variant="caption">{value}</Typography>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <Typography variant="caption">
              {value.error || value.msg}
            </Typography>
          </li>
        );
      }
    });
    return listDescriptionComponent;
  };

  render() {
    const {
      classes,
      type,
      listDescription,
      description,
      displayLogo
    } = this.props;
    let alertType = type || "tip";
    const { TYPE } = this.alertType(classes);
    return (
      <Paper classes={{ root: classes.root }} elevation={0}>
        <div
          className={classNames(
            classes.icon,
            this.iconStyle(alertType, displayLogo)
          )}
        >
          <div>{TYPE[alertType].icon}</div>
        </div>
        <div className={classes.content}>
          {displayLogo == true ? (
            <div className={classes.pulseFont}>{this.props.title}</div>
          ) : (
            <Typography variant="body2" className={classes.title}>
              {this.props.title}
            </Typography>
          )}
          {listDescription && (
            <ul className={classes.listDescription}>
              {this.renderListDescription()}
            </ul>
          )}
          {description && (
            <Typography variant="caption">{this.props.description}</Typography>
          )}
        </div>
      </Paper>
    );
  }
}
const styles = theme => ({
  root: {
    display: "flex",
    borderRadius: 2
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 40,
    flex: 0.075,
    color: theme.colors.secondary.white,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3
  },
  listDescription: {
    paddingLeft: 14,
    marginTop: 0,
    marginBottom: 0
  },
  title: {
    fontWeight: 700
  },
  iconTip: {
    backgroundColor: theme.colors.tip,
    border: `2px solid ${theme.colors.tip}`,
    borderRight: 0
  },
  iconTipBanner: {
    backgroundColor: theme.colors.primary.red,
    border: `2px solid ${theme.colors.primary.red}`,
    borderRight: 0
  },
  iconError: {
    backgroundColor: theme.colors.error,
    border: `2px solid ${theme.colors.error}`,
    borderRight: 0
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 14,
    borderLeft: 0,
    border: `2px solid ${theme.colors.blackScale.black70}`,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    flex: 1
  },
  pulseFont: {
    fontFamily: "OpenSans-Bold"
  }
});

export default withStyles(styles, { withTheme: true })(AlertTopBar);
