import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MessageFormat from "messageformat";
import VerifyDetails from "./containers/VerifyDetails";
import SelectPlan from "./containers/SelectPlan";
import SelectDependentPlan from "./containers/SelectDependentPlan";
import ReviewConsent from "./containers/ReviewConsent";
import Confirmation from "./containers/Confirmation";
import Grid from "@mui/material/Grid";
import { appActions, userActions } from "../../../../actions";
import SnackBar from "../../../../components/SnackBar";
import LoadingPopup from "../../../../components/LoadingPopup";
import Tooltip from "@mui/material/Tooltip";
import SimpleConfirmDialog from "../../../../components/SimpleConfirmDialog";
import FillHDFForm from "./containers/FillHDFForm";

import { QuestionnairesComponent } from "../../../../components/health-declaration-form/questionnaires/Questionnaires.component";
import { selectIsPaymentEnabled } from "../../../../selectors/ReviewAndConsentSelectors";

const styles = theme => ({
  root: {
    marginTop: "20px"
  },
  active: {
    color: "#ED1C24"
  },
  button: {
    marginLeft: 20,
    marginRight: 20
  },
  buttonPosition: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    padding: 20,
    position: "sticky",
    bottom: 0,
    zIndex: 1
  },
  backButton: {
    marginLeft: 20,
    marginRight: 20,
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "100px",
    color: "#333333"
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  fontBottom: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#6B6A6D"
  },
  fontBottomPremium: {
    fontSize: "16px",
    lineHeight: "40px",
    color: "#000000"
  },
  premiumText: {
    color: "#333333",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "40px",
    alignItems: "center"
  }
});

const steps = [
  "Verify Your Details",
  "Select Your Plan",
  "Review and Consent",
  "Confirmation"
];

function getSteps() {
  return steps;
}

class UpdatePlanSteps extends Component {
  state = {
    completed: new Set(),
    skipped: new Set()
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      activeStep: this.props.user.activeStep || 0,
      premium: 0,
      premiumDep: 0,
      planSelected: "",
      enableNext: this.props.enableNext,
      openConfirmDialog: false,
      snackBarErrorShow: false,
      tabValue: 0,
      // currentHDFUsers: [],

      hdfQuestionnaireCountErrorState: false,
      scubaDivingErrorState: false,
      alcoholConsumptionErrorState: false,
      drugAlcoholAddictionErrorState: false,
      mountaineeringErrorState: false,
      otherExtremeSportsErrorState: false,
      privateFlyingErrorState: false,
      racingErrorState: false,
      skyDivingErrorState: false,
      gynecologicalErrorState: false,
      otherFemaleOrganIssuesErrorState: false,
      pAPSmearErrorState: false,
      pregnancyConditonErrorState: false,
      pregnancySufferingErrorState: false,
      healthScreeningErrorState: false,
      insuranceArrangementErrorState: false,
      bowelErrorState: false,
      cancerTumourErrorState: false,
      endocrineErrorState: false,
      eyeHearingSpeechErrorState: false,
      gallBladderErrorState: false,
      heartErrorState: false,
      kidneyErrorState: false,
      nervousMentalErrorState: false,
      otherIllnessErrorState: false,
      respiratoryErrorState: false,
      severeInjuryErrorState: false,
      recentHealthConditionErrorState: false,
      smokingHabitsErrorState: false,
      stdsHistoryErrorState: false,
      otherInformationErrorState: false,
      familyMedicalHistoryErrorState: false,

      allValidationSuccess: false
    };
    this.mainRef = element => {
      this.main = element;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user.client_name.config.requiredFullHdf) {
      if (this.state.activeStep === 2) {
        if (this.props.hdf.users.length === 0) {
          this.setState({
            activeStep: 3
          });
          this.props.saveActiveStep(3);
        }
      }
    }
  }

  handleHDFValidation = (noOfQuestion, answeredQuestion) => {
    let hdfQuestionnaireCountErrorState = true;
    if (answeredQuestion == noOfQuestion) {
      hdfQuestionnaireCountErrorState = false;
    }

    this.setState({ hdfQuestionnaireCountErrorState }, this.validateAll);
  };

  handleIsPlanChanged = check => {
    this.setState({
      isPlanChanged: check
    });
  };

  handleRequiredFieldsError = (key, subKey, errorState) => {
    if (key === "otherInformation") {
      this.setState(
        { otherInformationErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "extremeSportsEngagement" && subKey === "privateFlying") {
      this.setState({ privateFlyingErrorState: errorState }, this.validateAll);
    }

    if (key === "extremeSportsEngagement" && subKey === "scubaDiving") {
      this.setState({ scubaDivingErrorState: errorState }, this.validateAll);
    }

    if (key === "extremeSportsEngagement" && subKey === "mountaineering") {
      this.setState({ mountaineeringErrorState: errorState }, this.validateAll);
    }

    if (key === "extremeSportsEngagement" && subKey === "skyDiving") {
      this.setState({ skyDivingErrorState: errorState }, this.validateAll);
    }

    if (key === "extremeSportsEngagement" && subKey === "racing") {
      this.setState({ racingErrorState: errorState }, this.validateAll);
    }

    if (key === "extremeSportsEngagement" && subKey === "other") {
      this.setState(
        { otherExtremeSportsErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "drugAlcoholAddictionHistory") {
      this.setState(
        { drugAlcoholAddictionErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "insuranceArrangement") {
      this.setState(
        { insuranceArrangementErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "recentHealthCondition") {
      this.setState(
        { recentHealthConditionErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "smoking") {
      this.setState({ smokingHabitsErrorState: errorState }, this.validateAll);
    }

    if (key === "alcohol") {
      this.setState(
        { alcoholConsumptionErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "sexuallyTransmittedDiseases") {
      this.setState({ stdsHistoryErrorState: errorState }, this.validateAll);
    }

    if (key === "healthScreeningHistory") {
      this.setState(
        { healthScreeningErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "familyMedicalHistory") {
      this.setState(
        { familyMedicalHistoryErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "femaleInsuredOnly" && subKey === "papSmear") {
      this.setState({ pAPSmearErrorState: errorState }, this.validateAll);
    }

    if (key === "femaleInsuredOnly" && subKey === "gynecological") {
      this.setState({ gynecologicalErrorState: errorState }, this.validateAll);
    }

    if (key === "femaleInsuredOnly" && subKey === "pregnancySuffering") {
      this.setState(
        { pregnancySufferingErrorState: errorState },
        this.validateAll
      );
    }

    if (
      key === "femaleInsuredOnly" &&
      subKey === "otherProblemsInvolvingFemaleOrgans"
    ) {
      this.setState(
        { otherFemaleOrganIssuesErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "femaleInsuredOnly" && subKey === "pregnancyCondition") {
      this.setState(
        { pregnancyConditonErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "otherMedicalConditions" && subKey === "respiratory") {
      this.setState({ respiratoryErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "nervousMental") {
      this.setState({ nervousMentalErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "bowel") {
      this.setState({ bowelErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "kidney") {
      this.setState({ kidneyErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "endocrine") {
      this.setState({ endocrineErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "cancerTumour") {
      this.setState({ cancerTumourErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "eyeHearingSpeech") {
      this.setState(
        { eyeHearingSpeechErrorState: errorState },
        this.validateAll
      );
    }

    if (key === "otherMedicalConditions" && subKey === "gallbladder") {
      this.setState({ gallBladderErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "severeInjury") {
      this.setState({ severeInjuryErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "heart") {
      this.setState({ heartErrorState: errorState }, this.validateAll);
    }

    if (key === "otherMedicalConditions" && subKey === "otherIllness") {
      this.setState({ otherIllnessErrorState: errorState }, this.validateAll);
    }
  };

  validateAll = () => {
    if (
      this.state.hdfQuestionnaireCountErrorState ||
      this.state.scubaDivingErrorState ||
      this.state.otherInformationErrorState ||
      this.state.alcoholConsumptionErrorState ||
      this.state.drugAlcoholAddictionErrorState ||
      this.state.mountaineeringErrorState ||
      this.state.otherExtremeSportsErrorState ||
      this.state.privateFlyingErrorState ||
      this.state.racingErrorState ||
      this.state.skyDivingErrorState ||
      this.state.gynecologicalErrorState ||
      this.state.otherFemaleOrganIssuesErrorState ||
      this.state.pAPSmearErrorState ||
      this.state.pregnancyConditonErrorState ||
      this.state.pregnancySufferingErrorState ||
      this.state.healthScreeningErrorState ||
      this.state.insuranceArrangementErrorState ||
      this.state.bowelErrorState ||
      this.state.cancerTumourErrorState ||
      this.state.endocrineErrorState ||
      this.state.eyeHearingSpeechErrorState ||
      this.state.gallBladderErrorState ||
      this.state.heartErrorState ||
      this.state.kidneyErrorState ||
      this.state.nervousMentalErrorState ||
      this.state.otherIllnessErrorState ||
      this.state.respiratoryErrorState ||
      this.state.severeInjuryErrorState ||
      this.state.recentHealthConditionErrorState ||
      this.state.smokingHabitsErrorState ||
      this.state.stdsHistoryErrorState ||
      this.state.familyMedicalHistoryErrorState
    ) {
      this.setState({ allValidationSuccess: false });

      // this.state.hdfQuestionnaireCountErrorState && console.log("question available / answered not tally ");
      this.state.scubaDivingErrorState &&
        console.log("scubaDiving validation failed ");
      this.state.alcoholConsumptionErrorState &&
        console.log("alcoholConsumption validation failed ");
      this.state.drugAlcoholAddictionErrorState &&
        console.log("drugAlcoholAddiction validation failed ");
      this.state.mountaineeringErrorState &&
        console.log("mountaineering validation failed ");
      this.state.otherExtremeSportsErrorState &&
        console.log("otherExtremeSports validation failed ");
      this.state.privateFlyingErrorState &&
        console.log("privateFlying validation failed ");
      this.state.racingErrorState && console.log("racing validation failed ");
      this.state.skyDivingErrorState &&
        console.log("skyDiving validation failed ");
      this.state.gynecologicalErrorState &&
        console.log("gynecological validation failed ");
      this.state.otherFemaleOrganIssuesErrorState &&
        console.log("otherFemaleOrganIssues validation failed ");
      this.state.pAPSmearErrorState &&
        console.log("pAPSmear validation failed ");
      this.state.pregnancyConditonErrorState &&
        console.log("pregnancyConditon validation failed ");
      this.state.pregnancySufferingErrorState &&
        console.log("pregnancySuffering validation failed ");
      this.state.healthScreeningErrorState &&
        console.log("healthScreening validation failed ");
      this.state.insuranceArrangementErrorState &&
        console.log("insuranceArrangement validation failed ");
      this.state.bowelErrorState && console.log("bowel validation failed ");
      this.state.cancerTumourErrorState &&
        console.log("cancerTumour validation failed ");
      this.state.endocrineErrorState &&
        console.log("endocrine validation failed ");
      this.state.eyeHearingSpeechErrorState &&
        console.log("eyeHearingSpeech validation failed ");
      this.state.gallBladderErrorState &&
        console.log("gallBladder validation failed ");
      this.state.heartErrorState && console.log("heart validation failed ");
      this.state.kidneyErrorState && console.log("kidney validation failed ");
      this.state.nervousMentalErrorState &&
        console.log("nervousMental validation failed ");
      this.state.otherIllnessErrorState &&
        console.log("otherIllness validation failed ");
      this.state.respiratoryErrorState &&
        console.log("respiratory validation failed ");
      this.state.severeInjuryErrorState &&
        console.log("severeInjury validation failed ");
      this.state.recentHealthConditionErrorState &&
        console.log("recentHealthCondition validation failed ");
      this.state.smokingHabitsErrorState &&
        console.log("smokingHabits validation failed ");
      this.state.stdsHistoryErrorState &&
        console.log("stdsHistory validation failed ");
      this.state.otherInformationErrorState &&
        console.log("otherInformation validation failed ");
      this.state.familyMedicalHistoryErrorState &&
        console.log("familyMedicalHistory validation failed ");
    } else {
      this.setState({ allValidationSuccess: true });
    }
  };

  postEnrolment = () => {
    if (this.props.user.consent) {
      if (this.props.user.client_name.config.isPruAgencyEnrolment === "True") {
        this.props.user.payloadEnrolment.coverage_details.previous = [];
        if (this.props.user.dependencies.length > 0) {
          this.props.user.payloadEnrolment.dependants[0].coverage_details.previous = [];
        }
      }

      this.props.user.payloadEnrolment.consent = this.props.user.consent;
      this.props.user.payloadEnrolment.payment_method = this.props.user.payment;
      this.props.user.payloadEnrolment.hdf_consent = this.props.user.hdfConsent;
      this.props.user.payloadEnrolment.payment_consent = this.props.user.paymentConsent;
      this.setState({ triggerLoadingPopup: true });
      this.props
        .postEnrolment(this.props.user.id, this.props.user.payloadEnrolment)
        .then(
          () => {
            this.setState({ triggerLoadingPopup: false });
            this.handleNext();
          },
          err => {
            this.setState({
              snackBarErrorShow: true,
              triggerLoadingPopup: false
            });
            return Promise.reject(err);
          }
        )
        .catch(err => {});
    }
  };

  handleTab = tabValue => {
    this.setState({
      step2Tab: tabValue
    });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <VerifyDetails
            history={this.props.history}
            tabValue={this.state.tabValue}
            handleTab={this.handleTab}
          />
        );
      case 1:
        return (
          <SelectPlan
            history={this.props.history}
            handleIsPlanChanged={this.handleIsPlanChanged}
          />
        );
      case 2:
        return this.props.user.underwriting_required ? (
          <FillHDFForm history={this.props.history} />
        ) : this.props.hdf.users.length > 0 ? (
          <QuestionnairesComponent
            user={this.props.user}
            handleRequiredFieldsError={this.handleRequiredFieldsError}
            handleHDFValidation={this.handleHDFValidation}
            handleIsPlanChanged={this.handleIsPlanChanged}
            isPlanChanged={this.state.isPlanChanged}
          />
        ) : (
          <ReviewConsent
            history={this.props.history}
            handleNextStep={this.handleNext}
          />
        );
      case 3: // need to fix this part for new hdf change
        return this.props.user.underwriting_required ||
          this.props.user.client_name.config.requiredFullHdf ? (
          <ReviewConsent
            history={this.props.history}
            handleNextStep={this.handleNext}
          />
        ) : (
          <Confirmation history={this.props.history} />
        );
      case 4:
        return this.props.user.underwriting_required ||
          this.props.user.client_name.config.requiredFullHdf ? (
          <Confirmation history={this.props.history} />
        ) : (
          "Unknown step"
        );
      default:
        return "Unknown step";
    }
  };
  steps = [
    this.props.user.content.labels["step1.navigation.title"],
    this.props.user.content.labels["step2.navigation.title"],
    this.props.user.content.labels["step3.navigation.title"],
    this.props.user.content.labels["step4.navigation.title"],
    this.props.user.content.labels["step5.navigation.title"]
  ];
  stepsHDF = [
    this.props.user.content.labels["step1.navigation.title"],
    this.props.user.content.labels["step2.navigation.title"],
    this.props.user.content.labels["step3.navigation.title"],
    this.props.user.content.labels["step4.navigation.title"],
    this.props.user.content.labels["step5.navigation.title"]
  ];
  stepsNoHealthDeclaration = [
    this.props.user.content.labels["step1.navigation.title"],
    this.props.user.content.labels["step2.navigation.title"],
    this.props.user.content.labels["step4.navigation.title"],
    this.props.user.content.labels["step5.navigation.title"]
  ];

  getSteps = () => {
    const requiredFullHdf = this.props.user.client_name.config.requiredFullHdf;

    if (requiredFullHdf === undefined || !requiredFullHdf) {
      return this.stepsNoHealthDeclaration;
    }

    return this.props.user.underwriting_required ? this.stepsHDF : this.steps;
  };

  totalSteps = () => {
    return this.state.steps.length;
  };

  isStepOptional = step => {
    return step === 2;
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped
      };
    });
  };

  checkHDFRequired = activeStep => {
    const { id, fullName, planSelected } = this.props.state.user;
    const coverageDetails = [];
    const person = {
      id: id,
      fullname: fullName,
      current: []
    };
    if (planSelected !== undefined && typeof planSelected === "object") {
      planSelected.forEach(plan => {
        const { coverageId } = plan;
        switch (coverageId) {
          case "GHS":
            person.current.push({ code: coverageId, option: plan.option });
            break;
          case "GMM":
            person.current.push({ code: coverageId, option: plan.option });
            break;
          case "VTLB":
          case "VCCA":
          case "VECI":
          case "GADD":
          case "GCCE":
            person.current.push({
              code: coverageId,
              sum_assured: plan.details[0].overview.details[0].details[0]
            });
            break;
        }
      });
    }

    coverageDetails.push(person);

    this.props.user.dependencies.map(dependent => {
      const { id, full_name, coverage_details } = dependent;
      const currentValues = [];
      const { current } = coverage_details;
      if (current.length > 0) {
        current.map(currentPlan => {
          const { code, option, sum_assured } = currentPlan;
          switch (code) {
            case "GHS":
              currentValues.push({ code, option });
              break;
            case "GMM":
              currentValues.push({ code, option });
              break;
            case "VTLB":
            case "VCCA":
            case "VECI":
            case "GADD":
              currentValues.push({ code, sum_assured });
          }
        });
      }

      coverageDetails.push({
        id: id,
        fullname: full_name,
        current: currentValues
      });
    });

    const requestPayload = {
      personId: id,
      coverageDetails: coverageDetails
    };

    this.props
      .checkHDFRequirementCall(id, requestPayload)
      .then(response => {
        this.setState({
          activeStep
        });

        this.props.saveActiveStep(activeStep);
      })
      .catch(error => {
        console.error("Error. Please consult system administrator.");
      });

    // if (this.state.currentHDFUsers.length > 0) {
    //   const newHDFUsers = [...this.state.currentHDFUsers];
    //   this.state.currentHDFUsers.forEach(currentHDFUser => {
    //     newHDFUsers.push(
    //       this.props.hdf.users.find(hdfUser => hdfUser.id !== currentHDFUser.id)
    //     );
    //   });
    //   this.props.handleUpdateHealthDeclaration(newHDFUsers);
    // } else {
    //   this.setState({ currentHDFUsers: this.props.hdf.users });
    // }
  };

  handleNext = () => {
    this.props.scrollToTop();
    let activeStep;
    if (this.isLastStep() && !this.allStepsCompleted()) {
      const steps = this.state.step;
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    if (this.props.user.underwriting_required) {
      if (activeStep === 2) {
        this.props.confirmEnrolment();
      } else if (activeStep === 3) {
        this.props.fillHDFForm();
      }
    } else {
      if (activeStep === 3 || activeStep === 2) {
        this.props.confirmEnrolment();
      }
    }

    if (activeStep === 2 && this.state.isPlanChanged) {
      // so when the plan is change or beling selected, the api will be called
      this.checkHDFRequired(activeStep);
    } else {
      this.setState({
        activeStep
      });

      this.props.saveActiveStep(activeStep);
    }
  };

  handelPreNext = () => {
    let activeStep;
    if (this.isLastStep() && !this.allStepsCompleted()) {
      const steps = this.state.step;
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    if (
      activeStep === 1 &&
      this.props.user.dependencies.length === 0 &&
      this.props.user.enable_dependent_registration
    ) {
      this.setState({
        ...this.state,
        openConfirmDialog: true
      });
    } else {
      this.handleNext();
    }
  };

  handleBack = () => {
    this.props.scrollToTop();

    if (
      this.props.user.client_name.config.requiredFullHdf === true &&
      this.props.hdf.users.length === 0 &&
      this.state.activeStep === 3
    ) {
      this.props.saveActiveStep(this.state.activeStep - 2);
      this.setState(state => ({
        activeStep: state.activeStep - 2
      }));
    } else {
      this.props.saveActiveStep(this.state.activeStep - 1);
      this.setState(state => ({
        activeStep: state.activeStep - 1,
        isPlanChanged: false
      }));
    }
  };

  handleStep = step => () => {
    this.props.scrollToTop();

    this.setState({
      activeStep: step
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      skipped: new Set()
    });
  };

  skippedSteps() {
    return this.state.skipped.size;
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      enableNext: nextProps.user.enableNext,
      premium: nextProps.user.premium || 0,
      premiumDep: nextProps.user.premiumDep || 0,
      consent: nextProps.user.consent || false,
      paymentConsent: nextProps.user.paymentConsent || false,
      hdfConsent: nextProps.user.hdfConsent || false
    });
  }
  componentWillMount() {
    let steps = this.getSteps();
    for (var i = 0; i < steps.length; i++) {
      if (
        !this.state.showDependent &&
        steps[i] === "Select Dependant(s) Plan"
      ) {
        steps.splice(i, 1);
      }
    }

    this.setState({
      steps: steps
    });
  }

  closeCofirmDialog = () => {
    this.setState({ openConfirmDialog: false });
    this.handleNext();
  };
  responseConfirmDialog = () => {
    // this.closeCofirmDialog();
    this.setState({
      ...this.state,
      openConfirmDialog: false,
      tabValue: 1
    });
  };

  isStepCompleted = step => {
    if (step === 2) {
      return this.props.hdf.users.length === 0
        ? false
        : this.state.activeStep > step
        ? true
        : false;
    } else {
      return this.state.activeStep > step ? true : false;
    }
  };

  isStepDisabled = step => {
    if (step === 2) {
      return this.props.hdf.users.length === 0 ? true : false;
    }
  };

  filterClientPremium(premiumValueEmp) {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    if (isPruAgencyEnrolment) {
      let tempPremiumSevenMonthRequest = parseFloat(premiumValueEmp);
      let sevenMonthChangeResult = (
        (tempPremiumSevenMonthRequest / 365) *
        212
      ).toFixed(2);

      return sevenMonthChangeResult.toString();
    }
    return premiumValueEmp;
  }

  render() {
    const mf = new MessageFormat("en");
    const { labels } = this.props.user.content;
    const footer = mf.compile(labels["step.footer.premium"]);
    const reviewButton = mf.compile(labels["step.review.button"]);
    const { classes, user } = this.props;
    const steps = this.state.steps;
    const { activeStep, allValidationSuccess } = this.state;
    const requiredFullHdf = this.props.user.client_name.config.requiredFullHdf;
    return (
      <main ref={this.mainRef}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};

              return requiredFullHdf !== undefined && requiredFullHdf ? (
                <Step
                  key={label}
                  {...props}
                  completed={this.isStepCompleted(index)}
                  disabled={this.isStepDisabled(index)}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              ) : (
                <Step key={label} {...props}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Grid container spacing={"8px"}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography component={"div"} className={classes.instructions}>
                {this.getStepContent(activeStep)}
              </Typography>
              {!(
                (this.props.user.underwriting_required &&
                  this.state.activeStep === 4) ||
                this.props.user.underwriting_required == undefined ||
                (false && this.state.activeStep === 3)
              ) ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.buttonPosition}
                  style={{ padding: "20px 0px 50px 0px" }}
                >
                  <div>
                    <div style={{ textAlign: "left", float: "left" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={
                          activeStep === 0 ||
                          (activeStep === 4 &&
                            this.props.user.underwriting_required) ||
                          (this.props.user.client_name.config
                            .requiredFullHdf !== true &&
                            activeStep === 3 &&
                            this.props.user.underwriting_required === false)
                        }
                        onClick={this.handleBack}
                        className={classes.backButton}
                      >
                        BACK
                      </Button>
                    </div>

                    <div style={{ textAlign: "right", float: "right" }}>
                      <div>
                        <div
                          style={{
                            textAlign: "right",
                            float: "right",
                            padding: "0px 5px"
                          }}
                        >
                          {(() => {
                            switch (this.state.activeStep) {
                              case 0:
                                return (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      this.state.activeStep === 0
                                        ? false
                                        : !this.state.enableNext
                                    }
                                    onClick={this.handelPreNext}
                                    className={classes.button}
                                    style={{ marginLeft: "0px" }}
                                  >
                                    NEXT
                                  </Button>
                                );
                              case 2: // need to fix this part for new hdf
                                return this.props.user.underwriting_required ? (
                                  this.state.enableNext &&
                                  this.state.hdfConsent ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleNext}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      {reviewButton({})}
                                    </Button>
                                  ) : (
                                    <Tooltip title="Please complete your Health Declaration to proceed further">
                                      <span>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          disabled={false}
                                          onClick={this.handleNext}
                                          className={classes.button}
                                          style={{ marginLeft: "0px" }}
                                        >
                                          {reviewButton({})}
                                        </Button>
                                      </span>
                                    </Tooltip>
                                  )
                                ) : this.props.user.client_name.config
                                    .requiredFullHdf === true ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!allValidationSuccess}
                                    onClick={this.handleNext}
                                    className={classes.button}
                                    style={{ marginLeft: "0px" }}
                                  >
                                    REVIEW & SUBMIT
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      this.props.user.client_name.config
                                        .isPruAgencyEnrolment === "True"
                                        ? !this.state.consent ||
                                          this.props.user.payment ===
                                            undefined ||
                                          !this.state.paymentConsent
                                        : !this.state.consent
                                    }
                                    onClick={this.postEnrolment}
                                    className={classes.button}
                                    style={{ marginLeft: "0px" }}
                                  >
                                    CONFIRM
                                  </Button>
                                );
                              case 1:
                                return this.props.user.underwriting_required ? (
                                  this.state.enableNext ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleNext}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      Proceed to Health Declaration
                                    </Button>
                                  ) : (
                                    <Tooltip title="Please complete all your coverage selection to proceed further">
                                      <span>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          disabled={true}
                                          onClick={this.handleNext}
                                          className={classes.button}
                                          style={{ marginLeft: "0px" }}
                                        >
                                          Proceed to Health Declaration
                                        </Button>
                                      </span>
                                    </Tooltip>
                                  )
                                ) : this.state.enableNext ? (
                                  this.props.user.client_name.config
                                    .requiredFullHdf === true ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleNext}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      NEXT
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleNext}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      {reviewButton({})}
                                    </Button>
                                  )
                                ) : this.props.user.client_name.config
                                    .isPruAgencyMedEnrolment === "True" ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={true}
                                    onClick={this.handleNext}
                                    className={classes.button}
                                    style={{ marginLeft: "0px" }}
                                  >
                                    NEXT
                                  </Button>
                                ) : (
                                  <Tooltip title="Please complete all your coverage selection to proceed further">
                                    <span>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={true}
                                        onClick={this.handleNext}
                                        className={classes.button}
                                        style={{ marginLeft: "0px" }}
                                      >
                                        {reviewButton({})}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                );
                              case 3:
                                return this.props.user.underwriting_required ||
                                  this.props.user.client_name.config
                                    .requiredFullHdf ? (
                                  this.props.isPaymentEnabled ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={
                                        !(
                                          this.state.consent &&
                                          this.state.enableNext &&
                                          this.state.paymentConsent
                                        ) ||
                                        !(this.props.user.client_name.config
                                          .requiredFullHdf &&
                                        this.props.hdf.users.length > 0
                                          ? this.props.hdf.packageStatus
                                          : true)
                                      }
                                      onClick={this.postEnrolment}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      CONFIRM
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={
                                        !this.state.consent ||
                                        !(this.props.user.client_name.config
                                          .requiredFullHdf &&
                                        this.props.hdf.users.length > 0
                                          ? this.props.hdf.packageStatus
                                          : true)
                                      }
                                      onClick={this.postEnrolment}
                                      className={classes.button}
                                      style={{ marginLeft: "0px" }}
                                    >
                                      CONFIRM
                                    </Button>
                                  )
                                ) : (
                                  ""
                                );
                            }
                          })()}
                        </div>
                        <div style={{ float: "right", padding: "0px 5px" }}>
                          <Typography
                            component={"span"}
                            className={classes.fontBottomPremium}
                          >
                            <span className={classes.premiumText}>
                              S$
                              {(
                                parseFloat(
                                  parseFloat(this.state.premium).toFixed(2)
                                ) +
                                parseFloat(
                                  parseFloat(this.state.premiumDep).toFixed(2)
                                )
                              )
                                .toFixed(2)

                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            {user.client_name.config.hideAnnual != "True"
                              ? "/year "
                              : ""}
                          </Typography>
                        </div>
                        <div
                          style={{
                            float: "right",
                            padding: "0px 5px",
                            lineHeight: "36px"
                          }}
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontBottom}
                            style={{ lineHeight: "36px" }}
                            dangerouslySetInnerHTML={{
                              __html: footer({})
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid />
              )}
            </Grid>
          </Grid>
          <SnackBar
            type="error"
            open={this.state.snackBarErrorShow}
            onClose={() => this.setState({ snackBarErrorShow: false })}
            message="We were unable to submit your enrolment request. Please try again."
          />
          <LoadingPopup
            title="Loading"
            description="Your request is currently being processed"
            subDescription="Please wait for a while"
            name="send-document-loading"
            open={this.state.triggerLoadingPopup}
          />
          <SimpleConfirmDialog
            name="leave-delete-dialog"
            title="Add Dependants"
            description="Do you want to add dependants?"
            closeButtonText="No"
            okButtonText="Yes"
            closeHandler={this.closeCofirmDialog}
            okHandler={this.responseConfirmDialog.bind(this)}
            open={this.state.openConfirmDialog}
          />
        </div>
      </main>
    );
  }
}

UpdatePlanSteps.propTypes = {
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    enableNext: state.enableNext,
    state: state,
    user: state.user,
    hdf: state.hdf,
    isPaymentEnabled: selectIsPaymentEnabled(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UpdatePlanSteps));
