import { createSelector } from "reselect";
import { getSelectedPlan, getUser } from "./CommonSelectors";

export const PLAN_OPTOUT = "Opt Out";

export const selectIsAllPlanOptOut = createSelector(getSelectedPlan, plans =>
  plans.every(v => {
    if (typeof v[1] === "string" && v[1] === PLAN_OPTOUT) return true;

    return false;
  })
);

export const selectIsPaymentEnabled = createSelector(
  [getUser, selectIsAllPlanOptOut],
  (user, isAllPlanOptOut) => {
    if (isAllPlanOptOut) return false;
    return user.paymentConsentRequired === "true";
  }
);
