import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { SummaryTable } from "../summary-table/SummaryTable.component";

export const HDFDeclarationSummary = props => {
  return (
    <Paper>
      <Typography
        style={{ fontWeight: "600", color: "red", paddingLeft: "45px" }}
        variant={"h6"}
      >
        Health Declaration
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="health-declaration-summary-accordion"
        >
          <Typography style={{ fontWeight: "600", paddingLeft: "60px" }}>
            Summarized Overview
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable user={props.user} userData={props.userData} />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
