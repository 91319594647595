export class InsuranceArrangementClass {
  constructor(dateOfApplication, typeOfApplication, reason) {
    this.dateOfApplication = dateOfApplication;
    this.typeOfApplication = typeOfApplication;
    this.reason = reason;
  }

  getInsuranceArrangementDetails = () => {
    return {
      dateOfApplication: this.dateOfApplication,
      typeOfApplication: this.typeOfApplication,
      reason: this.reason
    };
  };
}
