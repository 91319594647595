import Enums from "../utils/Enums";

let proc = process.env;

const config = {
  baseUrl: process.env.REACT_APP_API_URL,
  app_version: `version ${
    process.env.REACT_APP_VERSION
  } ${process.env.REACT_APP_ENV.charAt(0)}`,
  app: {
    type: Enums.APP_TYPE.ENROLMENT
  },
  api: {
    timeout: 300000,
    authBearer: "ZW5yb2xtZW50OnBhc3N3b3Jk",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  },
  products: {
    defaultCode: "PTF",
    config: {
      PTF: {
        code: "PTF",
        version: 1
      }
    }
  },
  googleMapURL: process.env.REACT_GOOGLE_MAP_URL
};

let hasExternalConfig =
  proc.REACT_APP_EXT_CONFIG &&
  proc.REACT_APP_EXT_CONFIG.toLowerCase() === "true";

let _config = hasExternalConfig ? window.MMPConfig : proc;
// local usage
// let _config = window.MMPConfig;
if (_config.BANNER !== undefined) {
  config.bannerEnabled = _config.BANNER.BANNER_ENABLED;
  config.bannerTitle = _config.BANNER.BANNER_TITLE;
  config.bannerDetails = _config.BANNER.BANNER_DETAILS;
  config.logoDisplay = _config.BANNER.LOGO_DISPLAY;
}
if (_config.POPUPLOGIN !== undefined) {
  config.popUpLoginMessageEnabled = _config.POPUPLOGIN.ENABLED;
  config.popUpLoginMessage = _config.POPUPLOGIN.MESSAGE;
}

if (_config.APP_VERSION !== undefined) {
  config.appVersionEnabled = _config.APP_VERSION.ENABLED;
  config.appVersionNumber = _config.APP_VERSION.VERSION_NUMBER;
}

if (_config.MAINTENANCE !== undefined) {
  config.maintenanceEnabled = _config.MAINTENANCE.MAINTENANCE_ENABLED;
  config.maintenanceTitle = _config.MAINTENANCE.MAINTENANCE_TITLE;
  config.maintenanceSubtitle = _config.MAINTENANCE.MAINTENANCE_SUBTITLE;
  config.maintenanceDetails = _config.MAINTENANCE.MAINTENANCE_DETAILS;
  config.startDateTime = _config.MAINTENANCE.START_DATE_TIME;
  config.endDateTime = _config.MAINTENANCE.END_DATE_TIME;
}

//Only for local development
// if (proc.REACT_APP_ENV === "LOCAL") {
//   config.api.authBearer = proc.REACT_APP_API_AUTH_BEARER;
// }

export default config;
