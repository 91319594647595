import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";

import SimpleControlDialog from "../../components/SimpleControlDialog";
import Button from "@mui/material/Button";
import userService from "../../services/UserServices";
import CiamResponseCode from "../../utils/CiamResponseCode.js";
import Util from "../../utils/Util.js";

const required = value => (value ? undefined : "Required");
let submitOTPdisable = false;
let isMetRequirement = false;

class OtpDialog extends Component {
  constructor() {
    super();
    this.state = {
      time: {},
      seconds: 30,
      buttonDisable: false,
      isHideResendOTP: false
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      m: minutes,
      s: seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
    this.setState({ buttonDisable: true });
  }

  countDown() {
    // counting down the tome
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });

    // stop disabling the button when count down is over
    if (seconds == 0) {
      clearInterval(this.timer);
      // refresh the 30 seconds count down
      this.timer = 0;
      this.setState({
        seconds: 30,
        time: this.secondsToTime(30),
        buttonDisable: false
      });
    }
  }

  resendOtp = () => {
    userService.resendOtp(this.props.code).then(
      res => {
        if (res.success) {
          submitOTPdisable = false;
          isMetRequirement = true;
          this.startTimer();
        } else {
          Util.errorCodeCloseOtpDialog(error);
          this.props.closeHandler(error);
        }
      },
      error => {
        Util.errorCodeCloseOtpDialog(error);
        if (error.isCloseOtpDialog) {
          this.props.closeHandler(error);
        } else {
          console.error("Failed to request OTP: ", error);
        }
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) this.props.reset();
  }

  submit = values => {
    isMetRequirement = false;
    this.props.submitHandler(values.otp);
    this.setState({ isHideResendOTP: false });
  };

  handleClose = () => {
    this.setState({ isHideResendOTP: false });
    if (this.props.closeHandler) {
      this.props.closeHandler();
    }
  };

  render() {
    const { isHideResendOTP } = this.state;
    const {
      ciamErrorCode,
      submitErrorMessage,
      show,
      valid,
      handleSubmit
    } = this.props;

    const errorCodesToDisableOTP = new Set([
      CiamResponseCode.OTP_EXPIRED.code,
      CiamResponseCode.OTP_EXPIRED.code_old,
      CiamResponseCode.FORGEROCK_ERROR.code
    ]);

    submitOTPdisable =
      errorCodesToDisableOTP.has(ciamErrorCode) && !isMetRequirement;

    const showDbox =
      ciamErrorCode === CiamResponseCode.MAX_OTP_FAILED.code ? false : show;
    const resendErrMessage = isHideResendOTP
      ? CiamResponseCode.OTP_REQ_FAILED
      : "";

    return (
      <SimpleControlDialog
        show={showDbox}
        title="Enter OTP"
        description="Please enter the OTP sent to your email address."
        onClose={this.handleClose}
        closeHandler={this.handleClose}
        valid={valid}
        isCodeTrue={submitOTPdisable}
        onSubmit={handleSubmit(this.submit)}
        okButtonText="SUBMIT"
        closeButtonText="CANCEL"
      >
        <Field
          name="otp"
          autoFocus
          margin="dense"
          component={renderTextField}
          autoComplete="[off]"
          label="OTP"
          type="text"
          onChange={this.props.inputHandler}
          validate={required}
        />
        <br />
        {this.props.submitError && (
          <Typography
            variant="caption"
            style={{
              color: "red",
              whiteSpace: "pre-line",
              textAlign: "center"
            }}
          >
            {!isHideResendOTP && (
              <>
                {submitErrorMessage}
                <br />
              </>
            )}
            <br />
          </Typography>
        )}
        <br />
        {!isHideResendOTP && this.renderOtpMessageAndButton()}
        <div
          style={{
            color: "red",
            textAlign: "center"
          }}
        >
          {resendErrMessage}
        </div>
      </SimpleControlDialog>
    );
  }
  renderOtpMessageAndButton = () => {
    return (
      <>
        <Typography variant="subheading">
          If you do not receive the OTP within 30 seconds please click on the
          "Resend OTP" button below
        </Typography>
        <Button
          disabled={this.state.buttonDisable}
          onClick={this.resendOtp}
          variant="contained"
          color="primary"
          style={styles.button}
        >
          Resend OTP
          {this.state.time.s === "30" ? "" : ` (${this.state.time.s})`}
        </Button>
      </>
    );
  };
}

const styles = theme => ({
  button: {
    minWidth: 150,
    fontSize: "15.75px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 120
    }
  }
});

export default reduxForm({
  form: "otp-form"
})(OtpDialog);
