import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputAdornment,
  Input,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { COUNTRIES } from "../../../../config/app.constants";
import UserLabel from "../components/UserLabel.component";
import { InputFieldWrapper } from "../components/InputFieldWrapper.component";
import OtherField from "../components/OtherField.component";
import { runValidate } from "../hooks/useValidate";
import debounce from "lodash/debounce";

/**
  @typedef {{
    error: boolean;
    message?: string | string[];
  }} ErrorField
 */

/**
  @typedef {{
    value?: string
    otherValue?: string
    onChange?: (value: string, otherValue: string) => void
    onValidate?: import("../hooks/useValidate").OnValidate
    label?: string
    className?: string
    errors?: {
      value?: ErrorField;
      otherValue?: ErrorField;
    }
  }} CountryFieldProps
 */

/**
  @typedef {{
    nationality: string;
    otherNationality: string;
    countryOfResidence: string;
    otherCountryOfResidence: string;
    height: string;
    weight: string;
  }} EmployeeForm
 */

/**
  @typedef {{
    nationality: string;
    otherNationality: string;
    occupation: string;
    height: string;
    weight: string;
  }} NonEmployeeForm
 */

/**
  @template T
  @typedef {{
    value?: T;
    onChange?: (value: T) => void
  }} OtherInformationQuestionnaireCommonFormProps
 */

const checkIsOthers = value => value === "Others";

/** @returns {import("../hooks/useValidate").ValidationFn} */
const otherValidationBuilder = dependantFieldName => {
  return (value, data) => {
    if (!checkIsOthers(data[dependantFieldName])) return true;

    return Boolean(value);
  };
};

const employeeRules = {
  nationality: "required",
  countryOfResidence: "required",
  otherNationality: otherValidationBuilder("nationality"),
  otherCountryOfResidence: otherValidationBuilder("countryOfResidence"),
  height: "required",
  weight: "required"
};
const dependantsRules = {
  occupation: "required",
  height: "required",
  weight: "required",
  nationality: "required",
  otherNationality: otherValidationBuilder("nationality")
};

/** @param {CountryFieldProps} props */
const CountryField = ({
  value: propValue,
  otherValue: propOtherValue,
  onChange,
  label,
  className,
  errors
}) => {
  const state = { value: propValue, otherValue: propOtherValue };
  const isOther = checkIsOthers(state.value);

  /** @param {keyof typeof state} field */
  const handleChange = (field, value) => {
    const newState = { ...state, [field]: value };
    if (field === "value" && !checkIsOthers(value)) {
      newState.otherValue = "";
    }

    onChange?.(newState.value, newState.otherValue);
  };

  /** @param {import("@mui/material").SelectChangeEvent<any>} ev */
  const handleSelect = ev => {
    handleChange("value", ev.target.value);
  };

  const handleOtherFieldChange = value => {
    handleChange("otherValue", value);
  };

  return (
    <Box className={className} display="flex" flexDirection="column" gap={2}>
      <InputFieldWrapper label={<Box fontWeight="bold">{label}</Box>}>
        <FormControl variant="standard">
          <Select value={propValue} onChange={handleSelect}>
            {COUNTRIES.map(country => (
              <MenuItem key={country.id} value={country.title}>
                {country.title}
              </MenuItem>
            ))}
          </Select>

          {errors?.value?.error ? (
            <FormHelperText style={{ color: "red" }}>
              {errors?.value?.message ?? "This field is mandatory."}
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </InputFieldWrapper>
      <OtherField
        value={propOtherValue}
        disabled={!isOther}
        onChange={handleOtherFieldChange}
        error={errors?.otherValue?.error}
      />
    </Box>
  );
};

/** @param {import("@mui/material").InputProps & {label?: string}} props */
const StandardInputBase = props => {
  const { label, className } = props;
  const { textFieldRed } = questionnairesStyles();

  const handleKeyPress = event => {
    const { key } = event;

    // Disable input of "e" and "-"
    if (key === "e" || key === "-") {
      event.preventDefault();
    }

    // Ensure minimum value is greater than 0
    const currentValue = event.target.value;
    const newValue =
      event.key === "Backspace"
        ? currentValue.slice(0, -1)
        : currentValue + event.key;
    const numericValue = Number(newValue);

    if (numericValue <= 0) {
      event.preventDefault();
    }
  };

  return (
    <InputFieldWrapper label={<Box fontWeight="bold">{label}</Box>}>
      <Input
        {...props}
        variant="standard"
        className={`${textFieldRed} ${className}`}
        onKeyPress={
          className === "height" || className === "weight"
            ? handleKeyPress
            : null
        }
      />
    </InputFieldWrapper>
  );
};

/**
 * @param {OtherInformationQuestionnaireCommonFormProps<EmployeeForm>} props
 */
const OtherInformationQuestionnaireEmployeeForm = ({
  value: valueProp,
  onChange
}) => {
  /**
   * @param {EmployeeForm|string} value
   * @param {keyof EmployeeForm|undefined} field
   */
  const handleChange = (value, field) => {
    let state;
    if (field && typeof value === "string") {
      state = { ...valueProp, [field]: value };
    } else {
      state = { ...valueProp, ...value };
    }

    onChange?.(state);
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(4,1fr)" gap={2}>
      <CountryField
        label="Nationality"
        value={valueProp?.nationality}
        otherValue={valueProp?.otherNationality}
        onChange={(value, otherValue) =>
          handleChange({ nationality: value, otherNationality: otherValue })
        }
      />
      <CountryField
        label="Country of Residence"
        value={valueProp?.countryOfResidence}
        otherValue={valueProp?.otherCountryOfResidence}
        onChange={(value, otherValue) =>
          handleChange({
            countryOfResidence: value,
            otherCountryOfResidence: otherValue
          })
        }
      />

      <StandardInputBase
        label="Height"
        type="number"
        className="height"
        endAdornment={<InputAdornment position="end">CM</InputAdornment>}
        value={valueProp?.height}
        onChange={ev => handleChange(ev.target.value, "height")}
        inputProps={{ min: 1 }}
      />

      <StandardInputBase
        label="Weight"
        type="number"
        className="weight"
        endAdornment={<InputAdornment position="end">KG</InputAdornment>}
        value={valueProp?.weight}
        onChange={ev => handleChange(ev.target.value, "weight")}
        inputProps={{ min: 1 }}
      />
    </Box>
  );
};

/**
 * @param {OtherInformationQuestionnaireCommonFormProps<NonEmployeeForm>} props
 */
const OtherInformationQuestionnaireNonEmployeeForm = ({
  onChange,
  value: valueProp
}) => {
  /**
   * @param {EmployeeForm|string} value
   * @param {keyof EmployeeForm|undefined} field
   */
  const handleChange = (value, field) => {
    let state;
    if (field && typeof value === "string") {
      state = { ...valueProp, [field]: value };
    } else {
      state = { ...valueProp, ...value };
    }

    onChange?.(state);
  };

  return (
    <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
      <CountryField
        label="Nationality"
        value={valueProp?.nationality}
        otherValue={valueProp?.otherNationality}
        onChange={(value, otherValue) =>
          handleChange({ nationality: value, otherNationality: otherValue })
        }
      />

      <StandardInputBase
        label="Occupation"
        value={valueProp?.occupation}
        onChange={ev => handleChange(ev.target.value, "occupation")}
      />

      <StandardInputBase
        label="Height"
        type="number"
        className="height"
        endAdornment={<InputAdornment position="end">CM</InputAdornment>}
        value={valueProp?.height}
        onChange={ev => handleChange(ev.target.value, "height")}
        inputProps={{ min: 1 }}
      />

      <StandardInputBase
        label="Weight"
        type="number"
        className="weight"
        endAdornment={<InputAdornment position="end">KG</InputAdornment>}
        value={valueProp?.weight}
        onChange={ev => handleChange(ev.target.value, "weight")}
        inputProps={{ min: 1 }}
      />
    </Box>
  );
};

const genderResolver = gender => (gender === "M" ? "Male" : "Female");

const MaritalStatus = Object.freeze({
  M: "Married",
  S: "Single",
  D: "Divorce",
  _default: "Widowed"
});
const maritalStatusResolver = maritalStatus => {
  return MaritalStatus[maritalStatus] ?? MaritalStatus._default;
};

const personalDetailsTransformer = (hdfUser, mainUserDetails) => {
  if (hdfUser.person_type === "EMPLOYEE") {
    const { fullName, passport_nric, designation, dob } = mainUserDetails;
    hdfUser.personalDetails = {
      ...hdfUser.personalDetails,
      fullName,
      idNo: passport_nric,
      occupation: designation,
      dateOfBirth: dob,
      gender: genderResolver(mainUserDetails.gender),
      maritalStatus: maritalStatusResolver(mainUserDetails.marital_status)
    };
  } else {
    const targetIdx = mainUserDetails.dependencies.findIndex(
      item => item.id === hdfUser.id
    );

    if (targetIdx !== -1) {
      const user = mainUserDetails.dependencies[targetIdx];

      hdfUser.personalDetails = {
        ...hdfUser.personalDetails,
        fullName: user.full_name,
        idNo: user.id_no,
        dateOfBirth: user.dob,
        gender: genderResolver(user.gender),
        maritalStatus: maritalStatusResolver(user.marital_status)
      };
    }
  }
};

/** @return {Map} */
const hdfUsersToMap = (hdfUsers, mainUserDetails) =>
  hdfUsers.reduce((acc, curr) => {
    if (!acc.has(curr.id)) {
      personalDetailsTransformer(curr, mainUserDetails);
      acc.set(curr.id, curr);
    }
    return acc;
  }, new Map());

const validate = hdfUser => {
  const keys =
    hdfUser.person_type === "EMPLOYEE" ? employeeRules : dependantsRules;
  const result = runValidate(hdfUser.personalDetails, keys);

  if (result.length === 0) return false;
  return result.every(v => v.isValid);
};

export const OtherInformationQuestionnaire = props => {
  const { container } = questionnairesStyles();

  const dispatch = useDispatch();
  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const [users, setUsers] = useState(() =>
    Object.fromEntries(
      hdfUsersToMap(healthDeclarationOfUsers, props.userDetails).entries()
    )
  );
  const [error, setError] = useState(false);

  const checkGlobalRequiredField = isError => {
    props.handleRequiredFieldsError("otherInformation", null, isError);
  };

  const debouncedCheckGlobalRequiredField = useCallback(
    debounce(checkGlobalRequiredField, 100),
    []
  );

  const debouncedValidateUsersOnChange = useCallback(
    debounce(users => {
      const isValid = Object.entries(users)
        .map(validate)
        .every(Boolean);
      if (!isValid) checkGlobalRequiredField(true);
    }, 150),
    []
  );

  useEffect(() => {
    const isValid = healthDeclarationOfUsers.every(validate);
    debouncedCheckGlobalRequiredField(!isValid);
  }, []);

  const handleEmployeeForm = (userId, value) => {
    users[userId].personalDetails = {
      ...users[userId].personalDetails,
      ...value
    };

    setUsers({ ...users });
    debouncedValidateUsersOnChange(users);
  };

  const updateUserHealthDeclaration = () => {
    const isValid = Object.values(users)
      .map(validate)
      .every(Boolean);

    if (isValid) {
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
    debouncedCheckGlobalRequiredField(!isValid);
    setError(!isValid);
  };

  return (
    <Box className={container}>
      <Box display="flex" flexDirection="column" gap={2.5}>
        {Object.values(users).map(user => {
          const { id, person_type } = user;

          if (user) {
            return (
              <Box key={id}>
                <UserLabel user={user} hdfUsers={users} />
  
                {person_type === "EMPLOYEE" ? (
                  <OtherInformationQuestionnaireEmployeeForm
                    value={user?.personalDetails}
                    onChange={value => handleEmployeeForm(id, value)}
                  />
                ) : (
                  <OtherInformationQuestionnaireNonEmployeeForm
                    value={user?.personalDetails}
                    onChange={value => handleEmployeeForm(id, value)}
                  />
                )}
              </Box>
            );
          }
        })}
      </Box>
      <Box mt={2}>
        {error && (
          <FormHelperText style={{ color: "red" }}>
            These fields are mandatory.
          </FormHelperText>
        )}
        <Button
          variant="outlined"
          sx={{ ...saveButton }}
          onClick={updateUserHealthDeclaration}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
