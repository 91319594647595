export const COUNTRIES = [
  {
    id: 1,
    title: "Afghanistan"
  },
  {
    id: 2,
    title: "Albania"
  },
  {
    id: 3,
    title: "Alderney"
  },
  {
    id: 4,
    title: "Algeria"
  },
  {
    id: 5,
    title: "Andorra"
  },
  {
    id: 6,
    title: "Anguilla"
  },
  {
    id: 7,
    title: "Antigua"
  },
  {
    id: 8,
    title: "Argentina"
  },
  {
    id: 9,
    title: "Aruba"
  },
  {
    id: 10,
    title: "Australia"
  },
  {
    id: 11,
    title: "Austria"
  },
  {
    id: 12,
    title: "Bahamas"
  },
  {
    id: 13,
    title: "Bahrain"
  },
  {
    id: 14,
    title: "Bangladesh"
  },
  {
    id: 15,
    title: "Barbados"
  },
  {
    id: 16,
    title: "Barbuda"
  },
  {
    id: 17,
    title: "Belgium"
  },
  {
    id: 18,
    title: "Belize"
  },
  {
    id: 19,
    title: "Belarus"
  },
  {
    id: 20,
    title: "Benin"
  },
  {
    id: 21,
    title: "Bolivia"
  },
  {
    id: 22,
    title: "Botswana"
  },

  {
    id: 23,
    title: "British Virgin Islands"
  },
  {
    id: 24,
    title: "Brunei"
  },
  {
    id: 25,
    title: "Bulgaria"
  },
  {
    id: 26,
    title: "Central Africa Republic"
  },
  {
    id: 27,
    title: "Cambodia"
  },
  {
    id: 28,
    title: "Canada"
  },
  {
    id: 29,
    title: "Cape Verde Islands"
  },
  {
    id: 30,
    title: "Cayman Islands"
  },
  {
    id: 31,
    title: "Chile"
  },
  {
    id: 32,
    title: "China"
  },
  {
    id: 33,
    title: "Colombia"
  },
  {
    id: 34,
    title: "Congo"
  },
  {
    id: 35,
    title: "Cook Islands"
  },
  {
    id: 36,
    title: "Costa Rica"
  },
  {
    id: 37,
    title: "Cuba"
  },
  {
    id: 38,
    title: "Croatia"
  },
  {
    id: 39,
    title: "Cyprus"
  },
  {
    id: 40,
    title: "Czech"
  },
  {
    id: 41,
    title: "Denmark"
  },
  {
    id: 42,
    title: "DominicRep"
  },
  {
    id: 43,
    title: "Ecuador"
  },
  {
    id: 44,
    title: "Egypt"
  },
  {
    id: 45,
    title: "ElSalvador"
  },
  {
    id: 46,
    title: "Estonia"
  },
  {
    id: 47,
    title: "Ethiopia"
  },
  {
    id: 48,
    title: "Faroe Islands"
  },
  {
    id: 49,
    title: "Fiji"
  },
  {
    id: 50,
    title: "Finland"
  },
  {
    id: 51,
    title: "France"
  },
  {
    id: 52,
    title: "French Polynesia"
  },
  {
    id: 53,
    title: "Gambia"
  },
  {
    id: 54,
    title: "Germany"
  },
  {
    id: 55,
    title: "Ghana"
  },
  {
    id: 56,
    title: "Gibraltar"
  },
  {
    id: 57,
    title: "Greece"
  },
  {
    id: 58,
    title: "Grenada"
  },
  {
    id: 59,
    title: "Gt Britain"
  },
  {
    id: 60,
    title: "Guadeloupe"
  },
  {
    id: 61,
    title: "Guatemala"
  },
  {
    id: 62,
    title: "Guernsey"
  },
  {
    id: 63,
    title: "Guyana"
  },
  {
    id: 64,
    title: "Haiti"
  },
  {
    id: 65,
    title: "HK G"
  },
  {
    id: 66,
    title: "Holland"
  },
  {
    id: 67,
    title: "Honduras"
  },
  {
    id: 68,
    title: "Hong Kong"
  },
  {
    id: 69,
    title: "Hungary"
  },
  {
    id: 70,
    title: "Iceland"
  },
  {
    id: 71,
    title: "India"
  },
  {
    id: 72,
    title: "Indonesia"
  },
  {
    id: 73,
    title: "Iran"
  },
  {
    id: 74,
    title: "Iraq"
  },
  {
    id: 75,
    title: "Ireland"
  },
  {
    id: 76,
    title: "Isle o Man"
  },
  {
    id: 77,
    title: "Israel"
  },
  {
    id: 78,
    title: "Italy"
  },
  {
    id: 79,
    title: "IvoryCoast"
  },
  {
    id: 80,
    title: "Jamaica"
  },
  {
    id: 81,
    title: "Japan"
  },
  {
    id: 82,
    title: "Jersey"
  },
  {
    id: 83,
    title: "Jordan"
  },
  {
    id: 84,
    title: "Kampuchea"
  },
  {
    id: 85,
    title: "Kenya"
  },
  {
    id: 86,
    title: "Kuwait"
  },
  {
    id: 87,
    title: "Laos"
  },
  {
    id: 88,
    title: "Latvia"
  },
  {
    id: 89,
    title: "Lebanon"
  },
  {
    id: 90,
    title: "Lesotho"
  },
  {
    id: 91,
    title: "Libya"
  },
  {
    id: 92,
    title: "Liechtenstein"
  },
  {
    id: 93,
    title: "Lithuania"
  },
  {
    id: 94,
    title: "Luxembourg"
  },
  {
    id: 95,
    title: "Macau"
  },
  {
    id: 96,
    title: "Macedonia"
  },
  {
    id: 97,
    title: "Madagascar"
  },
  {
    id: 98,
    title: "Malawi"
  },
  {
    id: 99,
    title: "Malaysia"
  },
  {
    id: 100,
    title: "Maldives"
  },
  {
    id: 101,
    title: "Mali"
  },
  {
    id: 102,
    title: "Malta"
  },
  {
    id: 103,
    title: "Marshall Island"
  },
  {
    id: 104,
    title: "Martinique"
  },
  {
    id: 105,
    title: "Mauritania"
  },
  {
    id: 106,
    title: "Mauritius"
  },
  {
    id: 107,
    title: "Mexico"
  },
  {
    id: 108,
    title: "Monaco"
  },
  {
    id: 109,
    title: "Mongolia"
  },
  {
    id: 110,
    title: "Montserrat"
  },
  {
    id: 111,
    title: "Morocco"
  },
  {
    id: 112,
    title: "Myanmar"
  },
  {
    id: 113,
    title: "N Antilles"
  },
  {
    id: 114,
    title: "Nauru"
  },
  {
    id: 115,
    title: "New Zealand"
  },
  {
    id: 116,
    title: "Nicaragua"
  },
  {
    id: 117,
    title: "Niger"
  },
  {
    id: 118,
    title: "Nigeria"
  },
  {
    id: 119,
    title: "Northern Mariana Islands"
  },
  {
    id: 120,
    title: "Norway"
  },
  {
    id: 121,
    title: "Oman"
  },
  {
    id: 122,
    title: "Others"
  },
  {
    id: 123,
    title: "Pakistan"
  },
  {
    id: 124,
    title: "Panama"
  },
  {
    id: 125,
    title: "Paraguay"
  },
  {
    id: 126,
    title: "Peru"
  },
  {
    id: 127,
    title: "Philippine"
  },
  {
    id: 128,
    title: "Poland"
  },
  {
    id: 129,
    title: "Portugal"
  },
  {
    id: 130,
    title: "Qatar"
  },
  {
    id: 131,
    title: "Romania"
  },
  {
    id: 132,
    title: "Russia"
  },
  {
    id: 133,
    title: "Rwanda"
  },
  {
    id: 134,
    title: "Samoa"
  },
  {
    id: 135,
    title: "San Marino"
  },
  {
    id: 136,
    title: "Saudi Arabia"
  },
  {
    id: 137,
    title: "Senegal"
  },
  {
    id: 138,
    title: "Seychelles"
  },
  {
    id: 139,
    title: "Sierra Leon"
  },
  {
    id: 140,
    title: "Singapore"
  },
  {
    id: 141,
    title: "Slovakia"
  },
  {
    id: 142,
    title: "Slovenia"
  },
  {
    id: 143,
    title: "Somalia"
  },
  {
    id: 144,
    title: "South Africa"
  },
  {
    id: 145,
    title: "South Korea"
  },
  {
    id: 146,
    title: "Spain"
  },
  {
    id: 147,
    title: "Sri Lanka"
  },
  {
    id: 148,
    title: "St. Kitts & Nevis"
  },
  {
    id: 149,
    title: "St. Lucia"
  },
  {
    id: 150,
    title: "St. Martin"
  },
  {
    id: 151,
    title: "St. Vincent & The Grenadines"
  },
  {
    id: 152,
    title: "Suriname"
  },
  {
    id: 153,
    title: "Swaziland"
  },
  {
    id: 154,
    title: "Sweden"
  },
  {
    id: 155,
    title: "Switzerland"
  },
  {
    id: 156,
    title: "Syria"
  },
  {
    id: 157,
    title: "Trinidad & Tobago"
  },
  {
    id: 158,
    title: "Taiwan"
  },
  {
    id: 159,
    title: "Tanzania"
  },
  {
    id: 160,
    title: "Thailand"
  },
  {
    id: 161,
    title: "Togo"
  },
  {
    id: 162,
    title: "Tunisia"
  },
  {
    id: 163,
    title: "Turkestan"
  },
  {
    id: 164,
    title: "Turkey"
  },
  {
    id: 165,
    title: "Turks & Caicos Islands"
  },
  {
    id: 166,
    title: "UAE"
  },
  {
    id: 167,
    title: "Uganda"
  },
  {
    id: 168,
    title: "Ukraine"
  },
  {
    id: 169,
    title: "Uruguay"
  },
  {
    id: 170,
    title: "United Kingdom"
  },
  {
    id: 171,
    title: "United States of America"
  },
  {
    id: 172,
    title: "Vanuatu"
  },
  {
    id: 173,
    title: "Vatican"
  },
  {
    id: 174,
    title: "Venezuela"
  },
  {
    id: 175,
    title: "Vietnam"
  },
  {
    id: 176,
    title: "Yemen"
  },
  {
    id: 177,
    title: "Yugoslavia"
  },
  {
    id: 178,
    title: "Zaire"
  },
  {
    id: 179,
    title: "Zambia"
  },
  {
    id: 180,
    title: "Zimbabwe"
  }
];
