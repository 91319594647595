export class SmokingHabitsClass {
  constructor(yearsOfSmoking, noOfCigarettesPerDay) {
    this.yearsOfSmoking = yearsOfSmoking;
    this.noOfCigarettesPerDay = noOfCigarettesPerDay;
  }

  getSmokingHabitsDetails = () => {
    return {
      yearsOfSmoking: this.yearsOfSmoking,
      noOfCigarettesPerDay: this.noOfCigarettesPerDay
    };
  };
}
