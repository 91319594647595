export class OtherMedicalConditionClass {
  constructor(
    nameOfCondition,
    startDate,
    endDate,
    testTypeAndResult,
    treatmentType,
    nameAddressOfDoctorConsulted
  ) {
    this.nameOfCondition = nameOfCondition;
    this.startDate = startDate;
    this.endDate = endDate;
    this.testTypeAndResult = testTypeAndResult;
    this.treatmentType = treatmentType;
    this.nameAddressOfDoctorConsulted = nameAddressOfDoctorConsulted;
  }

  getOtherMedicalConditionDetails = () => {
    return {
      nameOfCondition: this.nameOfCondition,
      startDate: this.startDate,
      endDate: this.endDate,
      testTypeAndResult: this.testTypeAndResult,
      treatmentType: this.treatmentType,
      nameAddressOfDoctorConsulted: this.nameAddressOfDoctorConsulted
    };
  };
}
