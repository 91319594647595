import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { questionnairesStyles } from "../questionnaire.styles";

/**
  @typedef {{
    label: ReactNode;
    children: ReactNode;
  }} InputFieldWrapperProps
 */

/**
 * @param {InputFieldWrapperProps} props
 */
export const InputFieldWrapper = ({ label, children }) => {
  const { infoGrid } = questionnairesStyles();

  return (
    <Grid container flexDirection="column">
      <Grid item className={infoGrid}>
        {label}
      </Grid>
      <Grid item className={infoGrid}>
        {children}
      </Grid>
    </Grid>
  );
};

// export default InputFieldWrapper;
