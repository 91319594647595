import { useDispatch, useSelector } from "react-redux";
import {
  getDifferentiatedValues,
  getUser,
  getUserIndex
} from "../../../../../utils/HealthDeclarationForm.utils";
import { selectHealthDeclarationOfUsers } from "../../../../../redux/health-declaration/hdf.selectors";
import { useMemo } from "react";
import { handleUpdateHealthDeclaration } from "../../../../../redux/health-declaration/hdf.actions";

/**
  @typedef {{
    id: string;
    handleRequiredFieldsError: (key: string, subkey: string, isError: boolean) => void;
  }} HealthDeclarationProps
 */

/**
 * @param {string} formKey
 * @param {HealthDeclarationProps} props
 * @param {() => boolean} validation
 * @param {() => any[]} dataBuilder
 */
const useHealthDeclaration = (props, validation, dataBuilder) => {
  const dispatch = useDispatch();
  const { userId, key, subKey } = getDifferentiatedValues(props.id);
  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);

  const userIndex = useMemo(
    () => getUserIndex(healthDeclarationOfUsers, userId),
    [userId, healthDeclarationOfUsers]
  );
  const user = getUser(healthDeclarationOfUsers, userId);

  const updateUserHealthDeclaration = () => {
    const isValid = validation();
    props.handleRequiredFieldsError(key, subKey, !isValid);

    if (isValid) {
      const data = dataBuilder();

      const updatedUser = {
        ...user,
        [key]: { ...user[key], [subKey]: data }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return {
    key,
    subKey,
    userIndex,
    user,
    healthDeclarationOfUsers,
    updateUserHealthDeclaration
  };
};

export default useHealthDeclaration;
