import React from "react";
import {
  PrivateFlyingQuestionnaireForm,
  stateRules
} from "./PrivateFlyingQuestionnaireForm.component";
import { ExtremeSportQuestionnaireBase } from "./components/ExtremeSportQuestionnaireBase.component";
import { ExtremeSportsClass } from "../../../../model/ExtremeSports.class";

const FORM_KEY = "privateFlying";

/**
 * @param {import("./hooks/useHealthDeclaration").HealthDeclarationProps} props
 */
const PrivateFlyingQuestionnaire = props => (
  <ExtremeSportQuestionnaireBase
    {...props}
    onAddRecord={() => ({
      nameOfActivity: "",
      country: "",
      otherCountry: "",
      frequency: ""
    })}
    extremeSportDataBuilder={v =>
      new ExtremeSportsClass(
        v.country,
        v.frequency,
        null,
        v.nameOfActivity,
        v.otherCountry
      )
    }
    stateRules={stateRules}
    formItemMapper={({ onChange, onValidate, item }) => (
      <PrivateFlyingQuestionnaireForm
        value={item}
        onChange={onChange}
        onValidate={onValidate}
        validateOnMount
      />
    )}
  />
);

export { PrivateFlyingQuestionnaire, FORM_KEY };
