import React from "react";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  InputAdornment,
  Input,
  TextField
} from "@mui/material";
import { ExtremeSportsClass } from "../../../../model/ExtremeSports.class";
import { questionnairesStyles } from "../questionnaire.styles";

import { COUNTRIES } from "../../../../config/app.constants";
import useForm from "./hooks/useForm";
import { InputFieldWrapper } from "../components/InputFieldWrapper.component";
import { ExtremeSportQuestionnaireBase } from "./components/ExtremeSportQuestionnaireBase.component";

/**
  @typedef {{
    nameOfActivity: string;
    country: string;
    frequency: string;
    otherCountry: string;
  }} OtherExtremeSportValue
 */

/**
  @typedef {Omit<import("./hooks/useForm").FormProps<OtherExtremeSportValue, keyof OtherExtremeSportValue>, 'changeModifier'|'stateRules'>} OtherExtremeSportQuestionnaireFormProps
 */

export const stateRules = {
  nameOfActivity: "required",
  country: "required",
  frequency: "required",
  otherCountry: (val, data) => {
    if (data.country !== "Others") return true;

    return Boolean(val);
  }
};

/** @param {OtherExtremeSportQuestionnaireFormProps} */
const OtherExtremeSportsEngagementQuestionnaireForm = props => {
  const { textFieldRed } = questionnairesStyles();
  const { errorFields, handleUpdateField, state, handleKeyPress } = useForm({
    ...props,
    stateRules,
    changeModifier: (state, field, value) => {
      if (field === "country" && value !== "Others") {
        state.otherCountry = "";
      }
    }
  });

  return (
    <Box display="grid" gridTemplateColumns="repeat(3,1fr)" gap={2}>
      <InputFieldWrapper label={<Box fontWeight="bold">Name of Activity</Box>}>
        <TextField
          value={state.nameOfActivity}
          variant="standard"
          className={textFieldRed}
          onChange={event =>
            handleUpdateField("nameOfActivity", event.target.value)
          }
          error={errorFields.nameOfActivity.error}
          required
        />
        {errorFields.nameOfActivity.error ? (
          <FormHelperText style={{ color: "red" }}>
            This field is mandatory.
          </FormHelperText>
        ) : (
          ""
        )}
      </InputFieldWrapper>
      <InputFieldWrapper
        label={<Box fontWeight="bold">Country of Activity</Box>}
      >
        <FormControl variant="standard" sx={{ m: 0 }}>
          <Select
            value={state.country}
            onChange={event => {
              handleUpdateField("country", event.target.value);
            }}
            required
            error={errorFields.country.error}
          >
            {COUNTRIES.map(country => (
              <MenuItem key={country.id} value={country.title}>
                {country.title}
              </MenuItem>
            ))}
          </Select>
          {errorFields.country.error ? (
            <FormHelperText style={{ color: "red" }}>
              This field is mandatory.
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </InputFieldWrapper>
      <InputFieldWrapper
        label={<Box fontWeight="bold">Frequency of Activity</Box>}
      >
        <Input
          variant="standard"
          className={textFieldRed}
          type="number"
          required
          error={errorFields.frequency.error}
          endAdornment={
            <InputAdornment position="end">times per year</InputAdornment>
          }
          onChange={event => {
            handleUpdateField("frequency", event.target.value);
          }}
          onKeyPress={handleKeyPress}
          value={state.frequency}
          inputProps={{ min: 1 }}
        />
        {errorFields.frequency.error ? (
          <FormHelperText style={{ color: "red" }}>
            This field is mandatory.
          </FormHelperText>
        ) : (
          ""
        )}
      </InputFieldWrapper>
      <Box gridColumn="2 / 3">
        <InputFieldWrapper
          label={
            <Box
              fontWeight="bold"
              style={{
                color: state.country !== "Others" ? "grey" : "black"
              }}
            >
              Others, please specify
            </Box>
          }
        >
          <TextField
            variant="standard"
            className={textFieldRed}
            disabled={state.country !== "Others" ? true : false}
            error={errorFields.otherCountry.error}
            onChange={event => {
              handleUpdateField("otherCountry", event.target.value);
            }}
            value={state.otherCountry}
          />
          {errorFields.otherCountry.error ? (
            <FormHelperText style={{ color: "red" }}>
              This field is mandatory.
            </FormHelperText>
          ) : (
            ""
          )}
        </InputFieldWrapper>
      </Box>
    </Box>
  );
};

/**
 * @param {import("./hooks/useHealthDeclaration").HealthDeclarationProps} props
 */
export const OtherExtremeSportsEngagementQuestionnaire = props => (
  <ExtremeSportQuestionnaireBase
    {...props}
    onAddRecord={() => ({
      nameOfActivity: "",
      country: "",
      frequency: "",
      otherCountry: ""
    })}
    stateRules={stateRules}
    extremeSportDataBuilder={v =>
      new ExtremeSportsClass(
        v.country,
        v.frequency,
        null,
        v.nameOfActivity,
        v.otherCountry
      )
    }
    formItemMapper={({ item, onChange, onValidate }) => (
      <OtherExtremeSportsEngagementQuestionnaireForm
        value={item}
        onChange={onChange}
        onValidate={onValidate}
        validateOnMount
      />
    )}
  />
);
