export class HealthScreeningHistoryClass {
  constructor(testDate, testType, testReason, testResult, typeOfTreatment) {
    this.testDate = testDate;
    this.testType = testType;
    this.testReason = testReason;
    this.testResult = testResult;
    this.typeOfTreatment = typeOfTreatment;
  }

  getHealthScreeningHistoryDetails = () => {
    return {
      testDate: this.testDate,
      testType: this.testType,
      testReason: this.testReason,
      testResult: this.testResult,
      typeOfTreatment: this.typeOfTreatment
    };
  };
}
