export const isHDFRequiredForEmployee = (
  isNewEmployee,
  isUpgradingCoverage
) => {
  return isNewEmployee || isUpgradingCoverage;
};

export const isHDFRequiredForDependent = (
  isNewDependent,
  isUpgradingCoverage
) => {
  return isNewDependent || isUpgradingCoverage;
};

export const getDifferentiatedValues = value => {
  const differentiator = value.toString().split("=");

  const optionValue = differentiator[0];
  const userId = differentiator[1];
  const questionId = differentiator[2];
  const key = differentiator[3];
  const subKey = differentiator[4];

  return {
    optionValue,
    userId,
    questionId,
    key,
    subKey
  };
};

export const getUserIndex = (healthDeclarationOfUsers, userId) => {
  const userIndex = healthDeclarationOfUsers.findIndex(
    healthDeclarationOfUser => healthDeclarationOfUser.id == userId
  );

  return userIndex;
};

export const getUser = (healthDeclarationOfUsers, userId) => {
  const user = healthDeclarationOfUsers.find(
    healthDeclarationOfUser => healthDeclarationOfUser.id.toString() === userId
  );

  return user;
};

export const isOnlyAndMainEmployee = hdfUsers => {
  if (hdfUsers.length) {
    if (hdfUsers.length > 1) {
      return false;
    } else if (hdfUsers[0].person_type === "EMPLOYEE") {
      return true;
    }
    return false;
  }
};

export const hdfUserListHasFemale = hdfUsers => {
  return hdfUsers.some(hdfUser => hdfUser["gender"] === "F");
};
