export class FamilyMedicalHistoryClass {
  constructor(relationship, causeOfDeath, ageOfOnset, ageOfDeath) {
    this.relationship = relationship;
    this.causeOfDeath = causeOfDeath;
    this.ageOfOnset = ageOfOnset;
    this.ageOfDeath = ageOfDeath;
  }

  getFamilyMedicalHistoryDetails = () => {
    return {
      relationship: this.relationship,
      causeOfDeath: this.causeOfDeath,
      ageOfOnset: this.ageOfOnset,
      ageOfDeath: this.ageOfDeath
    };
  };
}
