import { enrolment } from "../assets";
// import { availableProducts as avlProducts } from "./data/productInfo";
import _ from "lodash";
import { createSelector } from "reselect";
import { availableProducts as avlProducts } from "../assets/data/productInfo";

const GRAB_PRODUCT_CODE = "GCP";

export const getAccessToken = state => {
  const token =
    state.user && state.user.user ? state.user.user.access_token : "";
  return token;
};

export const getAvaliablePlans = (employeeType, clientType) => {
  const availableProducts = avlProducts[employeeType];
  return _.filter(enrolment[clientType].default.current, ({ option }) =>
    _.includes(availableProducts, option)
  );
};

export const getUser = state => state.user;

export const getSelectedPlan = createSelector(getUser, user => {
  if (user.planSelected instanceof Map) {
    return Array.from(user.planSelected.entries());
  }

  return user.planSelected || [];
});

// export const getCategories = quote => {
//   const categories = quote.data.categories;
//   return categories.map(cat => ({
//     ...cat,
//     plansConfiguration: cat.plansConfiguration.filter(c => {
//       return !Util.isEmpty(c.tier);
//     })
//   }));
// };

// export const getCategoryName = quote => {
//   const categories = quote.data.categories;
//   return categories.map(cat => cat.name);
// };

// export const getAvaliableProductBenefits = agentType => {
//   const availableProducts = assets.productInfo.availableProducts[agentType];
//   return _.filter(assets.benefits.list.default, ({ key }) =>
//     _.includes(availableProducts, key)
//   );
// };

// export const isGrabGroup = (appType, HR) =>
//   appType === Enums.APP_TYPE.HR &&
//   HR &&
//   HR.coverage.policy.product_code === GRAB_PRODUCT_CODE;

// export const isGrabGroupEmployee = EMPLOYEE =>
//   EMPLOYEE &&
//   EMPLOYEE.policy &&
//   EMPLOYEE.policy.product_code === GRAB_PRODUCT_CODE;
