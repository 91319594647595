import React, { useEffect, useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize"; // necessary for latest version
import "react-dates/lib/css/_datepicker.css";
import "../date-picker/react_dates_overrides.css";
import moment from "moment/moment";

export const DatePicker = props => {
  const [focusedInput, setFocusedInput] = useState(false);

  const [date, setDate] = useState(undefined);

  useEffect(() => {
    if (props.date){
      setDate(moment(props.date, 'MM/DD/YYYY'));
    }
  }, []);

  const handleDateSelection = pickedDate => {
    if (pickedDate) {
      setDate(pickedDate);
      props.onDateChange(pickedDate.format("MM/DD/YYYY"));
      setFocusedInput(false);
    }
  };

  return (
    <SingleDatePicker
      date={date}
      onDateChange={date => handleDateSelection(date)}
      focused={focusedInput}
      onFocusChange={({ focused }) => setFocusedInput(focused)}
      isOutsideRange={() => false}
      id="your_unique_id"
      placeholder="MM/DD/YYYY"
      required
    />
  );
};
