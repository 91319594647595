import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import MobileDetect from "mobile-detect";
import Grid from "@mui/material/Grid";
const mobileDetect = new MobileDetect(window.navigator.userAgent);

class PopupNotice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopUpClosed: false
    };
  }

  isLandscapeMode = () => {
    return (
      mobileDetect.tablet() !== null || window.innerHeight < window.innerWidth
    );
  };

  onClosePopup = () => {
    this.setState({ isPopUpClosed: true });
  };

  render() {
    const { classes, message } = this.props;

    const { isPopUpClosed } = this.state;

    if (!isPopUpClosed == true) {
      return (
        <div className={classes.sticky}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={11}
              sm={11}
              md={11}
              lg={11}
              className={classes.stickyContainer}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: message
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              className={classes.closeButton}
            >
              <div
                onClick={this.onClosePopup}
                className={classes.closeButtonContainer}
              >
                X
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    zIndex: 1
  },
  sticky: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    background: "#fff3cd",
    color: "#846923",
    textAlign: "center",
    zIndex: 2000,
    left: "0 !important",
    minHeight: "100px",
    display: "flex",
    alignItems: "center"
  },
  stickyContainer: {
    paddingLeft: "2rem",
    fontFamily: "OpenSans-Bold"
  },
  closeButton: {
    textAlign: "center"
  },
  closeButtonContainer: {
    fontFamily: "OpenSans-Bold",
    marginTop: "10px",
    maxHeight: "20px",
    maxWidth: "20px",
    margin: "auto",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.2)"
    }
  }
});

export default withStyles(styles, { withTheme: true })(PopupNotice);
