import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { OtherMedicalConditionClass } from "../../../../model/OtherMedicalCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const NervousMentalQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userNervousMental, setUserNervousMental] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.otherMedicalConditions?.nervousMental &&
      setUserNervousMental(user.otherMedicalConditions.nervousMental);

    let validateSuccess = validateNervousMental();

    let medicalCondition = user?.otherMedicalConditions?.nervousMental;

    if (!medicalCondition || medicalCondition.length == 0) {
      setError(true);
      handleAddNervousMental([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserNervousMental(medicalCondition);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    if (userNervousMental.length > 0) {
      updateUserHealthDeclaration();
    }
  }, [userNervousMental.length]);

  const handleAddNervousMental = () => {
    setUserNervousMental([
      ...userNervousMental,
      {
        nameOfCondition: "",
        nameOfConditionError: false,
        startDate: "",
        startDateError: false,
        endDate: "",
        endDateError: false,
        testTypeAndResult: "",
        testTypeAndResultError: false,
        treatmentType: "",
        treatmentTypeError: false,
        nameAddressOfDoctorConsulted: "",
        nameAddressOfDoctorConsultedError: false
      }
    ]);
  };

  const handleUpdateField = (field, index, value) => {
    userNervousMental[index][field] = value;
    setUserNervousMental([...userNervousMental]);
  };

  const handleUpdateDate = (field, index, event) => {
    userNervousMental[index][field] = event;
    setUserNervousMental([...userNervousMental]);
  };

  const validateNervousMental = () => {
    let validateSuccess = true;
    let errorDisplay = "";

    if (userNervousMental.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, subKey, true);
      errorDisplay = "Please enter at least 1 medical condition";
    }

    //mark field error
    userNervousMental.map(userNervousMental => {
      userNervousMental.nameOfConditionError = false;
      userNervousMental.startDateError = false;
      userNervousMental.endDateError = false;
      userNervousMental.testTypeAndResultError = false;
      userNervousMental.treatmentTypeError = false;
      userNervousMental.nameAddressOfDoctorConsultedError = false;

      if (!userNervousMental.nameOfCondition) {
        userNervousMental.nameOfConditionError = true;
      }

      if (!userNervousMental.startDate) {
        userNervousMental.startDateError = true;
      }

      if (!userNervousMental.endDate) {
        userNervousMental.endDateError = true;
      }

      if (!userNervousMental.testTypeAndResult) {
        userNervousMental.testTypeAndResultError = true;
      }

      if (!userNervousMental.treatmentType) {
        userNervousMental.treatmentTypeError = true;
      }

      if (!userNervousMental.nameAddressOfDoctorConsulted) {
        userNervousMental.nameAddressOfDoctorConsultedError = true;
      }
    });

    let errorObject = userNervousMental.find(userNervousMental => {
      return (
        userNervousMental.nameOfConditionError ||
        userNervousMental.startDateError ||
        userNervousMental.endDateError ||
        userNervousMental.testTypeAndResultError ||
        userNervousMental.treatmentTypeError ||
        userNervousMental.nameAddressOfDoctorConsultedError
      );
    });

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserNervousMental([...userNervousMental]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateNervousMental();
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, subKey, false);

      let nervousMental = [];
      let nervousMentalObject = {};

      userNervousMental.map(userNervousMental => {
        const medicalCondition = new OtherMedicalConditionClass(
          userNervousMental.nameOfCondition,
          userNervousMental.startDate,
          userNervousMental.endDate,
          userNervousMental.testTypeAndResult,
          userNervousMental.treatmentType,
          userNervousMental.nameAddressOfDoctorConsulted
        );
        nervousMentalObject = medicalCondition.getOtherMedicalConditionDetails();
        nervousMental.push(nervousMentalObject);
      });

      const updatedUser = {
        ...user,
        [key]: { ...user[key], nervousMental }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleRemove = index => {
    userNervousMental.splice(index, 1);
    setUserNervousMental([...userNervousMental]);

    validateNervousMental();
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      {userNervousMental.map((nervousMental, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Medical Condition {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button onClick={e => handleRemove(index)}>
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Name of Condition</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={nervousMental.nameOfConditionError}
                      helperText={
                        nervousMental.nameOfConditionError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameOfCondition",
                          index,
                          event.target.value
                        );
                      }}
                      value={nervousMental.nameOfCondition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Start Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={nervousMental.startDate}
                      date={nervousMental.startDate}
                      onDateChange={event => {
                        handleUpdateDate("startDate", index, event);
                      }}
                    />
                    {nervousMental.startDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">End Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={nervousMental.endDate}
                      date={nervousMental.endDate}
                      onDateChange={event => {
                        handleUpdateDate("endDate", index, event);
                      }}
                    />
                    {nervousMental.endDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of test(s) done and result</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={nervousMental.testTypeAndResultError}
                      helperText={
                        nervousMental.testTypeAndResultError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "testTypeAndResult",
                          index,
                          event.target.value
                        );
                      }}
                      value={nervousMental.testTypeAndResult}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of Treatment</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={nervousMental.treatmentTypeError}
                      helperText={
                        nervousMental.treatmentTypeError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "treatmentType",
                          index,
                          event.target.value
                        );
                      }}
                      value={nervousMental.treatmentType}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">
                      Name & Address of Doctors/ Clinics/ Hospitals
                    </Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={nervousMental.nameAddressOfDoctorConsultedError}
                      helperText={
                        nervousMental.nameAddressOfDoctorConsultedError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameAddressOfDoctorConsulted",
                          index,
                          event.target.value
                        );
                      }}
                      value={nervousMental.nameAddressOfDoctorConsulted}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}

      <Button
        // className={addPrivateFlying}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddNervousMental}
      >
        <FormHelperText
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "1em",
            textTransform: "none"
          }}
        >
          Add Medical Condition
        </FormHelperText>
      </Button>

      <br />
      <br />
      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>

      <TableRow>
        <br />
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>

      <br />
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
