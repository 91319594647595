import { HDF_TYPES } from "./hdf.types";

export const HDF_INITIAL_STATE = {
  users: [],
  isSubmittingHDF: false,
  packageId: undefined,
  signingUrl: undefined,
  spouseSigningUrl: undefined,
  isMainDocumentSigned: false,
  isSupplementDocumentSigned: false,
  packageStatus: false,
  CMDocumentName: undefined,
  hasHDF: false
};

export const hdfReducer = (state = HDF_INITIAL_STATE, action) => {
  const { UPDATE_HEALTH_DECLARATION, HAS_HEALTH_DECLARATION } = HDF_TYPES;

  const {
    SUBMIT_HDF_STARTED,
    SUBMIT_HDF_SUCCESS,
    SUBMIT_HDF_FAILED
  } = HDF_TYPES;

  const {
    GET_DOCUMENT_STATUS_START,
    GET_DOCUMENT_STATUS_SUCCESS,
    GET_DOCUMENT_STATUS_FAILED
  } = HDF_TYPES;

  const {
    GET_PACKAGE_STATUS_START,
    GET_PACKAGE_STATUS_SUCCESS,
    GET_PACKAGE_STATUS_FAILED
  } = HDF_TYPES;

  const {
    GET_CM_DOCUMENT_DETAILS_START,
    GET_CM_DOCUMENT_DETAILS_SUCCESS,
    GET_CM_DOCUMENT_DETAILS_FAILED
  } = HDF_TYPES;

  switch (action.type) {
    case UPDATE_HEALTH_DECLARATION:
      return {
        ...state,
        users: action.payload
      };

    case HAS_HEALTH_DECLARATION:
      return {
        ...state,
        hasHDF: action.payload
      };

    case SUBMIT_HDF_STARTED:
      return {
        ...state,
        isSubmittingHDF: true,
        packageId: undefined,
        signingUrl: undefined,
        spouseSigningUrl: undefined
      };
    case SUBMIT_HDF_SUCCESS:
      return {
        ...state,
        isSubmittingHDF: false,
        packageId: action.payload.package_id,
        signingUrl: action.payload.signing_url,
        spouseSigningUrl: action.payload.spouse_signing_url
      };
    case SUBMIT_HDF_FAILED:
      return {
        ...state,
        isSubmittingHDF: false,
        packageId: undefined,
        signingUrl: undefined,
        spouseSigningUrl: undefined
      };

    case GET_DOCUMENT_STATUS_START:
      return {
        ...state,
        isMainDocumentSigned: false,
        isSupplementDocumentSigned: false
      };
    case GET_DOCUMENT_STATUS_SUCCESS:
      return {
        ...state,
        isMainDocumentSigned: action.payload.mainDocumentStatus,
        isSupplementDocumentSigned: action.payload.supplementDocumentStatus
      };
    case GET_DOCUMENT_STATUS_FAILED:
      return {
        ...state,
        isMainDocumentSigned: false,
        isSupplementDocumentSigned: false
      };

    case GET_PACKAGE_STATUS_START:
      return {
        ...state,
        packageStatus: false
      };
    case GET_PACKAGE_STATUS_SUCCESS:
      return {
        ...state,
        packageStatus: true
      };
    case GET_PACKAGE_STATUS_FAILED:
      return {
        ...state,
        packageStatus: false
      };

    case GET_CM_DOCUMENT_DETAILS_START: {
      return {
        ...state,
        CMDocumentName: undefined
      };
    }
    case GET_CM_DOCUMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        CMDocumentName: action.payload
      };
    }
    case GET_CM_DOCUMENT_DETAILS_FAILED: {
      return {
        ...state,
        CMDocumentName: undefined
      };
    }

    default:
      return state;
  }
};
