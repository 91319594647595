import { HDF_TYPES } from "./hdf.types";

const { UPDATE_HEALTH_DECLARATION, HAS_HEALTH_DECLARATION } = HDF_TYPES;

export const updateHealthDeclaration = users => ({
  type: UPDATE_HEALTH_DECLARATION,
  payload: users
});

export const handleUpdateHealthDeclaration = users => {
  return dispatch => {
    dispatch(updateHealthDeclaration(users));
  };
};

export const updateHasHDF = hasHDF => ({
  type: HAS_HEALTH_DECLARATION,
  payload: hasHDF
});

export const handleUpdateHasHDF = hasHDF => {
  return async dispatch => {
    dispatch(updateHasHDF(hasHDF));
  };
};
