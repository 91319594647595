import { useState } from "react";

const ErrorType = Object.freeze({
  EMPTY_ARRAY: "EMPTY_ARRAY"
});

const ErrorMessage = {
  [ErrorType.EMPTY_ARRAY]: "Please enter at least 1 activity"
};

const ValidationRule = {
  required: val => Boolean(val)
};

const runValidate = (state, rule) => {
  const result = [];

  for (const k in state) {
    if (!(k in rule)) continue;

    const validation = rule[k];
    const value = state[k];
    let isValid = true;
    let errorMessage;
    if (validation) {
      if (typeof validation === "string") {
        isValid = ValidationRule[validation](value, state);
        errorMessage = validation;
      } else {
        const result = validation(value, state);
        if (typeof result !== "boolean") {
          ({ result: isValid, message: errorMessage } = result);
        } else {
          errorMessage = "not met validation";
          isValid = result;
        }
      }
    }

    result.push({
      field: k,
      isValid,
      error: {
        message: errorMessage
      }
    });
  }

  return result;
};

/**
  @template {object} T
  @typedef {(val:any, data: T) => boolean|{result: boolean; message: string}} ValidationFn
 */

/**
  @template {string} K
  @template {object} T
  @typedef {Record<K, keyof typeof ValidationRule | ValidationFn<T>>} StateRules
 */

/**
  @typedef {(validationResult: boolean, fields: Record<K, { error: boolean; message: string }>) => void} OnValidate
 */

/**
  @template {object} T
  @template {string} K 
  @param {StateRules<K, T>} rule 
  @param {{
    onValidate?: OnValidate;
  }|undefined} options
 */
const useValidateFields = (rule, options) => {
  const [errorFields, setErrorFields] = useState(
    /** @returns {Record<K, { error: boolean; message: string }>} */
    () =>
      Object.fromEntries(
        Object.keys(rule).map(key => [key, { error: false, message: "" }])
      )
  );

  const reset = () => {
    setErrorFields({});
  };

  /**
   * @param {Record<K, unknown>} state
   * @param {boolean} runOnvalidate
   */
  const validate = (state, runOnvalidate = true) => {
    let isValidationSuccess = true;

    const _errorFields = {};
    const overallErrors = runValidate(state, rule).map(v => {
      _errorFields[v.field] = {
        error: !v.isValid,
        message: v.error.message
      };
      return v.isValid;
    });

    isValidationSuccess = overallErrors.every(v => v);
    setErrorFields(_errorFields);

    if (runOnvalidate) options?.onValidate?.(isValidationSuccess, _errorFields);

    return {
      result: isValidationSuccess,
      fields: errorFields
    };
  };

  return {
    errorFields,
    validate,
    reset
  };
};

export {
  useValidateFields,
  runValidate,
  ValidationRule,
  ErrorMessage,
  ErrorType
};
