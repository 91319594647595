import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { AlcoholConsumptionClass } from "../../../../model/AlcoholConsumption.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

export const AlcoholConsumptionQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [alcoholType, setAlcoholType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [frequency, setFrequency] = useState("");

  const [alcoholTypeError, setAlcoholTypeError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [frequencyError, setFrequencyError] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);
  useEffect(() => {
    user?.alcohol?.alcoholType && setAlcoholType(user.alcohol.alcoholType);
    user?.alcohol?.quantity && setQuantity(user.alcohol.quantity);
    user?.alcohol?.frequency && setFrequency(user.alcohol.frequency);

    let validateSuccess = validateFields();

    if (user?.alcohol || validateSuccess) {
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [alcoholType, quantity, frequency];

  const validateFields = () => {
    let validateSuccess = true;

    setAlcoholTypeError(!alcoholType);
    setQuantityError(!quantity);
    setFrequencyError(!frequency);

    if (!alcoholType || !quantity || !frequency) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const alcoholConsumption = new AlcoholConsumptionClass(
        alcoholType,
        quantity,
        frequency
      );
      const alcoholConsumptionObject = {};
      alcoholConsumptionObject[
        subKey
      ] = alcoholConsumption.getAlcoholConsumptionDetails();

      const updatedUser = {
        ...user,
        ...alcoholConsumptionObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleKeyPress = event => {
    const { key } = event;

    // Disable input of "e" and "-"
    if (key === "e" || key === "-") {
      event.preventDefault();
    }

    // Ensure minimum value is greater than 0
    const currentValue = event.target.value;
    const newValue =
      event.key === "Backspace"
        ? currentValue.slice(0, -1)
        : currentValue + event.key;
    const numericValue = Number(newValue);

    if (numericValue <= 0) {
      event.preventDefault();
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) === true
          ? ""
          : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) === true
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid className={infoGrid} item xs={5}>
              <Box fontWeight="bold">Type of Alcohol</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                color="error"
                className={textFieldRed}
                required
                error={alcoholTypeError}
                helperText={alcoholTypeError && "This field is mandatory."}
                onChange={event => {
                  setAlcoholType(event.target.value);
                }}
                value={alcoholType}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Quantity</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                type="number"
                required
                error={quantityError}
                helperText={quantityError && "This field is mandatory."}
                onChange={event => {
                  setQuantity(event.target.value);
                }}
                onKeyPress={handleKeyPress}
                value={quantity}
                inputProps={{ min: 1 }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Frequency (per week)</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                type="number"
                required
                error={frequencyError}
                helperText={frequencyError && "This field is mandatory."}
                onChange={event => {
                  setFrequency(event.target.value);
                }}
                onKeyPress={handleKeyPress}
                value={frequency}
                inputProps={{ min: 1 }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
