export const APP_RESET = "APP_RESET";
export const APP_SET_ERROR = "APP_SET_ERROR";

export const USER_UPDATE_TOKEN = "USER_UPDATE_TOKEN";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_REFRESH_TOKEN_PENDING = "USER_REFRESH_TOKEN_PENDING";
export const USER_REFRESH_TOKEN_FULFILLED = "USER_REFRESH_TOKEN_FULFILLED";
export const USER_REFRESH_TOKEN_REJECTED = "USER_REFRESH_TOKEN_REJECTED";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_GET_INFO = "USER_GET_INFO";
export const USER_GET_INFO_SUCCESS = "USER_GET_INFO_SUCCESS";
export const USER_GET_INFO_ERROR = "USER_GET_INFO_ERROR";
export const USER_GET_POLICY_SUCCESS = "USER_GET_POLICY_SUCCESS";
export const USER_GET_POLICY_ERROR = "USER_GET_POLICY_ERROR";

export const EMPLOYEE_GET_INFO = "EMPLOYEE_GET_INFO";
export const EMPLOYEE_GET_INFO_SUCCESS = "EMPLOYEE_GET_INFO_SUCCESS";
export const EMPLOYEE_GET_INFO_ERROR = "EMPLOYEE_GET_INFO_ERROR";

export const APP_SCROLL = "APP_SCROLL";
export const APP_SCROLL_FIN = "APP_SCROLL_FIN";

export const SELECT_PLAN = "SELECT_PLAN";
export const SELECT_DEPENDENT_PLAN = "SELECT_DEPENDENT_PLAN";

export const HANDLE_NEXT = "HANDLE_NEXT";
export const PREMIUM_CALC_EMP = "PREMIUM_CALC_EMP";
export const PREMIUM_CALC_DEP = "PREMIUM_CALC_DEP";
export const DEPENDENT_OPT_OUT = "DEPENDENT_OPT_OUT";

export const CONSENT = "CONSENT";
export const PAYMENT_CONSENT = "PAYMENT_CONSENT";
export const HDF_CONSENT = "HDF_CONSENT";

export const PAYLOAD = "PAYLOAD";
export const USER_UPDATE_COVERAGE_DETAILS = "USER_UPDATE_COVERAGE_DETAILS";
export const UPDATE_USER_DEPENDANTS = "UPDATE_USER_DEPENDANTS";
export const EMPLOYEE_OPTION_SELECTED = "EMPLOYEE_OPTION_SELECTED";
export const DEPENDENT_OPTION_SELECTED = "DEPENDENT_OPTION_SELECTED";
export const DEPENDENT_OPTOUT_LIST = "DEPENDENT_OPTOUT_LIST";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const LOAD_PLANS = "LOAD_PLANS";
export const LOAD_DEFAULT_LABELS = "LOAD_DEFAULT_LABELS";
export const LOAD_PREVIOUS_PLANS = "LOAD_PREVIOUS_PLANS";
export const ACTIVE_STEP = "ACTIVE_STEP";
export const SHOW_BADGE = "SHOW_BADGE";
export const DEPENDENT_BY_RELATION = "DEPENDENT_BY_RELATION";

export const DEPENDENT_OPT_OUT_LIST = "DEPENDENT_OPT_OUT_LIST";
export const EMPLOYEE_OPT_OUT_LIST = "EMPLOYEE_OPT_OUT_LIST";

export const DEPENDENT_OPT_OUT_NAME_LIST = "DEPENDENT_OPT_OUT_NAME_LIST";
export const EMPLOYEE_OPT_OUT_NAME_LIST = "EMPLOYEE_OPT_OUT_NAME_LIST";

export const USER_SAVE_INFO_ERROR = "USER_SAVE_INFO_ERROR";
export const OCCUPATION_CLASS_LIST_SUCCESS = "OCCUPATION_CLASS_LIST_SUCCESS";
export const OCCUPATION_CLASS_LIST_ERROR = "OCCUPATION_CLASS_LIST_ERROR";
export const SELECT_HDF_VALUE = "SELECT_HDF_VALUE";
export const SELECT_HDF_REMARKS = "SELECT_HDF_REMARKS";
export const SA_PREMIUM = "SA_PREMIUM";
export const DEP_SA_PREMIUM = "DEP_SA_PREMIUM";
export const TOTAL_PREMIUM = "TOTAL_PREMIUM";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const HDF_PAYLOAD = "HDF_PAYLOAD";

export const CHECK_HDF_SIGNED_STARTED = "CHECK_HDF_SIGNED_STARTED";
export const CHECK_HDF_SIGNED_SUCCESS = "CHECK_HDF_SIGNED_SUCCESS";
export const CHECK_HDF_SIGNED_FAILED = "CHECK_HDF_SIGNED_FAILED";

export const GET_SIGNED_URL_STARTED = "GET_SIGNED_URL_STARTED";
export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";
export const GET_SIGNED_URL_FAILED = "GET_SIGNED_URL_FAILED";
