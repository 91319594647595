import * as actionTypes from "./types";

export const appActions = {
  reset,
  scrollToTop,
  scrollToTopFin,
  setError
};

function reset(){
  return{ type: actionTypes.APP_RESET }
}
function scrollToTop() {
  return { type: actionTypes.APP_SCROLL };
}
function scrollToTopFin() {
  return { type: actionTypes.APP_SCROLL_FIN };
}
function setError(error) {
  return {
    type: actionTypes.APP_SET_ERROR,
    payload: error
  };
}
