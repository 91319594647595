export class STDHistoryClass {
  constructor(
    date,
    relationship,
    diagnosis,
    treatment,
    nameAddressOfDoctorConsulted
  ) {
    this.date = date;
    this.relationship = relationship;
    this.diagnosis = diagnosis;
    this.treatment = treatment;
    this.nameAddressOfDoctorConsulted = nameAddressOfDoctorConsulted;
  }

  getSTDHistoryDetails = () => {
    return {
      date: this.date,
      relationship: this.relationship,
      diagnosis: this.diagnosis,
      treatment: this.treatment,
      nameAddressOfDoctorConsulted: this.nameAddressOfDoctorConsulted
    };
  };
}
