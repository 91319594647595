export const HDF_TYPES = {
  CHECK_HDF_REQUIRED_STARTED: "CHECK_HDF_REQUIRED_STARTED",
  CHECK_HDF_REQUIRED_SUCCESS: "CHECK_HDF_REQUIRED_SUCCESS",
  CHECK_HDF_REQUIRED_FAILED: "CHECK_HDF_REQUIRED_FAILED",

  UPDATE_HEALTH_DECLARATION: "UPDATE_HEALTH_DECLARATION",
  HAS_HEALTH_DECLARATION: "HAS_HEALTH_DECLARATION",

  SUBMIT_HDF_STARTED: "SUBMIT_HDF_STARTED",
  SUBMIT_HDF_SUCCESS: "SUBMIT_HDF_SUCCESS",
  SUBMIT_HDF_FAILED: "SUBMIT_HDF_FAILED",

  GET_DOCUMENT_STATUS_START: "GET_DOCUMENT_STATUS_START",
  GET_DOCUMENT_STATUS_SUCCESS: "GET_DOCUMENT_STATUS_SUCCESS",
  GET_DOCUMENT_STATUS_FAILED: "GET_DOCUMENT_STATUS_FAILED",

  GET_PACKAGE_STATUS_START: "GET_PACKAGE_STATUS_START",
  GET_PACKAGE_STATUS_SUCCESS: "GET_PACKAGE_STATUS_SUCCESS",
  GET_PACKAGE_STATUS_FAILED: "GET_PACKAGE_STATUS_FAILED",

  GET_CM_DOCUMENT_DETAILS_START: "GET_CM_DOCUMENT_DETAILS_START",
  GET_CM_DOCUMENT_DETAILS_SUCCESS: "GET_CM_DOCUMENT_DETAILS_SUCCESS",
  GET_CM_DOCUMENT_DETAILS_FAILED: "GET_CM_DOCUMENT_DETAILS_FAILED"
};
