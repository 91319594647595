import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../actions";
import { withStyles } from "@mui/styles";
import { dashBoardImages } from "../assets";
import Card from "@mui/material/Card";
import green from "@mui/material/colors/green";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { injectIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import pink from "@mui/material/colors/pink";
import Typography from "@mui/material/Typography";
import moment from "moment";
import MessageFormat from "messageformat";
import _ from "lodash";

class PlanSummary extends Component {
  constructor(props) {
    super(props);

    this.route = this.props.route;
    this.state = {
      premium: props.user.premiumPlans,
      dependentPlans: props.user.premiumPlans,
      planSelected: props.user.planSelected,
      policyStartYear: moment(this.props.user.policy_start_date).format("YYYY")
    };
  }

  viewPlanDetails = () => {
    this.props.employeePlan();
    // this.props.dependantPlanGHSB();
    // this.props.dependantPlanPGIH();
    // this.props.premiumCalculationDep(this.premiumCalculationDep(this.props.user.planSelectedDependent));
    // this.props.premiumCalculationEmp(this.premiumCalculationEmp(this.state.planSelected));

    this.props.confirmationType === "Register"
      ? this.props.history.push("/auth/dashboard/updatePlan")
      : this.props.history.push("/auth/viewPlan");
  };

  premiumCalculationDep = planSelectedDependent => {
    let tempPremium = 0;
    if (planSelectedDependent === "Employee") {
      return tempPremium;
    }
    this.props.user.premiumPlans.map((plan, keyPlan) => {
      if (plan.option === this.props.user.option) {
        plan.details.details.map(planType => {
          if (planType.header === planSelectedDependent) {
            tempPremium = planType.premiumGSMM + planType.premiumGHS;
          }
        });
      }
    });
    return tempPremium;
  };
  premiumCalculationEmp = planSelected => {
    let sumPremium = 0;
    let planSelectedArray = Array.from(planSelected);
    this.state.dependentPlans.map(premiumPlans => {
      planSelectedArray.map(selectedPlans => {
        if (selectedPlans[1].option === premiumPlans.option) {
          let tempPremium = premiumPlans.details
            .find(plan => plan.header === selectedPlans[0])
            .details.find(premium => premium.header === "Employee").premium;
          sumPremium = sumPremium + tempPremium;
        }
      });
    });
    return sumPremium;
  };

  componentDidMount() {
    this.setState({
      premium: this.props.user.premiumPlans,
      dependentPlans: this.props.user.premiumPlans
    });
  }

  render() {
    const { classes, intl } = this.props;
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.props.user;
    const mf = new MessageFormat("en");
    const header =
      this.props.confirmationType === "Register"
        ? mf.compile(labels["dashboard.register.confirm.header"])
        : mf.compile(labels["step5.header"]);
    const confirmationDescription =
      this.props.confirmationType === "Register"
        ? mf.compile(labels["dashboard.register.confirm.description"])
        : mf.compile(labels["step5.description"]);
    const select =
      this.props.confirmationType === "Register"
        ? mf.compile(labels["dashboard.register.confirm.select"])
        : mf.compile(labels["step5.select"]);
    const placeholderMapTemp = !_.isEmpty(this.props.user.placeholderMap)
      ? new Map(this.props.user.placeholderMap)
      : new Map();
    return (
      <div className={classes.container}>
        <Grid container spacing={"24px"} className={classes.contentContainer}>
          <Grid item xs={12}>
            <Typography
              className={classes.titleText}
              variant="headline"
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: header({ year: placeholderMapTemp.get("{year}") })
              }}
            >
              {/* {labels['step5.header']} */}
            </Typography>
            <Typography
              className={classes.contentText}
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: confirmationDescription({
                  year: placeholderMapTemp.get("{year}"),
                  enrolmentEnd: placeholderMapTemp.get("{enrolmentEnd}"),
                  supportEmail:
                    this.props.user.client_name.config.isPruAgencyEnrolment ===
                    "True"
                      ? this.props.user.client_name.config.supportBillingEmail
                      : placeholderMapTemp.get("{supportEmail}"),
                  policyStartDate: placeholderMapTemp.get("{policyStart}"),
                  policyEndDate: placeholderMapTemp.get("{policyEnd}"),
                  policyYear: moment(
                    placeholderMapTemp.get("{policyStart}")
                  ).format("YYYY")
                })
              }}
            />

            <Grid item xs={12} md={6} className={classes.boxText}>
              <Card
                className={classes.cardIconBox}
                onClick={this.viewPlanDetails}
                // onClick={() => {
                //   history.push("/auth/viewPlan");
                // }}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={dashBoardImages.viewPlan}
                      alt="View Plan logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography
                      className={classes.contentBoxText}
                      dangerouslySetInnerHTML={{
                        __html: select({
                          year: placeholderMapTemp.get("{year}")
                        })
                      }}
                    >
                      {/* View your plan */}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    height: "100%",
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },

  cardContainer: {
    padding: 20,
    //marginTop:50,
    backgroundColor: "#FFFFFF",
    height: "100%"
  },
  contentContainer: {
    padding: 20
    //marginTop:50
  },
  titleText: {
    margin: theme.spacing.unit,
    padding: 20,
    //fontWeight: 400,
    fontSize: "36px",
    lineHeight: "40px",
    color: "#000000"
  },
  contentText: {
    margin: theme.spacing.unit,
    padding: 20,
    //fontWeight: 400,
    fontSize: "24px",
    lineHeight: "40px",
    color: "#6B6A6D"
  },

  titleBoxText: {
    margin: theme.spacing.unit,
    fontSize: 16,
    color: "gray"
  },
  contentBoxText: {
    margin: theme.spacing.unit,
    fontSize: 20,
    lineHeight: "40px"
  },
  dependentText: {
    display: "flex",
    fontSize: 14,
    marginLeft: 30,
    marginTop: 20
  },
  titleLinkText: {
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 15
  },
  paperTileStyle: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: "200px",
    overflow: "hidden",
    height: "100% !important"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    height: "100%"
  },
  button: {
    margin: theme.spacing.unit
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  myDocumentLine: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    width: 40,
    height: 40,
    color: "#000"
  },
  pinkAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: pink[500]
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500]
  },
  divider80: {
    marginLeft: 30,
    width: "80%",
    align: "center"
  },

  normalCard: {
    height: 260
  },

  alignCardBottom: {
    display: "flex",
    flexDirection: "column"
  },

  flexibleCard: {
    height: 260,
    [theme.breakpoints.down("xs")]: {
      minHeight: 350
    }
  },

  cardIconBox: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },

  cardIconBoxContent: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px"
  },

  roundButton: {
    borderRadius: 20
  },

  expandAllCard: {
    flexGrow: 1
  },
  table: {
    minWidth: 200
  },
  media: {
    height: 200,
    position: "relative",
    cursor: "pointer"
  },
  imageSrc: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto"
  },
  socialMedia: {
    width: 60,
    height: 60,
    marginLeft: 20,
    marginTop: 5
  },
  menuItem: {
    textDecoration: "none"
  },
  info_ms: {
    padding: "90px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "65px 50px"
    }
  },
  card_ms: {
    minHeight: 285,
    [theme.breakpoints.down("sm")]: {
      minHeight: 200
    }
  },
  boxText: {
    padding: 20
  },
  description_ms: {
    fontSize: "14px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    }
  },
  title_ms: {
    fontSize: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  }
});

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    agentType: state.user.auth.pcode,
    history: prop.history
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PlanSummary)));
