import React from "react";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import FormHelperText from "@mui/material/FormHelperText";

export const renderTextField = props => {
  const {
    input,
    label,
    children,
    isSelect,
    meta: { error, warning, touched },
    ...custom
  } = props;

  return (
    <div>
      <TextField
        margin="normal"
        fullWidth
        select={isSelect || false}
        error={touched && error !== undefined}
        label={label}
        {...input}
        {...custom}
      >
        {children}
      </TextField>
      {touched &&
        ((error && (
          <FormHelperText
            error={true}
            className={classNames({ "m-b-10": error })}
          >
            {error}
          </FormHelperText>
        )) ||
          (warning && (
            <FormHelperText
              error={true}
              className={classNames({ "m-b-10": warning })}
            >
              {warning}
            </FormHelperText>
          )))}
    </div>
  );
};
