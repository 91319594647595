import React from "react";
import { isOnlyAndMainEmployee } from "../../../../utils/HealthDeclarationForm.utils";
import { questionnairesStyles } from "../questionnaire.styles";
import { Typography } from "@mui/material";

/**
  @typedef {{
    className?: string;
    user?: User;
    hdfUsers: User[]
  }} UserLabelProps

  @typedef {{
    person_type: "EMPLOYEE"|"SPOUSE";
    full_name: string
  }} User
 */

/**
 * @param {User} user
 */
const resolveUserType = user => {
  switch (user.person_type) {
    case "EMPLOYEE":
      return "(Self)";
    case "SPOUSE":
      return "(Spouse)";
    default:
      return "";
  }
};

/**
 * @param {UserLabelProps} props
 */
const UserLabel = ({ user, className, hdfUsers }) => {
  const { username } = questionnairesStyles();

  let name = "";
  let userType = "";

  if (!isOnlyAndMainEmployee(hdfUsers)) {
    if (user) {
      name = user?.full_name;
      userType = resolveUserType(user);
    }
  }

  return (
    <Typography className={[username, className].join(" ")} mx={0}>
      {name} {userType}
    </Typography>
  );
};

export default UserLabel;
