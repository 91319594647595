export class RecentHealthConditionClass {
  constructor(date, diagnosis, treatment, nameAddressOfDoctorConsulted) {
    this.date = date;
    this.diagnosis = diagnosis;
    this.treatment = treatment;
    this.nameAddressOfDoctorConsulted = nameAddressOfDoctorConsulted;
  }

  getRecentHealthConditionDetails = () => {
    return {
      date: this.date,
      diagnosis: this.diagnosis,
      treatment: this.treatment,
      nameAddressOfDoctorConsulted: this.nameAddressOfDoctorConsulted
    };
  };
}
