export const productKeys = {
  PLAN_1: "001",
  PLAN_2: "002",
  PLAN_3: "003"
};

const { PLAN_1, PLAN_2, PLAN_3 } = productKeys;

export const availableProducts = {
  // Agent Distribution Channel

  MANAGER: [PLAN_1, PLAN_3],

  EMPLOYEE: [PLAN_2, PLAN_3],

  // Partner Distribution Channel
  PD: [],

  //UOB Assisted (include UOB team 1)
  UOB_ASSISTED: [],
  UOB_TEAM_1: [],

  //UOB Direct (include UOB team 2)
  UOB_DIRECTED: [],
  UOB_TEAM_2: [],
  SCB_ASSISTED: []
};

// export default { productKeys, availableProducts };
