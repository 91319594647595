export const Questionnaires = [
  {
    id: 1,
    title: "Details of Employee & Dependant(s)",
    key: "personalDetails",
    subQuestions: [
      {
        id: "1-1",
        title: "",
        key: "personalDetails"
      }
    ]
  },
  {
    id: 2,
    title: "Extreme Sports Engagement",
    key: "extremeSportsEngagement",
    subQuestions: [
      {
        id: "2-1",
        title: "Do you engage in Private Flying (Pilot/Passenger)?",
        key: "privateFlying"
      },
      {
        id: "2-2",
        title: "Do you engage in Scuba Diving?",
        key: "scubaDiving"
      },
      {
        id: "2-3",
        title: "Do you engage in Mountaineering/Outdoor Rock Climbing?",
        key: "mountaineering"
      },
      {
        id: "2-4",
        title: "Do you engage in Parachuting/Sky Diving?",
        key: "skyDiving"
      },
      {
        id: "2-5",
        title: "Do you engage in Racing?",
        key: "racing"
      },
      {
        id: "2-6",
        title:
          "Do you engage in other extreme sport activities not mentioned above?",
        key: "other"
      }
    ]
  },
  {
    id: 3,
    title: "Drug / Alcohol Addiction History",
    key: "drugAlcoholAddictionHistory",
    subQuestions: [
      {
        id: "3-1",
        title:
          "Have you taken narcotics, any habit forming drugs or ever been treated for drug or alcohol addiction?",
        key: "drugAlcoholAddictionHistory"
      }
    ]
  },
  {
    id: 4,
    title: "Insurance Arrangement",
    key: "insuranceArrangement",
    subQuestions: [
      {
        id: "4-1",
        title:
          "Do you have any health or life insurance application that has been rejected, postponed or accepted at special rates or terms by any insurance company?",
        key: "insuranceArrangement"
      }
    ]
  },
  {
    id: 5,
    title: "Recent Health Condition",
    key: "recentHealthCondition",
    subQuestions: [
      {
        id: "5-1",
        title:
          "Have you in the last 3 months had any of the following Symptoms for more than one week continuously: fatigue, weight loss, diarrhoea, enlarged nodes or unusual skin lesions?",
        key: "recentHealthCondition"
      }
    ]
  },
  {
    id: 6,
    title: "Smoking Habits",
    key: "smoking",
    subQuestions: [
      {
        id: "6-1",
        title: "Do you smoke any cigarettes?",
        key: "smoking"
      }
    ]
  },
  {
    id: 7,
    title: "Alcohol Consumption",
    key: "alcohol",
    subQuestions: [
      {
        id: "7-1",
        title: "Do you consume alcohol?",
        key: "alcohol"
      }
    ]
  },
  {
    id: 8,
    title: "Sexually Transmitted Diseases History",
    key: "sexuallyTransmittedDiseases",
    subQuestions: [
      {
        id: "8-1",
        title:
          "Have you or your spouse, ever been told to have, received any medical advice, counseling or treatment in connection with sexually transmitted disease, AIDS,AIDS Related Complex or any other AIDS related conditions?",
        key: "sexuallyTransmittedDiseases"
      }
    ]
  },
  {
    id: 9,
    title: "Health Screening History",
    key: "healthScreeningHistory",
    subQuestions: [
      {
        id: "9-1",
        title:
          "In the past 5 years, have you attended to any tests such as X ray, ultrasound, CT scan, electro-cardiogram (ECG), biopsy, endoscopy, blood or urine test?",
        key: "healthScreeningHistory"
      }
    ]
  },
  {
    id: 10,
    title: "Family Medical History",
    key: "familyMedicalHistory",
    subQuestions: [
      {
        id: "10-1",
        title:
          "Have either of your natural parents or siblings died or suffered from cancer, heart disease, stroke, high blood pressure, diabetes, kidney disease, mental disorder, dementia, tuberculosis, Down’s syndrome or any hereditary disease?",
        key: "familyMedicalHistory"
      }
    ]
  },
  {
    id: 11,
    title: "Other Existing Medical Conditions",
    key: "otherMedicalConditions",
    subQuestions: [
      {
        id: "11-1",
        title:
          "Have you EVER had or been told you had or been treated for: \n a) asthma, bronchitis, persistent cough, tuberculosis or respiratory disorder?",
        key: "respiratory"
      },
      {
        id: "11-2",
        title:
          "b) epilepsy, fits, stroke, paralysis, weakness of limbs, prolonged headache/giddiness, unconsciousness, nervous breakdown, depression or any other nervous/mental disorders or any disease of the brain?",
        key: "nervousMental"
      },
      {
        id: "11-3",
        title:
          "c) gastritis, stomach or duodenal ulcer, blood in stools, fistula, piles or any other stomach or bowel disorders?",
        key: "bowel"
      },
      {
        id: "11-4",
        title:
          "d) blood, protein or sugar in urine, kidney stones, infection or any other disorders of the kidney, bladder or genital organs?",
        key: "kidney"
      },
      {
        id: "11-5",
        title:
          "e) anaemia, diabetes, thyroid disorders or any other endocrine disorder?",
        key: "endocrine"
      },
      {
        id: "11-6",
        title: "f) cancer, tumour, cyst or growth of any kind?",
        key: "cancerTumour"
      },
      {
        id: "11-7",
        title: "g) any form of eye, hearing or speech disorder or disease?",
        key: "eyeHearingSpeech"
      },
      {
        id: "11-8",
        title:
          "h) jaundice, Hepatitis B carrier or any other type of hepatitis, liver or gallbladder disorders?",
        key: "gallbladder"
      },
      {
        id: "11-9",
        title:
          "i) slipped disc, gout, arthritis, pain or deformity or disorders of the muscles, spine, limbs or joints or severe injury?",
        key: "severeInjury"
      },
      {
        id: "11-10",
        title:
          "j) raised cholesterol, high blood pressure, heart attack, heart murmur, irregular or fast heart rate, chest discomfort or pain, diseases or any other disorders of the heart, heart valves or blood vessels?",
        key: "heart"
      },
      {
        id: "11-11",
        title:
          "k) any other illness, disorder, injury, disability, operation or hospitalization not mention above?",
        key: "otherIllness"
      }
    ]
  },
  {
    id: 12,
    title: "For Female Insured Only",
    key: "femaleInsuredOnly",
    subQuestions: [
      {
        id: "12-1",
        title:
          "a) Have you ever had any abnormal pap smear test or been told by a doctor to have a repeat pap smear within the next 6 months?",
        key: "papSmear"
      },
      {
        id: "12-2",
        title:
          "b) Have you had an abnormal mammogram or been advised to have mammogram, ultrasound, biopsy, operation of the breasts, ultrasound of pelvis or attended to any other gynecological investigations?",
        key: "gynecological"
      },
      {
        id: "12-3",
        title:
          "c) Have you ever consulted a doctor for irregular, painful menstruation or other problem(s) involving the female organs?",
        key: "otherProblemsInvolvingFemaleOrgans"
      },
      {
        id: "12-4",
        title: "d) Have you ever suffered any complication of pregnancy?",
        key: "pregnancySuffering"
      },
      {
        id: "12-5",
        title: "e) Are you currently pregnant?",
        key: "pregnancyCondition"
      }
    ]
  }
];
