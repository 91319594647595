import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";

import PlanDetails from "../../../components/PlanDetails";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MessageFormat from "messageformat";
import _ from "lodash";

class ViewPlan extends Component {
  render() {
    const { classes, history } = this.props;
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.props.user;
    const mf = new MessageFormat("en");

    const notes = mf.compile(labels["view.note"]);

    const placeholderMapTemp = !_.isEmpty(this.props.user.placeholderMap)
      ? new Map(this.props.user.placeholderMap)
      : new Map();

    return (
      !_.isEmpty(this.props.user.placeholderMap) && (
        <div className={classes.rootInfo}>
          <div className={classes.contentInfo}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <PlanDetails history={history} />
              </Grid>
              <Grid item sm={12} md={12}></Grid>
            </Grid>
          </div>
          <div className={classes.info}>
            <Typography component={"span"} className={classes.infoText}>
              {/* <img
            
                        className={classes.socialMedia}
                        src={assets.menuIcons.viewDoc}
                        alt="view docs"
                        
                      /> */}
              <span
                style={{ lineHeight: "20px" }}
                dangerouslySetInnerHTML={{
                  __html: notes({
                    supportEmail: placeholderMapTemp.get("{supportEmail}")
                  })
                }}
              ></span>
            </Typography>
          </div>
        </div>
      )
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  contentInfo: {
    paddingTop: 10,
    paddingBottom: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  info: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px",
    padding: "0px 25px 0px 20px",
    fontSize: "16px",
    lineHeight: "40px"
  },
  infoText: {
    fontSize: "16px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 25px 0px"
  },
  socialMedia: {
    width: 25,
    height: 25
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ViewPlan));
