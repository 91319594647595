export class AlcoholConsumptionClass {
  constructor(alcoholType, quantity, frequency) {
    this.alcoholType = alcoholType;
    this.quantity = quantity;
    this.frequency = frequency;
  }

  getAlcoholConsumptionDetails = () => {
    return {
      alcoholType: this.alcoholType,
      quantity: this.quantity,
      frequency: this.frequency
    };
  };
}
