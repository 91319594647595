import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { PregnancyConditionClass } from "../../../../model/PregnancyCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

export const PregnancyConditionQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [months, setMonths] = useState("");
  const [monthsError, setMonthsError] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.femaleInsuredOnly?.pregnancyCondition?.months &&
      setMonths(user.femaleInsuredOnly.pregnancyCondition.months);

    let validateSuccess = validateFields();
    if (user?.femaleInsuredOnly?.pregnancyCondition || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [months];

  const validateFields = () => {
    let validateSuccess = true;

    setMonthsError(!months);

    if (!months) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const pregnancyCondition = new PregnancyConditionClass(months);
      const femaleSpecificObject = {};
      femaleSpecificObject[
        subKey
      ] = pregnancyCondition.getPregnancyConditionDetails();

      const updatedUser = {
        ...user,
        [key]: { ...user[key], ...femaleSpecificObject }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleKeyPress = event => {
    const { key } = event;

    // Disable input of "e" and "-"
    if (key === "e" || key === "-") {
      event.preventDefault();
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Months</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                type="number"
                className={textFieldRed}
                required
                error={monthsError}
                helperText={monthsError && "This field is mandatory."}
                onChange={event => {
                  setMonths(event.target.value);
                }}
                onKeyPress={handleKeyPress}
                value={months}
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
