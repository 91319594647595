import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

class LoadingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    const {
      classes,
      name,
      title = this.props.intl.formatMessage({
        id: "popup.loading.default.title"
      }),
      description = this.props.intl.formatMessage({
        id: "popup.loading.default.description"
      }),
      subDescription = this.props.intl.formatMessage({
        id: "popup.loading.default.subDescription"
      })
    } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          classes={{ paper: classes.paper }}
          maxWidth="xs"
          aria-labelledby={`dialog-${name}-loading`}
        >
          <DialogTitle id={`dialog-${name}-loading`}>{title}</DialogTitle>
          <DialogContent>
            <CircularProgress
              color="primary"
              className={classes.circularProgress}
            />
            <Typography variant="title" gutterBottom>
              {description}
            </Typography>
            <Typography gutterBottom>{subDescription}</Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  paper: {
    textAlign: "center",
    minWidth: 350,
    padding: 20
  },
  circularProgress: {
    paddingBottom: 20,
    paddingTop: 20
  }
});
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(LoadingPopup)));
