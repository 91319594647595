import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { SmokingHabitsClass } from "../../../../model/SmokingHabits.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

export const SmokingHabitsQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [yearsOfSmoking, setYearsOfSmoking] = useState("");
  const [noOfCigarettesPerDay, setNoOfCigarettesPerDay] = useState("");

  const [yearsOfSmokingError, setYearsOfSmokingError] = useState(false);
  const [noOfCigarettesPerDayError, setNoOfCigarettesPerDayError] = useState(
    false
  );

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.smoking?.yearsOfSmoking &&
      setYearsOfSmoking(user.smoking.yearsOfSmoking);
    user?.smoking?.noOfCigarettesPerDay &&
      setNoOfCigarettesPerDay(user.smoking.noOfCigarettesPerDay);

    let validateSuccess = validateFields();

    if (user?.smoking || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [yearsOfSmoking, noOfCigarettesPerDay];

  const validateFields = () => {
    let validateSuccess = true;

    setYearsOfSmokingError(!yearsOfSmoking);
    setNoOfCigarettesPerDayError(!noOfCigarettesPerDay);

    if (!yearsOfSmoking || !noOfCigarettesPerDay) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const smokingHabits = new SmokingHabitsClass(
        yearsOfSmoking,
        noOfCigarettesPerDay
      );
      const smokingHabitObject = {};
      smokingHabitObject[subKey] = smokingHabits.getSmokingHabitsDetails();

      const updatedUser = {
        ...user,
        ...smokingHabitObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleKeyPress = event => {
    const { key } = event;

    // Disable input of "e" and "-"
    if (key === "e" || key === "-") {
      event.preventDefault();
    }

    // Ensure minimum value is greater than 0
    const currentValue = event.target.value;
    const newValue =
      event.key === "Backspace"
        ? currentValue.slice(0, -1)
        : currentValue + event.key;
    const numericValue = Number(newValue);

    if (numericValue <= 0) {
      event.preventDefault();
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">No. of years smoked</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                type={"number"}
                className={textFieldRed}
                onChange={event => {
                  setYearsOfSmoking(event.target.value);
                }}
                onKeyPress={handleKeyPress}
                value={yearsOfSmoking}
                inputProps={{ min: 1 }}
              />
              {yearsOfSmokingError && (
                <FormHelperText style={{ color: "red" }}>
                  This field is mandatory.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">No. of sticks smoked (per day)</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                type={"number"}
                className={textFieldRed}
                onChange={event => {
                  setNoOfCigarettesPerDay(event.target.value);
                }}
                onKeyPress={handleKeyPress}
                value={noOfCigarettesPerDay}
                inputProps={{ min: 1 }}
              />
              {noOfCigarettesPerDayError && (
                <FormHelperText style={{ color: "red" }}>
                  This field is mandatory.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
