import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userActions } from "../actions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Grid from "@mui/material/Grid";
import MessageFormat from "messageformat";
import _ from "lodash";

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    const { client_name } = props.user.client_name;

    const isRiEnrolment = client_name.client_name === "RI";
    const benefitsArray = isRiEnrolment
      ? Array.from(props.user.planSelectedDependent || [])
      : [];

    const sortOrder = ["GHS", "GOSP", "GPRU", "GADD", "GCCE"];

    const sortedBenefits = benefitsArray.sort((a, b) => {
      const indexA = sortOrder.findIndex(keyword => a[0].includes(keyword));
      const indexB = sortOrder.findIndex(keyword => b[0].includes(keyword));
      return indexA - indexB;
    });

    this.state = {
      plans: props.user.currentPlans,
      dependentPlans: props.user.premiumPlans,
      planSelected: props.user.planSelected,
      planSelectedDependent: isRiEnrolment
        ? sortedBenefits
        : props.user.planSelectedDependent,
      totalPremium: 0,
      rateMatrics: props.user.rateMatrics,
      isPruAgencyMedEnrolment:
        props.user.client_name.config.isPruAgencyMedEnrolment === "True"
          ? true
          : false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        planSelectedDependent: this.props.user.planSelectedDependent,
        plans: this.props.user.currentPlans,
        dependentPlans: this.props.user.premiumPlans,
        planSelected: this.props.user.planSelected,
        rateMatrics: this.props.user.rateMatrics,
        isPruAgencyMedEnrolment:
          this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
            ? true
            : false
      });
    }
  }

  premiumInfo = planSelected => {
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let tempPremium = 0;
    let coverageName = planSelected && planSelected[0];

    var a = moment(this.props.user.dob, "D/M/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    this.state.dependentPlans.map(plan => {
      if (planSelected[1].option === plan.option) {
        plan.details.map(planType => {
          if (planType.header === planSelected[0]) {
            planType.details.map(dependent => {
              if (Array.isArray(planType.multiplier)) {
                if (dependent.header === "Employee") {
                  for (
                    let count = 0;
                    count < planType.multiplier.length;
                    count++
                  ) {
                    let tempPremium1 =
                      dependent.premium[count] * planType.multiplier[count] -
                      dependent.subsidy;
                    tempPremium = tempPremium + tempPremium1;
                  }
                }
              } else {
                if (dependent.header === "Employee") {
                  tempPremium =
                    diffYears >= 71 && isPruAgencyMedEnrolment
                      ? dependent.premium71 * planType.multiplier -
                        dependent.subsidy
                      : dependent.premium * planType.multiplier -
                        dependent.subsidy;
                }
              }
            });
          }
        });
      }
    });
    let categorySub = this.getCategorySubsidy(
      this.getCoverageIdByName(coverageName)
    );
    return tempPremium - categorySub;
  };
  // employeeEnrolmentCheck =(coverage)=>{
  //   let categoryRules = this.props.user.category === null ? this.props.user.client_name.config.categoryRules['All Employees'] : this.props.user.client_name.config.categoryRules[this.props.user.category] ;
  //   let coverageRules = categoryRules && categoryRules[coverage];
  //   return coverageRules;
  // }
  employeeEnrolmentCheck = coverage => {
    let coverageId = this.props.user.currentPlans.find(
      currentPlan => currentPlan.planType === coverage
    ).coverageId;
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules = categoryRules && categoryRules[coverageId];
    return coverageRules;
  };
  hidePremiumsCheck = coverageId => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules =
      isPruAgencyEnrolment === true || this.state.isPruAgencyMedEnrolment
        ? undefined
        : categoryRules[coverageId].hidePremiums;
    return coverageRules;
  };

  premiumInfoVertical = (user, planSelected, insuree) => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let planSelectedLoc = planSelected;
    let rateMatrics = this.state.rateMatrics;
    let tempPremium = 0;
    if (insuree === "Employee" && planSelectedLoc[1] === "Opt Out")
      return tempPremium;
    if (planSelectedLoc === "Opt Out") return tempPremium;
    let diffDays;
    var a = moment(user.dob, "D/M/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    diffDays = b.diff(a, "years");
    let occupationClass = this.props.occupationToClassValue(user.occupation);
    let gender = user.gender;
    rateMatrics.map(rateMatric => {
      if (isPruAgencyEnrolment) {
        rateMatric.details.map(detail => {
          if (
            insuree === "Employee" &&
            (detail.coverageId === planSelectedLoc[1].coverageId ||
              detail.coverageId === planSelected[1].code)
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = gender === "M" ? rule.maleRate : rule.femaleRate;
                let SA = "0";
                let healthLoading = "0";
                let currentPlan = user.coverage_details.current;
                if (planSelectedLoc[0].header === "Existing") {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc[1].coverageId ||
                      currPlan.code === planSelectedLoc[1].code
                    ) {
                      SA = currPlan.sum_assured;
                      healthLoading = currPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                } else if (planSelectedLoc[0].header === "VECI") {
                  currentPlan.map(plan => {
                    if (plan.code === "VCCA") {
                      SA = plan.sum_assured;
                    }
                  });
                } else {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc[1].coverageId ||
                      currPlan.code === planSelectedLoc[1].code
                    ) {
                      healthLoading = currPlan.extra_premium.replace(/,/g, "");
                      SA = currPlan.sum_assured;
                    }
                  });
                }

                if (planSelectedLoc.header === "VECI") {
                  tempPremium =
                    parseFloat(healthLoading) +
                    ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      0.3 *
                      parseFloat(rate).toFixed(2)) /
                      1000 /
                      365) *
                      212;
                } else {
                  tempPremium =
                    parseFloat(healthLoading) +
                    ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      parseFloat(rate).toFixed(2)) /
                      1000 /
                      365) *
                      212;
                }
              }
            });
          } else if (
            insuree !== "Employee" &&
            detail.coverageId === planSelectedLoc.coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = gender === "M" ? rule.maleRate : rule.femaleRate;
                let SA = "0";
                let healthLoading = "0";
                let currentPlan = user.coverage_details.current;
                if (planSelectedLoc.header === "Existing") {
                  currentPlan.map(currPlan => {
                    if (currPlan.code === planSelectedLoc.coverageId) {
                      SA = currPlan.sum_assured;
                      healthLoading = currPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                } else if (planSelectedLoc.header === "VECI") {
                  currentPlan.map(prevPlan => {
                    if (prevPlan.code === "VCCA") {
                      SA = prevPlan.sum_assured;
                    }
                  });
                } else {
                  currentPlan.map(currPlan => {
                    if (currPlan.code === planSelectedLoc.coverageId) {
                      healthLoading = currPlan.extra_premium.replace(/,/g, "");
                    }
                  });
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                }

                if (planSelectedLoc.header === "VECI") {
                  tempPremium =
                    parseFloat(healthLoading) +
                    ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      0.3 *
                      parseFloat(rate).toFixed(2)) /
                      1000 /
                      365) *
                      212;
                } else {
                  tempPremium =
                    parseFloat(healthLoading) +
                    ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                      parseFloat(rate).toFixed(2)) /
                      1000 /
                      365) *
                      212;
                }
              }
            });
          }
        });
      } else if (isPruAgencyMedEnrolment) {
        rateMatric.details.map(detail => {
          if (
            insuree === "Employee" &&
            (detail.coverageId === planSelectedLoc[1].coverageId ||
              detail.coverageId === planSelectedLoc[1].code)
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = rule.rate;
                let SA = "0";
                let currentPlan = user.coverage_details.current;

                if (planSelectedLoc[0].header === "Existing") {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc[1].coverageId ||
                      currPlan.code === planSelectedLoc[1].code
                    ) {
                      SA = currPlan.sum_assured;
                    }
                  });
                } else {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc[1].coverageId ||
                      currPlan.code === planSelectedLoc[1].code
                    ) {
                      SA = currPlan.sum_assured;
                    }
                  });
                }

                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                  parseFloat(rate).toFixed(2);
              }
            });
          } else if (
            insuree !== "Employee" &&
            detail.coverageId === planSelectedLoc.coverageId
          ) {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = rule.rate;
                let SA = "0";
                let currentPlan = user.coverage_details.current;

                if (planSelectedLoc.header === "Existing") {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc.coverageId ||
                      currPlan.code === planSelectedLoc.code
                    ) {
                      SA = currPlan.sum_assured;
                    }
                  });
                } else {
                  currentPlan.map(currPlan => {
                    if (
                      currPlan.code === planSelectedLoc.coverageId ||
                      currPlan.code === planSelectedLoc.code
                    ) {
                      SA = currPlan.sum_assured;
                    }
                  });
                }

                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                  parseFloat(rate).toFixed(2);
              }
            });
          }
        });
      } else {
        if (rateMatric.value == occupationClass.value) {
          rateMatric.details.map(detail => {
            detail.rules.map(rule => {
              if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
                let rate = 0;
                let SA;
                if (insuree === "Employee") {
                  rate = rule.employeeRate;
                  SA =
                    planSelectedLoc[1].details[0].overview.details[0]
                      .details[0];
                } else if (insuree === "SPOUSE") {
                  rate = rule.spouseRate;
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                } else if (insuree === "CHILD") {
                  rate = rule.childRate;
                  SA =
                    planSelectedLoc.details[0].overview.details[0].details[0];
                }
                tempPremium =
                  (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                    parseFloat(rate).toFixed(2)) /
                  1000;
              }
            });
          });
        }
      }
    });
    return tempPremium;
  };

  premiumInfoDependent = (coverage, plan, dependent) => {
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let dob = this.props.user.dob;

    var a = moment(dob, "DD/MM/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    let multiplier = 0;
    let subsidy = 0;
    if (
      dependent === undefined ||
      dependent === "Dependants" ||
      plan === "Opt Out"
    )
      return 0.0;
    if (dependent && dependent.id) {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage[0]).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage[0] &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === "Individual");
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let premiumDetailsSub = this.state.dependentPlans
          .find(planObj => planObj.option === plan.option)
          .details.find(
            detail =>
              detail.header === coverage[0] && (multiplier = detail.multiplier)
          )
          .details.find(detail1 => detail1.header === "Individual");
        subsidy = premiumDetailsSub.subsidy;
        return premiumDetailsSub.premium * multiplier - subsidy;
      }
    } else {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage[0]).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage[0] &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === dependent);
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let planDetails = this.state.dependentPlans.find(
          planObj => planObj.option === plan.option
        );
        let detailMultiplier = planDetails.details.find(
          detail => detail.header === coverage[0]
        );
        multiplier = detailMultiplier.multiplier;
        let details = planDetails.details.find(
          detail => detail.header === coverage[0]
        );
        let premiumDetailsSub1 = details.details.find(
          detail1 => detail1.header === dependent
        );
        subsidy = premiumDetailsSub1.subsidy;
        return isPruAgencyMedEnrolment && diffYears >= 71
          ? premiumDetailsSub1.premium71 * multiplier - subsidy
          : premiumDetailsSub1.premium * multiplier - subsidy;
      }
    }
  };

  getCoverageIdByName = coverageName => {
    return (
      this.state.plans.find(plan => plan.planType === coverageName) &&
      this.state.plans.find(plan => plan.planType === coverageName).coverageId
    );
  };

  getCategorySubsidy = coverage => {
    let categotyPlans =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let categorySubsidyArr =
      categotyPlans &&
      categotyPlans[coverage] &&
      categotyPlans[coverage].subsidy;
    let categorySubsidy = 0;
    categorySubsidyArr &&
      categorySubsidyArr.map(subArray => {
        categorySubsidy = categorySubsidy + parseInt(subArray); //tempSubsidy;
      });
    return categorySubsidy;
  };

  premiumCalculation = planSelected => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let sumPremium = 0;
    let coverageName;
    let planSelectedArray = Array.from(planSelected);
    planSelectedArray.map(selectedPlans => {
      let isVerticalPlan = this.state.plans.find(
        plan => plan.planType === selectedPlans[0]
      );
      if (isVerticalPlan && isVerticalPlan.isVertical === "true") {
        var a = moment(this.props.user.dob, "D/M/YYYY");
        var b = moment();
        let diffDays = b.diff(a, "years");
        let occupationClass = this.props.occupationToClassValue(
          this.props.user.occupation
        );
        if (isPruAgencyEnrolment === true) {
          let user = this.props.user;
          let currentPlan = user.coverage_details.current;
          let gender = this.props.user.gender;
          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (
                  detail.coverageId === selectedPlans[1].coverageId ||
                  detail.coverageId === selectedPlans[1].code
                ) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate =
                        gender === "M" ? rule.maleRate : rule.femaleRate;
                      let SA = "0";
                      let healthLoading = "0";

                      if (selectedPlans[0].header === "Existing") {
                        currentPlan.map(currPlan => {
                          if (
                            selectedPlans[1].coverageId === currPlan.code ||
                            selectedPlans[1].code === currPlan.code
                          ) {
                            SA = currPlan.sum_assured;
                            healthLoading = currPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                parseFloat(rate).toFixed(2)) /
                                1000 /
                                365) *
                                212;
                          }
                        });
                      } else if (selectedPlans[0].header === "VECI") {
                        currentPlan.map(prevPlan => {
                          if (prevPlan.code === "VCCA") {
                            SA = prevPlan.sum_assured;
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                0.3 *
                                parseFloat(rate).toFixed(2)) /
                                1000 /
                                365) *
                                212;
                          }
                        });
                      } else {
                        currentPlan.map(currPlan => {
                          if (
                            selectedPlans[1].coverageId === currPlan.code ||
                            selectedPlans[1].code === currPlan.code
                          ) {
                            healthLoading = currPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                            SA = currPlan.sum_assured;
                          }
                        });
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            parseFloat(rate).toFixed(2)) /
                            1000 /
                            365) *
                            212;
                      }
                    }
                  });
                }
              });
            }
          });
        } else if (isPruAgencyMedEnrolment) {
          let user = this.props.user;
          let currentPlan = user.coverage_details.current;

          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (
                  detail.coverageId === selectedPlans[1].coverageId ||
                  detail.coverageId === selectedPlans[1].code
                ) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate = rule.rate;
                      let SA = "0";

                      if (selectedPlans[0].header === "Existing") {
                        currentPlan.map(currPlan => {
                          if (
                            selectedPlans[1].coverageId === currPlan.code ||
                            selectedPlans[1].code === currPlan.code
                          ) {
                            SA = currPlan.sum_assured;
                            sumPremium =
                              sumPremium +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                                1000) *
                                parseFloat(rate).toFixed(2);
                          }
                        });
                      } else {
                        currentPlan.map(currPlan => {
                          if (
                            selectedPlans[1].coverageId === currPlan.code ||
                            selectedPlans[1].code === currPlan.code
                          ) {
                            SA = currPlan.sum_assured;
                          }
                        });

                        sumPremium =
                          sumPremium +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                            parseFloat(rate).toFixed(2);
                      }
                    }
                  });
                }
              });
            }
          });
        } else {
          this.state.rateMatrics.map(rateMatric => {
            if (rateMatric.value == occupationClass.value) {
              rateMatric.details.map(detail => {
                detail.rules.map(rule => {
                  if (
                    rule.ageBand[0] <= diffDays &&
                    diffDays <= rule.ageBand[1]
                  ) {
                    let rate = rule.employeeRate;
                    let SA =
                      selectedPlans[1].details[0].overview.details[0]
                        .details[0];
                    sumPremium =
                      sumPremium +
                      (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                        parseFloat(rate).toFixed(2)) /
                        1000;
                  }
                });
              });
            }
          });
        }
      } else {
        let isPruAgencyMedEnrolment =
          this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
            ? true
            : false;
        var a = moment(this.props.user.dob, "DD/MM/YYYY");
        var b = moment();
        if (
          this.props.user.client_name.config.dobValidationByPolicyStartDate ===
          "true"
        ) {
          b = moment(
            this.props.user.client_name.policy_start_date,
            "YYYY-MM-DD"
          );
        }
        let diffYears = b.diff(a, "years");

        this.state.dependentPlans.map(premiumPlans => {
          coverageName = selectedPlans[0];
          if (selectedPlans[1].option === premiumPlans.option) {
            let premiumDetails = premiumPlans.details.find(
              plan => plan.header === selectedPlans[0]
            );
            let premiumDetailsSub =
              premiumDetails &&
              premiumDetails.details.find(obj => obj.header === "Employee");
            let subsidy = premiumDetailsSub && premiumDetailsSub.subsidy;
            let categorySubTemp = this.getCategorySubsidy(
              this.getCoverageIdByName(coverageName)
            );
            if (Array.isArray(premiumDetails.multiplier)) {
              for (
                let count = 0;
                count < premiumDetails.multiplier.length;
                count++
              ) {
                let tempPremium =
                  premiumDetailsSub &&
                  premiumDetailsSub.premium[count] *
                    premiumDetails.multiplier[count] -
                    premiumDetailsSub.subsidy;
                sumPremium = sumPremium + tempPremium;
              }
            } else {
              let tempPremium =
                isPruAgencyMedEnrolment && diffYears >= 71 && premiumDetailsSub
                  ? premiumDetailsSub.premium71 * premiumDetails.multiplier -
                    premiumDetailsSub.subsidy
                  : premiumDetailsSub.premium * premiumDetails.multiplier -
                    premiumDetailsSub.subsidy;
              sumPremium = sumPremium + tempPremium;
            }
            sumPremium = sumPremium - categorySubTemp;
          }
        });
      }
    });
    this.state.totalPremium = this.state.totalPremium + sumPremium;
    return sumPremium;
  };

  filterClientPremium = premiumValueEmp => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    if (isPruAgencyEnrolment) {
      let tempPremiumSevenMonthRequest = parseFloat(premiumValueEmp);
      let sevenMonthChangeResult = (
        (tempPremiumSevenMonthRequest / 365) *
        212
      ).toFixed(2);

      return sevenMonthChangeResult.toString();
    }
    return premiumValueEmp;
  };

  premiumCoverageCalculation = (planSelectedE, planSelectedD) => {
    let planSelectedTemp = planSelectedE[0];
    let isVerticalPlan =
      planSelectedTemp &&
      this.state.plans.find(plan => plan.planType === planSelectedTemp[0]);
    let premiumInfoEmp =
      isVerticalPlan &&
      isVerticalPlan.isVertical &&
      isVerticalPlan.isVertical === "true"
        ? this.premiumInfoVertical(
            this.props.user,
            planSelectedE[0],
            "Employee"
          )
        : this.premiumInfo(planSelectedE[0]);
    let premiumInfoDep = 0;
    planSelectedD[1] &&
      planSelectedD[1].map(plan => {
        premiumInfoDep =
          premiumInfoDep +
          (isVerticalPlan.isVertical === "true"
            ? this.premiumInfoVertical(
                plan.dependentType,
                plan.planDep,
                plan.dependentType.dependent
              )
            : this.premiumInfoDependent(
                planSelectedD,
                plan.planDep,
                plan.dependentType.full_name ? "Individual" : plan.dependentType
              ));
      });

    return (
      parseFloat(parseFloat(premiumInfoEmp).toFixed(2)) +
      parseFloat(parseFloat(premiumInfoDep).toFixed(2))
    );
  };

  premiumCalculationDependent = planSelectedDependent => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let key = planSelectedDependent[0];
    let sumPremium = 0;
    let planSelectedDependentArray = Array.from(planSelectedDependent);
    let coverage = planSelectedDependentArray[0];
    let planSelected = planSelectedDependentArray[1];
    let currentPlanDep =
      this.props.user.dependencies.length > 0
        ? this.props.user.dependencies[0].coverage_details.current
        : [];
    let premiumEmp = 0;
    planSelected &&
      planSelected.map(selectedPlans => {
        if (selectedPlans.planDep === "Opt Out") return sumPremium;
        let isVerticalPlan = this.state.plans.find(
          plan => plan.planType === key
        );
        if (isVerticalPlan.isVertical === "true") {
          var a = moment(
            selectedPlans && selectedPlans.dependentType.dob,
            "D/M/YYYY"
          );
          var b = moment();
          let diffDays = b.diff(a, "years");
          let occupationClass = this.props.occupationToClassValue(
            selectedPlans && selectedPlans.dependentType.occupation
          );
          if (isPruAgencyEnrolment === true) {
            let gender = this.props.user.dependencies[0].gender;

            this.state.rateMatrics.map(rateMatric => {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans.planDep.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate =
                        gender === "M" ? rule.maleRate : rule.femaleRate;
                      let SA = "0";
                      let healthLoading = "0";

                      if (selectedPlans.planDep.header === "Existing") {
                        currentPlanDep.map(currPlan => {
                          if (
                            selectedPlans.planDep.coverageId === currPlan.code
                          ) {
                            SA = currPlan.sum_assured;
                            healthLoading = currPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                            sumPremium =
                              sumPremium +
                              parseFloat(healthLoading) +
                              ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                                parseFloat(rate).toFixed(2)) /
                                1000 /
                                365) *
                                212;
                          }
                        });
                      } else if (selectedPlans.planDep.header === "VECI") {
                        currentPlanDep.map(prevPlan => {
                          if (prevPlan.code === "VCCA") {
                            SA = prevPlan.sum_assured;
                          }
                        });
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            0.3 *
                            parseFloat(rate).toFixed(2)) /
                            1000 /
                            365) *
                            212;
                      } else {
                        currentPlanDep.map(currPlan => {
                          if (
                            selectedPlans.planDep.coverageId === currPlan.code
                          ) {
                            healthLoading = currPlan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                          }
                        });
                        SA =
                          selectedPlans.planDep.details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          parseFloat(healthLoading) +
                          ((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            parseFloat(rate).toFixed(2)) /
                            1000 /
                            365) *
                            212;
                      }
                    }
                  });
                }
              });
            });
          } else if (isPruAgencyMedEnrolment) {
            this.state.rateMatrics.map(rateMatric => {
              rateMatric.details.map(detail => {
                if (detail.coverageId === selectedPlans.planDep.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate = rule.rate;
                      let SA = "0";

                      if (selectedPlans.planDep.header === "Existing") {
                        currentPlanDep.map(currPlan => {
                          if (
                            selectedPlans.planDep.coverageId === currPlan.code
                          ) {
                            SA = currPlan.sum_assured;

                            sumPremium =
                              sumPremium +
                              (parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                                1000) *
                                parseFloat(rate).toFixed(2);
                          }
                        });
                      } else {
                        SA =
                          selectedPlans.planDep.details[0].overview.details[0]
                            .details[0];
                        sumPremium =
                          sumPremium +
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                            parseFloat(rate).toFixed(2);
                      }
                    }
                  });
                }
              });
            });
          } else {
            this.state.rateMatrics.map(rateMatric => {
              if (rateMatric.value == occupationClass.value) {
                rateMatric.details.map(detail => {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let rate;
                      if (selectedPlans.dependentType.dependent === "CHILD")
                        rate = rule.childRate;
                      if (selectedPlans.dependentType.dependent === "SPOUSE")
                        rate = rule.spouseRate;
                      let SA =
                        selectedPlans.planDep.details[0].overview.details[0]
                          .details[0];
                      sumPremium =
                        sumPremium +
                        (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                          parseFloat(rate).toFixed(2)) /
                          1000;
                    }
                  });
                });
              }
            });
          }
        } else {
          this.state.dependentPlans.map(premiumPlans => {
            let dob = this.props.user.dob;

            var a = moment(dob, "DD/MM/YYYY");
            var b = moment();
            if (
              this.props.user.client_name.config
                .dobValidationByPolicyStartDate === "true"
            ) {
              b = moment(
                this.props.user.client_name.policy_start_date,
                "YYYY-MM-DD"
              );
            }
            let diffYears = b.diff(a, "years");

            if (
              selectedPlans.planDep.option &&
              selectedPlans.planDep.option === premiumPlans.option
            ) {
              let premiumDetails = premiumPlans.details.find(
                plan => plan.header === coverage
              );
              let premiumDetailsSub =
                premiumDetails &&
                premiumDetails.details.find(
                  premium =>
                    premium.header ===
                    (selectedPlans.dependentType.full_name
                      ? "Individual"
                      : selectedPlans.dependentType)
                );
              let subsidy = premiumDetailsSub.subsidy;
              if (Array.isArray(premiumDetails.multiplier)) {
                for (
                  let count = 0;
                  count < premiumDetails.multiplier.length;
                  count++
                ) {
                  let tempPremium =
                    premiumDetailsSub.premium[count] *
                      premiumDetails.multiplier[count] -
                    premiumDetailsSub.subsidy;
                  sumPremium = sumPremium + tempPremium;
                }
              } else {
                let multiplier = 0;
                let tempPremiumSub = premiumPlans.details
                  .find(
                    plan =>
                      plan.header === coverage && (multiplier = plan.multiplier)
                  )
                  .details.find(
                    premium =>
                      premium.header ===
                      (selectedPlans.dependentType.full_name
                        ? "Individual"
                        : selectedPlans.dependentType)
                  );
                let subsidy = tempPremiumSub.subsidy;
                let tempPremium =
                  isPruAgencyMedEnrolment && diffYears >= 71
                    ? tempPremiumSub.premium71 * multiplier - subsidy
                    : tempPremiumSub.premium * multiplier - subsidy;
                sumPremium = sumPremium + tempPremium;
              }
            } else if (selectedPlans.planDep === "Opt Out") {
              sumPremium = sumPremium + 0;
            }
          });
        }
      });
    this.state.totalPremium = this.state.totalPremium + sumPremium + premiumEmp;
    return sumPremium + premiumEmp;
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  premiumCalculationTotal = () => {
    let employeePremium = this.premiumCalculation(this.state.planSelected);
    let dependentPremium = 0;
    let sumPremium = 0;
    Array.from(this.state.planSelectedDependent).map(planSelectedDependent1 => {
      dependentPremium =
        dependentPremium +
        this.premiumCalculationDependent(planSelectedDependent1);
    });

    sumPremium =
      parseFloat(parseFloat(employeePremium).toFixed(2)) +
      parseFloat(parseFloat(dependentPremium).toFixed(2));
    return sumPremium;
  };

  renderPlanName = code => {
    if (code == "GHS1" || code == "GMM1") {
      return "Plan 1";
    } else if (code == "GHS2" || code == "GMM2") {
      return "Plan 2";
    } else if (code == "GHS3" || code == "GMM3") {
      return "Plan 3";
    } else if (code == "GHS4" || code == "GMM4") {
      return "Plan 4";
    } else {
      return code.replace("00", "");
    }
  };

  render() {
    const { classes, user } = this.props;
    const { content, placeholderMap, dependencies, depRule } = this.props.user;
    let countOptOut = 0;
    const { labels } = this.props.user.content;
    const mf = new MessageFormat("en");
    const header = mf.compile(labels["view.header"]);
    const notes = mf.compile(labels["view.note"]);
    const placeholderMapTemp = !_.isEmpty(this.props.user.placeholderMap)
      ? new Map(this.props.user.placeholderMap)
      : new Map();

    const { plans } = this.state;

    return (
      <div>
        <div style={{ padding: "0px 20px" }}>
          <div className={classes.headerInfo}>
            <Typography
              component={"span"}
              className={classes.headerText}
              dangerouslySetInnerHTML={{
                __html: header({ year: placeholderMapTemp.get("{year}") })
              }}
            />
          </div>
        </div>

        {
          <div className={classes.headerInfo} style={{ paddingBottom: "0px" }}>
            {Array.from(this.state.planSelectedDependent).map(
              (planCovered, kepPlanCovered) => {
                let planCoveredTemp = planCovered[1];
                let isGADDPlan = false;

                if (
                  this.state.isPruAgencyMedEnrolment &&
                  planCovered[0] ===
                    "Group Accidental Death & Dismemberment (GADD) Benefits"
                ) {
                  isGADDPlan = true;
                }
                return (
                  <div
                    style={{ padding: "0px 20px 40px 20px" }}
                    key={kepPlanCovered}
                  >
                    <div style={{ background: "#F2F2F2", padding: "10px" }}>
                      <Typography
                        component={"span"}
                        className={classes.headerText}
                        style={{ color: "black" }}
                      >
                        {planCovered[0]}
                      </Typography>
                    </div>
                    {/* Header */}
                    <Grid
                      container
                      spacing={"8px"}
                      key={kepPlanCovered}
                      style={{ padding: "0px 10px" }}
                    >
                      <Grid item xs sm md>
                        <div style={{ padding: "10px 0px" }}>
                          <Typography
                            component={"span"}
                            className={classes.planText}
                            style={{ color: "#000000", fontWeight: 600 }}
                          >
                            {planCoveredTemp[0] &&
                            planCoveredTemp[0].dependentType &&
                            planCoveredTemp[0].dependentType.full_name
                              ? "Individual"
                              : "Group"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs sm md>
                        <div style={{ padding: "10px 0px" }}>
                          <Typography
                            component={"span"}
                            className={classes.planText}
                            style={{ color: "#000000", fontWeight: 600 }}
                          >
                            Plan
                          </Typography>
                        </div>
                      </Grid>
                      {this.props.user.underwriting_required && (
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.planText}
                              style={{ color: "#000000", fontWeight: 600 }}
                            >
                              Sum Assured
                            </Typography>
                          </div>
                        </Grid>
                      )}
                      {(this.props.user.client_name.config
                        .isPruAgencyEnrolment === "True" ||
                        isGADDPlan === true) && (
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.planText}
                              style={{ color: "#000000", fontWeight: 600 }}
                            >
                              Sum Assured
                            </Typography>
                          </div>
                        </Grid>
                      )}
                      {this.props.user.client_name.config
                        .isPruAgencyEnrolment === "True" && (
                        <Grid item xs sm md>
                          <div style={{ padding: "10px 0px" }}>
                            <Typography
                              component={"span"}
                              className={classes.planText}
                              style={{ color: "#000000", fontWeight: 600 }}
                            >
                              Health Loading
                            </Typography>
                          </div>
                        </Grid>
                      )}
                      {this.hidePremiumsCheck(
                        this.getCoverageIdByName(planCovered[0])
                      ) !== "true" &&
                        this.hidePremiumsCheck(
                          this.getCoverageIdByName(planCovered[0])
                        ) === undefined && (
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.planText}
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Premium
                              </Typography>
                            </div>
                          </Grid>
                        )}
                    </Grid>
                    {/* Header end */}

                    {this.employeeEnrolmentCheck(planCovered[0]) &&
                      this.employeeEnrolmentCheck(planCovered[0])
                        .employeeEnrole !== "false" &&
                      Array.from(this.state.planSelected)
                        .filter(planEmp => planEmp[0] === planCovered[0])
                        .map((planCoveredE, kepPlanCovered) => {
                          return (
                            <Grid
                              container
                              spacing={"8px"}
                              key={kepPlanCovered}
                              style={{ padding: "0px 10px" }}
                            >
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {`${user.fullName} (Self)`}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {this.props.user.client_name.config
                                      .isPruAgencyEnrolment === "True"
                                      ? planCoveredE[1] === "Opt Out"
                                        ? planCoveredE[1]
                                        : planCoveredE[1].option === "000"
                                        ? planCoveredE[1].code === "VECI"
                                          ? `VECI`
                                          : `Existing`
                                        : +planCoveredE[1].option
                                      : this.props.user.client_name.config
                                          .isPruAgencyMedEnrolment === "True"
                                      ? planCoveredE[1] === "Opt Out"
                                        ? planCoveredE[1]
                                        : planCoveredE[1].option === "000"
                                        ? planCoveredE[1].code === "GADD"
                                          ? `GADD`
                                          : `Existing`
                                        : this.renderPlanName(
                                            planCoveredE[1].option
                                          )
                                      : planCoveredE[1] === "Opt Out"
                                      ? planCoveredE[1]
                                      : !isGADDPlan
                                      ? planCoveredE[1].header
                                      : planCoveredE[1].option}
                                  </Typography>
                                </div>
                              </Grid>
                              {!isGADDPlan ||
                                (this.props.user.client_name.client_name ===
                                  "PACS" && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600
                                        }}
                                      ></Typography>
                                    </div>
                                  </Grid>
                                ))}
                              {this.props.user.underwriting_required && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {planCoveredE[1] === "Opt Out"
                                        ? "N/A"
                                        : `S$${planCoveredE[1].details[0].overview.details[0].details[0]}`}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {(this.props.user.client_name.config
                                .isPruAgencyEnrolment === "True" ||
                                isGADDPlan === true) && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {planCoveredE[1] === "Opt Out"
                                        ? "N/A"
                                        : this.props.user.coverage_details.current.map(
                                            currPlan => {
                                              if (
                                                planCoveredE[1].coverageId ===
                                                  currPlan.code ||
                                                planCoveredE[1].code ===
                                                  currPlan.code
                                              ) {
                                                return `S$${currPlan.sum_assured}`;
                                              }
                                            }
                                          )}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {this.props.user.client_name.config
                                .isPruAgencyEnrolment && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {planCoveredE[1] === "Opt Out"
                                        ? "N/A"
                                        : this.props.user.coverage_details.current.map(
                                            currPlan => {
                                              if (
                                                planCoveredE[1].coverageId ===
                                                  currPlan.code ||
                                                planCoveredE[1].code ===
                                                  currPlan.code
                                              ) {
                                                return `S$${currPlan.extra_premium}`;
                                              }
                                            }
                                          )}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {this.hidePremiumsCheck(
                                this.getCoverageIdByName(planCovered[0])
                              ) !== "true" &&
                                this.hidePremiumsCheck(
                                  this.getCoverageIdByName(planCovered[0])
                                ) === undefined && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {plans.find(
                                          plan =>
                                            plan.planType === planCovered[0]
                                        ).isVertical === "true"
                                          ? this.props.user.client_name.config
                                              .isPruAgencyEnrolment === "True"
                                            ? planCoveredE[1] === "Opt Out"
                                              ? "N/A"
                                              : this.props.user.coverage_details.current.map(
                                                  currPlan => {
                                                    if (
                                                      planCoveredE[1]
                                                        .coverageId ===
                                                        currPlan.code ||
                                                      planCoveredE[1].code ===
                                                        currPlan.code
                                                    ) {
                                                      return `S$${parseFloat(
                                                        currPlan.premium.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}${
                                                        user.client_name.config
                                                          .hideAnnual != "True"
                                                          ? "/year"
                                                          : ""
                                                      }`;
                                                    }
                                                  }
                                                )
                                            : `S$${parseFloat(
                                                this.premiumInfoVertical(
                                                  user,
                                                  planCoveredE,
                                                  "Employee"
                                                )
                                              ).toFixed(2)}${
                                                user.client_name.config
                                                  .hideAnnual != "True"
                                                  ? "/year"
                                                  : ""
                                              }`
                                          : `S$${parseFloat(
                                              this.premiumInfo(planCoveredE)
                                            ).toFixed(2)}${
                                              user.client_name.config
                                                .hideAnnual != "True"
                                                ? "/year"
                                                : ""
                                            }`}
                                      </Typography>
                                    </div>
                                  </Grid>
                                )}
                            </Grid>
                          );
                        })}
                    {this.employeeEnrolmentCheck(planCovered[0])
                      .dependentEnrole === "true" &&
                      planCovered[1] &&
                      planCovered[1]
                        .filter(
                          plan => plan.dependentType.dependent === "SPOUSE"
                        )
                        .sort((a, b) =>
                          a.dependentType.full_name.localeCompare(
                            b.dependentType.full_name
                          )
                        )
                        .map((plan, kepPlan) => {
                          let dependentSumAssured;
                          let dependentOption;
                          return (
                            <Grid
                              container
                              spacing={"8px"}
                              key={kepPlan}
                              style={{ padding: "0px 10px" }}
                            >
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.dependentType.full_name
                                      ? `${
                                          plan.dependentType.full_name
                                        } (${this.capitalizeFLetter(
                                          plan.dependentType.dependent
                                        )})`
                                      : plan.dependentType}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.planDep === "Opt Out"
                                      ? (dependentOption = plan.planDep)
                                      : this.props.user.dependencies.map(
                                          dependent => {
                                            if (
                                              dependent.dependent === "SPOUSE"
                                            ) {
                                              dependent.coverage_details.current.map(
                                                currPlan => {
                                                  if (
                                                    currPlan.code ==
                                                    plan.planDep.coverageId
                                                  ) {
                                                    dependentOption =
                                                      plan.planDep.header;
                                                  }
                                                }
                                              );
                                            }
                                          }
                                        )}
                                    {dependentOption !== "Opt Out"
                                      ? dependentOption
                                      : ""}
                                  </Typography>
                                </div>
                              </Grid>
                              {(this.props.user.client_name.config
                                .isPruAgencyEnrolment ||
                                this.props.user.client_name.config
                                  .isPruAgencyMedEnrolment === "True") && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {plan.planDep === "Opt Out"
                                        ? (dependentSumAssured = "N/A")
                                        : this.props.user.dependencies.map(
                                            dependent => {
                                              if (
                                                dependent.dependent === "SPOUSE"
                                              ) {
                                                dependent.coverage_details.current.map(
                                                  currPlan => {
                                                    if (
                                                      plan.planDep
                                                        .coverageId ===
                                                      currPlan.code
                                                    ) {
                                                      dependentSumAssured =
                                                        `S$` +
                                                        currPlan.sum_assured;
                                                    }
                                                  }
                                                );
                                              }
                                            }
                                          )}
                                      {dependentSumAssured !== "N/A"
                                        ? dependentSumAssured
                                        : ""}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {this.props.user.client_name.config
                                .isPruAgencyEnrolment && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {plan.planDep === "Opt Out"
                                        ? "N/A"
                                        : this.props.user.dependencies[0].coverage_details.current.map(
                                            currPlan => {
                                              if (
                                                plan.planDep.coverageId ===
                                                currPlan.code
                                              ) {
                                                return `S$${currPlan.extra_premium}`;
                                              }
                                            }
                                          )}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plans.find(
                                      plan => plan.planType === planCovered[0]
                                    ).isVertical === "true"
                                      ? user.client_name.config
                                          .isPruAgencyEnrolment === "True"
                                        ? plan.planDep === "Opt Out"
                                          ? "N/A"
                                          : this.props.user.dependencies[0].coverage_details.current.map(
                                              currPlan => {
                                                if (
                                                  plan.planDep.coverageId ===
                                                  currPlan.code
                                                ) {
                                                  return plan.planDep !=
                                                    "Opt Out"
                                                    ? `S$${parseFloat(
                                                        currPlan.premium.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}` +
                                                        `${
                                                          user.client_name
                                                            .config
                                                            .hideAnnual !=
                                                          "True"
                                                            ? "/year "
                                                            : ""
                                                        }`
                                                    : "N/A";
                                                }
                                              }
                                            )
                                        : `S$${parseFloat(
                                            this.premiumInfoVertical(
                                              plan.dependentType,
                                              plan.planDep,
                                              "SPOUSE"
                                            )
                                          ).toFixed(2)}${
                                            user.client_name.config
                                              .hideAnnual != "True"
                                              ? "/year"
                                              : ""
                                          }`
                                      : // `S$${parseFloat(this.premiumInfoDependent( planCovered,plan.planDep,plan.dependentType.full_name ?"Individual":plan.dependentType)).toFixed(2) }/year`
                                        // `S$${parseFloat(user.depPremiumSA.get(plan.dependentType.id).premium).toFixed(2)}/year`
                                        `S$${parseFloat(
                                          this.premiumInfoDependent(
                                            planCovered,
                                            plan.planDep,
                                            plan.dependentType.full_name
                                              ? "Individual"
                                              : plan.dependentType
                                          )
                                        ).toFixed(2)}${
                                          user.client_name.config.hideAnnual !=
                                          "True"
                                            ? "/year "
                                            : ""
                                        }`}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}
                    {this.employeeEnrolmentCheck(planCovered[0])
                      .dependentEnrole === "true" &&
                      planCovered[0] !==
                        "Group Accidental Death & Dismemberment (GADD) Benefits" &&
                      planCovered[1] &&
                      planCovered[1]
                        .filter(
                          plan => plan.dependentType.dependent === "CHILD"
                        )
                        .sort((a, b) =>
                          a.dependentType.full_name.localeCompare(
                            b.dependentType.full_name
                          )
                        )
                        .map((plan, kepPlan) => {
                          return (
                            <Grid
                              container
                              spacing={"8px"}
                              key={kepPlan}
                              style={{ padding: "0px 10px" }}
                            >
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.dependentType.full_name
                                      ? `${
                                          plan.dependentType.full_name
                                        } (${this.capitalizeFLetter(
                                          plan.dependentType.dependent
                                        )})`
                                      : plan.dependentType}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.planDep === "Opt Out"
                                      ? plan.planDep
                                      : plan.planDep.header}
                                  </Typography>
                                </div>
                              </Grid>
                              {this.props.user.underwriting_required && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {plan.planDep === "Opt Out"
                                        ? "N/A"
                                        : `S$${plan.planDep.details[0].overview.details[0].details[0]}`}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plans.find(
                                      plan => plan.planType === planCovered[0]
                                    ).isVertical === "true"
                                      ? `S$${parseFloat(
                                          this.premiumInfoVertical(
                                            plan.dependentType,
                                            plan.planDep,
                                            "CHILD"
                                          )
                                        ).toFixed(2)}${
                                          user.client_name.config.hideAnnual !=
                                          "True"
                                            ? "/year "
                                            : ""
                                        }`
                                      : // `S$${parseFloat(this.premiumInfoDependent( planCovered,plan.planDep,plan.dependentType.full_name ?"Individual":plan.dependentType)).toFixed(2) }/year`
                                      // `S$${parseFloat(user.depPremiumSA.get(plan.dependentType.id).premium).toFixed(2)}/year`
                                      plan.planDep != "Opt Out" &&
                                        this.premiumInfoDependent(
                                          planCovered,
                                          plan.planDep,
                                          plan.dependentType.full_name
                                            ? "Individual"
                                            : plan.dependentType
                                        ) == "0.00"
                                      ? this.props.user.client_name.config
                                          .waiverAllow !== undefined &&
                                        this.props.user.client_name.config
                                          .waiverAllow === "True"
                                        ? "Waived"
                                        : `S$0.00${
                                            user.client_name.config
                                              .hideAnnual != "True"
                                              ? "/year "
                                              : ""
                                          }`
                                      : // waived only one waiver is allow
                                        `S$${parseFloat(
                                          this.premiumInfoDependent(
                                            planCovered,
                                            plan.planDep,
                                            plan.dependentType.full_name
                                              ? "Individual"
                                              : plan.dependentType
                                          )
                                        ).toFixed(2)}${
                                          user.client_name.config.hideAnnual !=
                                          "True"
                                            ? "/year "
                                            : ""
                                        }`}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}
                    {this.employeeEnrolmentCheck(planCovered[0])
                      .dependentEnrole === "true" &&
                      planCovered[1] &&
                      planCovered[1]
                        .filter(
                          plan =>
                            plan.dependentType === "Spouse Only" ||
                            plan.dependentType === "Child(ren) Only" ||
                            plan.dependentType === "Spouse & Child(ren)" ||
                            plan.dependentType === "Dependants"
                        )
                        .map((plan, kepPlan) => {
                          const displayPlan1 = `S$${parseFloat(
                            this.premiumInfoDependent(
                              planCovered,
                              plan.planDep,
                              plan.dependentType.full_name
                                ? "Individual"
                                : plan.dependentType
                            )
                          ).toFixed(2)}${
                            user.client_name.config.hideAnnual != "True"
                              ? "/year "
                              : ""
                          }`;

                          if (
                            this.props.user.client_name.client_name ===
                              "PACS" &&
                            plan.dependentType === "Dependants"
                          ) {
                            return null;
                          }

                          return (
                            <Grid
                              container
                              spacing={"8px"}
                              key={kepPlan}
                              style={{ padding: "0px 10px" }}
                            >
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.dependentType.full_name
                                      ? `${
                                          plan.dependentType.full_name
                                        } (${this.capitalizeFLetter(
                                          plan.dependentType.dependent
                                        )})`
                                      : plan.dependentType}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs sm md>
                                <div style={{ padding: "10px 0px" }}>
                                  <Typography
                                    component={"span"}
                                    className={classes.planText}
                                  >
                                    {plan.planDep === "Opt Out"
                                      ? plan.planDep
                                      : !isGADDPlan && plan.planDep.header}
                                  </Typography>
                                </div>
                              </Grid>
                              {!isGADDPlan ||
                                (this.props.user.client_name.client_name ===
                                  "PACS" && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600
                                        }}
                                      ></Typography>
                                    </div>
                                  </Grid>
                                ))}
                              {this.props.user.underwriting_required && (
                                <Grid item xs sm md>
                                  <div style={{ padding: "10px 0px" }}>
                                    <Typography
                                      component={"span"}
                                      className={classes.planText}
                                    >
                                      {plan.planDep === "Opt Out"
                                        ? "N/A"
                                        : `S$${plan.planDep.details[0].overview.details[0].details[0]}`}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              {this.hidePremiumsCheck(
                                this.getCoverageIdByName(planCovered[0])
                              ) !== "true" &&
                                this.hidePremiumsCheck(
                                  this.getCoverageIdByName(planCovered[0])
                                ) === undefined && (
                                  <Grid item xs sm md>
                                    <div style={{ padding: "10px 0px" }}>
                                      <Typography
                                        component={"span"}
                                        className={classes.planText}
                                      >
                                        {displayPlan1}
                                      </Typography>
                                    </div>
                                  </Grid>
                                )}
                            </Grid>
                          );
                        })}

                    <div
                      className={classes.premiumBlock}
                      style={{ padding: 0 }}
                    >
                      <Grid
                        container
                        spacing={"8px"}
                        style={{ padding: "0px 10px" }}
                      >
                        {this.hidePremiumsCheck(
                          this.getCoverageIdByName(planCovered[0])
                        ) !== "true" &&
                          this.hidePremiumsCheck(
                            this.getCoverageIdByName(planCovered[0])
                          ) === undefined && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextLabel}
                                >
                                  Annual Premium
                                </Typography>
                              </div>
                            </Grid>
                          )}
                        {this.hidePremiumsCheck(
                          this.getCoverageIdByName(planCovered[0])
                        ) !== "true" &&
                          this.hidePremiumsCheck(
                            this.getCoverageIdByName(planCovered[0])
                          ) === undefined && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextLabel}
                                />
                              </div>
                            </Grid>
                          )}
                        {this.props.user.client_name.config
                          .isPruAgencyEnrolment === "True" && (
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.premiumTextLabel}
                              />
                            </div>
                          </Grid>
                        )}
                        {this.props.user.client_name.config
                          .isPruAgencyEnrolment === "True" ||
                          (this.props.user.client_name.config
                            .isPruAgencyMedEnrolment === "True" && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextLabel}
                                />
                              </div>
                            </Grid>
                          ))}
                        {this.props.user.underwriting_required && (
                          <Grid item xs sm md>
                            <div style={{ padding: "10px 0px" }}>
                              <Typography
                                component={"span"}
                                className={classes.premiumTextLabel}
                              />
                            </div>
                          </Grid>
                        )}
                        {this.hidePremiumsCheck(
                          this.getCoverageIdByName(planCovered[0])
                        ) !== "true" &&
                          this.hidePremiumsCheck(
                            this.getCoverageIdByName(planCovered[0])
                          ) === undefined && (
                            <Grid item xs sm md>
                              <div style={{ padding: "10px 0px" }}>
                                <Typography
                                  component={"span"}
                                  className={classes.premiumTextValue}
                                  style={{ float: "none", fontWeight: 600 }}
                                >
                                  S$
                                  {parseFloat(
                                    this.premiumCoverageCalculation(
                                      Array.from(
                                        this.state.planSelected
                                      ).filter(
                                        planEmp => planEmp[0] === planCovered[0]
                                      ),
                                      planCovered
                                    )
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  {user.client_name.config.hideAnnual != "True"
                                    ? "/year "
                                    : ""}
                                </Typography>
                              </div>
                            </Grid>
                          )}
                      </Grid>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        }
        {Array.from(this.state.planSelectedDependent).length > 1 && (
          <div className={classes.headerInfo} style={{ paddingTop: "0px" }}>
            {this.hidePremiumsCheck("GHSB") !== "true" &&
              this.hidePremiumsCheck("GHSB") === undefined && (
                <div
                  className={classes.premiumBlockTotal}
                  style={{ padding: "25px 0px 0px 0px" }}
                >
                  <Grid
                    container
                    spacing={"8px"}
                    style={{ padding: "0px 30px" }}
                  >
                    <Grid item xs sm md>
                      <div style={{ padding: "10px 0px" }}>
                        <Typography
                          component={"span"}
                          className={classes.premiumTextLabel}
                        >
                          Total Annual Premium
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs sm md>
                      <div style={{ padding: "10px 0px" }}>
                        <Typography
                          component={"span"}
                          className={classes.premiumTextLabel}
                        />
                      </div>
                    </Grid>
                    <Grid item xs sm md>
                      <div style={{ padding: "10px 0px" }}>
                        <Typography
                          component={"span"}
                          className={classes.premiumTextLabel}
                        />
                      </div>
                    </Grid>
                    {this.props.user.client_name.config.isPruAgencyEnrolment ===
                      "True" && (
                      <Grid item xs sm md>
                        <div style={{ padding: "10px 0px" }}>
                          <Typography
                            component={"span"}
                            className={classes.premiumTextLabel}
                          />
                        </div>
                      </Grid>
                    )}
                    {this.props.user.underwriting_required && (
                      <Grid item xs sm md>
                        <div style={{ padding: "10px 0px" }}>
                          <Typography
                            component={"span"}
                            className={classes.premiumTextLabel}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs sm md style={{ marginRight: "190px" }}>
                      <div style={{ padding: "10px 0px" }}>
                        <Typography
                          component={"span"}
                          className={classes.premiumTextLabel}
                          style={{ padding: "10px 0px" }}
                        >
                          S$
                          {parseFloat(this.premiumCalculationTotal())
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          {user.client_name.config.hideAnnual != "True"
                            ? "/year "
                            : ""}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "20px"
  },
  contentInfo: {
    paddingTop: 10,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerInfo: {
    padding: theme.spacing.unit * 2.5
  },
  headerText: {
    color: "#ED1C24",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center"
  },
  noteHead: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "28px"
  },
  noteBlock: {
    padding: "20px 0px"
  },
  bottomBackgroud: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px"
  },
  subHeaderText: {
    fontSize: "20px",
    //lineHeight: '55px',
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.388636px",
    color: "#6B6A6D"
  },
  subHeader1Text: {
    color: "#6B6A6D",
    fontSize: "20px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center"
  },
  planText: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#68737A"
  },
  premiumBlock: {
    padding: "35px 0px",
    width: "100%",
    borderBottom: "1px solid #E4E3E4",
    borderTop: "1px solid #E4E3E4",
    verticalAlign: "middle"
  },
  premiumBlockTotal: {
    padding: "45px 0px",
    width: "100%",
    borderBottom: "1px solid #000000",
    borderTop: "1px solid #000000",
    verticalAlign: "middle"
  },
  premiumTextLabel: {
    fontSize: "16px",
    color: "#000000",
    letterSpacing: "0.388636px",
    display: "flex",
    float: "left",
    fontWeight: 600
  },
  premiumTextValue: {
    fontSize: "16px",
    color: "#000000",
    float: "right"
    //fontWeight:600
  },
  premiumText: {
    color: "#333333",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "40px",
    alignItems: "center"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PlanDetails));
