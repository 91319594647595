export class DrugAlcoholAddictionHistoryClass {
  constructor(
    nameOfSubstance,
    dateOfTreatment,
    treatmentType,
    nameAndAddressOfConsultedDoctor
  ) {
    this.nameOfSubstance = nameOfSubstance;
    this.dateOfTreatment = dateOfTreatment;
    this.treatmentType = treatmentType;
    this.nameAndAddressOfConsultedDoctor = nameAndAddressOfConsultedDoctor;
  }

  getDrugAlcoholAddictionHistoryDetails = () => {
    return {
      nameOfSubstance: this.nameOfSubstance,
      dateOfTreatment: this.dateOfTreatment,
      treatmentType: this.treatmentType,
      nameAndAddressOfConsultedDoctor: this.nameAndAddressOfConsultedDoctor
    };
  };
}
