import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { OtherMedicalConditionClass } from "../../../../model/OtherMedicalCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const CancerTumourQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userCancerTumour, setUserCancerTumour] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.otherMedicalConditions?.cancerTumour &&
      setUserCancerTumour(user.otherMedicalConditions.cancerTumour);

    let validateSuccess = validateCancerTumour();

    let medicalCondition = user?.otherMedicalConditions?.cancerTumour;

    if (!medicalCondition || medicalCondition.length == 0) {
      setError(true);
      handleAddCancerTumour([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserCancerTumour(medicalCondition);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    if (userCancerTumour.length > 0) {
      updateUserHealthDeclaration();
    }
  }, [userCancerTumour.length]);

  const handleAddCancerTumour = () => {
    setUserCancerTumour([
      ...userCancerTumour,
      {
        nameOfCondition: "",
        nameOfConditionError: false,
        startDate: "",
        startDateError: false,
        endDate: "",
        endDateError: false,
        testTypeAndResult: "",
        testTypeAndResultError: false,
        treatmentType: "",
        treatmentTypeError: false,
        nameAddressOfDoctorConsulted: "",
        nameAddressOfDoctorConsultedError: false
      }
    ]);
  };

  const handleUpdateField = (field, index, value) => {
    userCancerTumour[index][field] = value;
    setUserCancerTumour([...userCancerTumour]);
  };

  const handleUpdateDate = (field, index, event) => {
    userCancerTumour[index][field] = event;
    setUserCancerTumour([...userCancerTumour]);
  };

  const validateCancerTumour = () => {
    let validateSuccess = true;
    let errorDisplay = "";

    if (userCancerTumour.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, subKey, true);
      errorDisplay = "Please enter at least 1 medical condition";
    }

    //mark field error
    userCancerTumour.map(userCancerTumour => {
      userCancerTumour.nameOfConditionError = false;
      userCancerTumour.startDateError = false;
      userCancerTumour.endDateError = false;
      userCancerTumour.testTypeAndResultError = false;
      userCancerTumour.treatmentTypeError = false;
      userCancerTumour.nameAddressOfDoctorConsultedError = false;

      if (!userCancerTumour.nameOfCondition) {
        userCancerTumour.nameOfConditionError = true;
      }

      if (!userCancerTumour.startDate) {
        userCancerTumour.startDateError = true;
      }

      if (!userCancerTumour.endDate) {
        userCancerTumour.endDateError = true;
      }

      if (!userCancerTumour.testTypeAndResult) {
        userCancerTumour.testTypeAndResultError = true;
      }

      if (!userCancerTumour.treatmentType) {
        userCancerTumour.treatmentTypeError = true;
      }

      if (!userCancerTumour.nameAddressOfDoctorConsulted) {
        userCancerTumour.nameAddressOfDoctorConsultedError = true;
      }
    });

    let errorObject = userCancerTumour.find(userCancerTumour => {
      return (
        userCancerTumour.nameOfConditionError ||
        userCancerTumour.startDateError ||
        userCancerTumour.endDateError ||
        userCancerTumour.testTypeAndResultError ||
        userCancerTumour.treatmentTypeError ||
        userCancerTumour.nameAddressOfDoctorConsultedError
      );
    });

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserCancerTumour([...userCancerTumour]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateCancerTumour();
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, subKey, false);

      let cancerTumour = [];
      let cancerTumourObject = {};

      userCancerTumour.map(userCancerTumour => {
        const medicalCondition = new OtherMedicalConditionClass(
          userCancerTumour.nameOfCondition,
          userCancerTumour.startDate,
          userCancerTumour.endDate,
          userCancerTumour.testTypeAndResult,
          userCancerTumour.treatmentType,
          userCancerTumour.nameAddressOfDoctorConsulted
        );
        cancerTumourObject = medicalCondition.getOtherMedicalConditionDetails();
        cancerTumour.push(cancerTumourObject);
      });

      const updatedUser = {
        ...user,
        [key]: { ...user[key], cancerTumour }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleRemove = index => {
    userCancerTumour.splice(index, 1);
    setUserCancerTumour([...userCancerTumour]);

    validateCancerTumour();
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      {userCancerTumour.map((cancerTumour, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Medical Condition {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button onClick={e => handleRemove(index)}>
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Name of Condition</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={cancerTumour.nameOfConditionError}
                      helperText={
                        cancerTumour.nameOfConditionError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameOfCondition",
                          index,
                          event.target.value
                        );
                      }}
                      value={cancerTumour.nameOfCondition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Start Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={cancerTumour.startDate}
                      date={cancerTumour.startDate}
                      onDateChange={event => {
                        handleUpdateDate("startDate", index, event);
                      }}
                    />
                    {cancerTumour.startDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">End Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={cancerTumour.endDate}
                      date={cancerTumour.endDate}
                      onDateChange={event => {
                        handleUpdateDate("endDate", index, event);
                      }}
                    />
                    {cancerTumour.endDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of test(s) done and result</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={cancerTumour.testTypeAndResultError}
                      helperText={
                        cancerTumour.testTypeAndResultError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "testTypeAndResult",
                          index,
                          event.target.value
                        );
                      }}
                      value={cancerTumour.testTypeAndResult}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of Treatment</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={cancerTumour.treatmentTypeError}
                      helperText={
                        cancerTumour.treatmentTypeError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "treatmentType",
                          index,
                          event.target.value
                        );
                      }}
                      value={cancerTumour.treatmentType}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">
                      Name & Address of Doctors/ Clinics/ Hospitals
                    </Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={cancerTumour.nameAddressOfDoctorConsultedError}
                      helperText={
                        cancerTumour.nameAddressOfDoctorConsultedError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameAddressOfDoctorConsulted",
                          index,
                          event.target.value
                        );
                      }}
                      value={cancerTumour.nameAddressOfDoctorConsulted}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}

      <Button
        // className={addPrivateFlying}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddCancerTumour}
      >
        <FormHelperText
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "1em",
            textTransform: "none"
          }}
        >
          Add Medical Condition
        </FormHelperText>
      </Button>

      <br />
      <br />
      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>

      <TableRow>
        <br />
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>

      <br />
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
