import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { OtherMedicalConditionClass } from "../../../../model/OtherMedicalCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const SevereInjuryQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userSevereInjury, setUserSevereInjury] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.otherMedicalConditions?.severeInjury &&
      setUserSevereInjury(user.otherMedicalConditions.severeInjury);

    let validateSuccess = validateSevereInjury();
    let medicalCondition = user?.otherMedicalConditions?.severeInjury;

    if (!medicalCondition || medicalCondition.length == 0) {
      setError(true);
      handleAddSevereInjury([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserSevereInjury(medicalCondition);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    if (userSevereInjury.length > 0) {
      updateUserHealthDeclaration();
    }
  }, [userSevereInjury.length]);

  const handleAddSevereInjury = () => {
    setUserSevereInjury([
      ...userSevereInjury,
      {
        nameOfCondition: "",
        nameOfConditionError: false,
        startDate: "",
        startDateError: false,
        endDate: "",
        endDateError: false,
        testTypeAndResult: "",
        testTypeAndResultError: false,
        treatmentType: "",
        treatmentTypeError: false,
        nameAddressOfDoctorConsulted: "",
        nameAddressOfDoctorConsultedError: false
      }
    ]);
  };

  const handleUpdateField = (field, index, value) => {
    userSevereInjury[index][field] = value;
    setUserSevereInjury([...userSevereInjury]);
  };

  const handleUpdateDate = (field, index, event) => {
    userSevereInjury[index][field] = event;
    setUserSevereInjury([...userSevereInjury]);
  };

  const validateSevereInjury = () => {
    let validateSuccess = true;
    let errorDisplay = "";

    if (userSevereInjury.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, subKey, true);
      errorDisplay = "Please enter at least 1 medical condition";
    }

    //mark field error
    userSevereInjury.map(userSevereInjury => {
      userSevereInjury.nameOfConditionError = false;
      userSevereInjury.startDateError = false;
      userSevereInjury.endDateError = false;
      userSevereInjury.testTypeAndResultError = false;
      userSevereInjury.treatmentTypeError = false;
      userSevereInjury.nameAddressOfDoctorConsultedError = false;

      if (!userSevereInjury.nameOfCondition) {
        userSevereInjury.nameOfConditionError = true;
      }

      if (!userSevereInjury.startDate) {
        userSevereInjury.startDateError = true;
      }

      if (!userSevereInjury.endDate) {
        userSevereInjury.endDateError = true;
      }

      if (!userSevereInjury.testTypeAndResult) {
        userSevereInjury.testTypeAndResultError = true;
      }

      if (!userSevereInjury.treatmentType) {
        userSevereInjury.treatmentTypeError = true;
      }

      if (!userSevereInjury.nameAddressOfDoctorConsulted) {
        userSevereInjury.nameAddressOfDoctorConsultedError = true;
      }
    });

    let errorObject = userSevereInjury.find(userSevereInjury => {
      return (
        userSevereInjury.nameOfConditionError ||
        userSevereInjury.startDateError ||
        userSevereInjury.endDateError ||
        userSevereInjury.testTypeAndResultError ||
        userSevereInjury.treatmentTypeError ||
        userSevereInjury.nameAddressOfDoctorConsultedError
      );
    });

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserSevereInjury([...userSevereInjury]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateSevereInjury();
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, subKey, false);

      let severeInjury = [];
      let severeInjuryObject = {};

      userSevereInjury.map(userSevereInjury => {
        const medicalCondition = new OtherMedicalConditionClass(
          userSevereInjury.nameOfCondition,
          userSevereInjury.startDate,
          userSevereInjury.endDate,
          userSevereInjury.testTypeAndResult,
          userSevereInjury.treatmentType,
          userSevereInjury.nameAddressOfDoctorConsulted
        );
        severeInjuryObject = medicalCondition.getOtherMedicalConditionDetails();
        severeInjury.push(severeInjuryObject);
      });

      const updatedUser = {
        ...user,
        [key]: { ...user[key], severeInjury }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleRemove = index => {
    userSevereInjury.splice(index, 1);
    setUserSevereInjury([...userSevereInjury]);

    validateSevereInjury();
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      {userSevereInjury.map((severeInjury, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Medical Condition {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button onClick={e => handleRemove(index)}>
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Name of Condition</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={severeInjury.nameOfConditionError}
                      helperText={
                        severeInjury.nameOfConditionError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameOfCondition",
                          index,
                          event.target.value
                        );
                      }}
                      value={severeInjury.nameOfCondition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Start Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={severeInjury.startDate}
                      date={severeInjury.startDate}
                      onDateChange={event => {
                        handleUpdateDate("startDate", index, event);
                      }}
                    />
                    {severeInjury.startDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">End Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={severeInjury.endDate}
                      date={severeInjury.endDate}
                      onDateChange={event => {
                        handleUpdateDate("endDate", index, event);
                      }}
                    />
                    {severeInjury.endDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of test(s) done and result</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={severeInjury.testTypeAndResultError}
                      helperText={
                        severeInjury.testTypeAndResultError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "testTypeAndResult",
                          index,
                          event.target.value
                        );
                      }}
                      value={severeInjury.testTypeAndResult}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of Treatment</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={severeInjury.treatmentTypeError}
                      helperText={
                        severeInjury.treatmentTypeError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "treatmentType",
                          index,
                          event.target.value
                        );
                      }}
                      value={severeInjury.treatmentType}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">
                      Name & Address of Doctors/ Clinics/ Hospitals
                    </Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={severeInjury.nameAddressOfDoctorConsultedError}
                      helperText={
                        severeInjury.nameAddressOfDoctorConsultedError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameAddressOfDoctorConsulted",
                          index,
                          event.target.value
                        );
                      }}
                      value={severeInjury.nameAddressOfDoctorConsulted}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}

      <Button
        // className={addPrivateFlying}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddSevereInjury}
      >
        <FormHelperText
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "1em",
            textTransform: "none"
          }}
        >
          Add Medical Condition
        </FormHelperText>
      </Button>

      <br />
      <br />
      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>

      <TableRow>
        <br />
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>

      <br />
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
