import React, { useCallback, useEffect, useRef, ReactNode } from "react";
import { Box, Grid, FormHelperText, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash/debounce";

import { questionnairesStyles, saveButton } from "../../questionnaire.styles";

import UserLabel from "../../components/UserLabel.component";
import {
  ErrorMessage,
  runValidate,
  useValidateFields
} from "../../hooks/useValidate";
import useHealthDeclaration from "../hooks/useHealthDeclaration";
import useActivityRecords from "../hooks/useActivityRecords";

/**
  @template {object} T
  @typedef {{
    item: T; 
    index: number; 
    onChange: (value: T) => void; 
    onValidate: (value: boolean) => void 
  }} FormItemMapperParams
 */

/**
  @template {object} T
  @typedef {(params: FormItemMapperParams<T>) => ReactNode}FormItemMapper
 */

/**
  @template {object} S
  @template {string} K
  @typedef {{
    id: string;
    onAddRecord: () => S
    formItemMapper: FormItemMapper<S>;
    stateRules: import("../../hooks/useValidate").StateRules<K, S>;
    extremeSportDataBuilder: (item: S) => import("../../../../../model/ExtremeSports.class");
  }} ExtremeSportQuestionnaireBaseProps
 */

/**
  @template {object} S
  @template {string} K
  @param {ExtremeSportQuestionnaireBaseProps<S, K>} props 
  @returns 
 */
export const ExtremeSportQuestionnaireBase = props => {
  const { container } = questionnairesStyles();

  const {
    user,
    key,
    subKey,
    healthDeclarationOfUsers,
    updateUserHealthDeclaration
  } = useHealthDeclaration(
    props,
    () => validate(userRecords).result,
    () =>
      userRecords.data.map(v =>
        props.extremeSportDataBuilder(v).getExtremeSportsDetails()
      )
  );

  const { state: userRecords, add, remove, update } = useActivityRecords(() => {
    const activity = user?.extremeSportsEngagement?.[subKey];

    if (!activity || activity.length === 0) {
      return {
        data: [props.onAddRecord()],
        validationList: [false]
      };
    }

    return {
      data: activity,
      validationList: activity.map(v => {
        return runValidate(v, props.stateRules).map(x => x.isValid);
      })
    };
  });
  const shouldCheckGlobalRequiredField = useRef(false);

  const { validate, errorFields } = useValidateFields(
    {
      data: value => {
        if (value.length === 0) {
          return { result: false, message: ErrorMessage.EMPTY_ARRAY };
        }

        return true;
      },
      validationList: value => value.every(v => v)
    },
    {
      onValidate: isValid => {
        if (!isValid || shouldCheckGlobalRequiredField.current) {
          debouncedCheckGlobalRequiredField(!isValid);
          shouldCheckGlobalRequiredField.current = false;
        }
      }
    }
  );

  const debouncedCheckGlobalRequiredField = useCallback(
    debounce(isError => {
      props.handleRequiredFieldsError(key, subKey, isError);
    }, 100),
    []
  );

  const handleAddRecord = () => {
    add(props.onAddRecord());
  };

  const updateRecordItem = (index, value) => {
    update(index, { data: value });
  };

  const onValidationItem = (index, value) => {
    update(index, { validationResult: value }, state => validate(state));
  };

  const handleRemove = index => {
    shouldCheckGlobalRequiredField.current = true;
    remove(index);
  };

  useEffect(() => {
    validate(userRecords);
  }, [userRecords.data.length]);

  return (
    <Box className={container}>
      <UserLabel user={user} hdfUsers={healthDeclarationOfUsers} />

      {userRecords.data.length > 0 && (
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          mb={2}
          sx={{
            maxWidth: 1200
          }}
        >
          {userRecords.data.map((item, index) => (
            <Box key={index}>
              <Grid container justifyContent="space-between" mb={2}>
                <Grid
                  display="inline-flex"
                  item
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box fontWeight="bold">Record {index + 1}</Box>
                </Grid>
                <Grid item justifyContent="center" alignItems="center">
                  <Button onClick={() => handleRemove(index)}>
                    <FormHelperText
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "1em",
                        textTransform: "none"
                      }}
                    >
                      Remove
                    </FormHelperText>
                  </Button>
                </Grid>
              </Grid>

              {props.formItemMapper({
                index,
                item,
                onChange: value => updateRecordItem(index, value),
                onValidate: value => onValidationItem(index, value)
              })}
            </Box>
          ))}
        </Box>
      )}

      <Box mb={3}>
        <Button
          endIcon={<AddIcon style={{ color: "red" }} />}
          onClick={handleAddRecord}
        >
          <FormHelperText
            style={{
              color: "red",
              fontWeight: "bold",
              fontSize: "1em",
              textTransform: "none"
            }}
          >
            Add Record
          </FormHelperText>
        </Button>
      </Box>

      {errorFields.data.error && (
        <Box mb={3} color="red">
          {errorFields.data.message}
        </Box>
      )}

      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
