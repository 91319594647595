import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import classNames from "classnames";

class SimpleControlDialog extends Component {
  render() {
    const {
      closeButtonText,
      closeHandler,
      description,
      okButtonText,
      onClose,
      onSubmit,
      show,
      valid,
      isCodeTrue,
      title,
      classes
    } = this.props;

    let isDisable = !valid;
    if (isCodeTrue) {
      console.log("isCodeTrue: " + isCodeTrue);
      isDisable = true;
    }
    return (
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="control-dialog-title"
        disableBackdropClick
        maxWidth="sm"
        fullWidth={true}
      >
        <form onSubmit={onSubmit}>
          <DialogTitle
            classes={{ root: classes.dialogTitle }}
            id="control-dialog-title"
          >
            {title}
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText classes={{ root: classes.dialogContentText }}>
              {description}
            </DialogContentText>
            <div style={{ padding: "30px 100px" }}>{this.props.children}</div>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
              onClick={closeHandler}
              className={classNames(classes.button, classes.cancelButton)}
            >
              {closeButtonText}
            </Button>
            <Button
              type="submit"
              disabled={isDisable}
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.okButton)}
            >
              {okButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    overflow: "hidden",
    textAlign: "center",
    padding: "30px 15px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 200,
      maxWidth: "md"
    }
  },
  dialogContentText: {
    color: "#000000",
    fontSize: "18px"
  },
  dialogActions: {
    justifyContent: "center",
    padding: "30px 15px"
  },
  dialogTitle: {
    textAlign: "center",
    padding: "30px 15px 0px 15px",
    fontSize: "24px !important",
    color: "#000000 !important"
  },

  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500,
    fontSize: "15.75px"
  },
  button: {
    minWidth: 150,
    fontSize: "15.75px",
    [theme.breakpoints.down("xs")]: {
      minWidth: 120
    }
  }
});

export default withStyles(styles, { withTheme: true })(SimpleControlDialog);
