import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userActions } from "../../../actions";
import { withStyles } from "@mui/styles";
import { dashBoardImages } from "../../../assets";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Enums from "../../../utils/Enums";
import Util from "../../../utils/Util";
import moment from "moment";
import MessageFormat from "messageformat";

class UpdateDashboard extends Component {
  state = {
    activeStep: 0
  };
  constructor(props) {
    super(props);
    this.route = this.props.route;
    this.state = {
      activeStep: 0,
      policyStartYear: moment(this.props.user.policy_start_date).format("YYYY"),
      enrolmentEndDate: moment(this.props.user.enrolment_end_date).format(
        "DD MMMM YYYY"
      ),
      selectPlanEligibility: this.selectPlanEligibility(this.props.user),
      enable_self_registration: props.user.enable_self_registration,
      status: props.user.status
    };
  }
  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  getSteps = () => {
    return ["", ""];
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return ``;
      case 1:
        return "";
    }
  };

  selectPlanEligibility = user => {
    if (
      user.fullName === null ||
      user.fullName === undefined ||
      user.fullName === ""
    )
      return false;
    if (
      user.passport_nric === null ||
      user.passport_nric === undefined ||
      user.passport_nric === ""
    )
      return false;
    if (user.dob === null || user.dob === undefined || user.dob === "")
      return false;
    if (user.email === null || user.email === undefined || user.email === "")
      return false;
    if (
      user.employee_no === null ||
      user.employee_no === undefined ||
      user.employee_no === ""
    )
      return false;
    if (user.gender === null || user.gender === undefined || user.gender === "")
      return false;
    if (
      user.nationality === null ||
      user.nationality === undefined ||
      user.nationality === ""
    )
      return false;
    if (
      user.marital_status === null ||
      user.marital_status === undefined ||
      user.marital_status === ""
    )
      return false;
    if (user.weight === null || user.weight === undefined || user.weight === "")
      return false;
    if (user.height === null || user.height === undefined || user.height === "")
      return false;
    if (
      user.occupation === null ||
      user.occupation === undefined ||
      user.occupation === ""
    )
      return false;

    return true;
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      selectPlanEligibility: this.selectPlanEligibility(this.props.user)
    });
  }

  render() {
    const { classes, history, user } = this.props;
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.props.user;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const mf = new MessageFormat("en");
    const updateHeader =
      this.state.enable_self_registration === true &&
      this.state.status === Enums.STATUS.PENDING_REGISTRATION
        ? mf.compile(labels["dashboard.register.header"])
        : mf.compile(labels["dashboard.update.header"]);
    const updateDescription =
      this.state.enable_self_registration === true &&
      this.state.status === Enums.STATUS.PENDING_REGISTRATION
        ? mf.compile(labels["dashboard.register.description"])
        : mf.compile(labels["dashboard.update.description"]);
    const updateSelect = mf.compile(labels["dashboard.update.select"]);
    const registerSelect =
      this.state.enable_self_registration === true &&
      this.state.status === Enums.STATUS.PENDING_REGISTRATION
        ? mf.compile(labels["dashboard.register.select"])
        : "";
    const placeholderMapTemp = !_.isEmpty(this.props.user.placeholderMap)
      ? new Map(this.props.user.placeholderMap)
      : new Map();
    return (
      <div className={classes.container}>
        <Grid container spacing={"24px"} className={classes.contentContainer}>
          <Grid item xs={12}>
            {user.fullName === "" ? (
              <Typography
                className={classes.titleText}
                variant="headline"
                color="inherit"
              >
                Welcome to PRUworks.
              </Typography>
            ) : (
              <Typography
                className={classes.titleText}
                variant="headline"
                color="inherit"
                dangerouslySetInnerHTML={{
                  __html: updateHeader({
                    fullName: placeholderMapTemp.get("{fullName}")
                  })
                }}
              />
            )}
            <Typography
              className={classes.contentText}
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: updateDescription({
                  enrolmentEnd: placeholderMapTemp.get("{enrolmentEnd}"),
                  policyStartDate: placeholderMapTemp.get("{policyStart}"),
                  policyEndDate: placeholderMapTemp.get("{policyEnd}")
                })
              }}
            />
            {this.state.enable_self_registration &&
            this.state.status === Enums.STATUS.PENDING_REGISTRATION ? (
              <Grid item xs={12} sm={6} md={6} className={classes.boxText}>
                <Card
                  className={classes.cardIconBox}
                  onClick={() => {
                    history.push("/auth/dashboard/enroleDetails", "Register");
                  }}
                >
                  <Grid container spacing={"24px"}>
                    <Grid item xs={2} sm={2} md={2}>
                      <img
                        className={classes.socialMedia}
                        src={dashBoardImages.updatePlan}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      md={8}
                      className={classes.cardIconBoxContent}
                    >
                      <Typography
                        className={classes.contentBoxText}
                        dangerouslySetInnerHTML={{
                          __html: registerSelect({
                            year: placeholderMapTemp.get("{year}")
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <IconButton className={classes.button} color={"primary"}>
                        <Icon>keyboard_arrow_right</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={6} className={classes.boxText}>
                <Card
                  className={classes.cardIconBox}
                  onClick={() => {
                    history.push("/auth/dashboard/updatePlan");
                  }}
                >
                  <Grid container spacing={"24px"}>
                    <Grid item xs={2} sm={2} md={2}>
                      <img
                        className={classes.socialMedia}
                        src={dashBoardImages.updatePlan}
                        alt="Update Plan logo"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      md={8}
                      className={classes.cardIconBoxContent}
                    >
                      <Typography
                        className={classes.contentBoxText}
                        dangerouslySetInnerHTML={{
                          __html: updateSelect({
                            year: placeholderMapTemp.get("{year}")
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                      <IconButton
                        className={classes.button}
                        color={"primary"}
                        style={{ top: "15px" }}
                      >
                        <Icon>keyboard_arrow_right</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    height: "100%",
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },

  contentContainer: {
    padding: 20,
    marginTop: 50
  },
  titleText: {
    margin: theme.spacing.unit,
    padding: 20,
    fontSize: "36px",
    lineHeight: "40px",
    color: "#000000"
  },
  contentText: {
    margin: theme.spacing.unit,
    padding: 20,
    fontSize: "24px",
    lineHeight: "40px",
    color: "#6B6A6D"
  },

  contentBoxText: {
    margin: theme.spacing.unit,
    fontSize: 20,
    lineHeight: "40px"
  },

  button: {
    margin: theme.spacing.unit
  },

  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },

  cardIconBox: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },

  cardIconBoxContent: {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px"
  },

  socialMedia: {
    width: 60,
    height: 60,
    marginLeft: 20,
    marginTop: 5
  },

  boxText: {
    padding: 20
  }
});

function mapStateToProps(state, prop) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(UpdateDashboard));
