import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
// import { createTheme } from "@mui/material";

export const questionnairesStyles = makeStyles({
  container: {
    backgroundColor: "#e1e1e1",
    marginLeft: 64,
    marginRight: 8,
    padding: 16,
    paddingLeft: 64,
    "& + &": {
      paddingTop: 32
    }
  },
  username: {
    paddingBottom: 24,
    marginInline: 4
  },
  tableCell: {
    borderWidth: 0
  },
  infoGrid: {
    padding: 0,
    display: "contents"
  },
  textFieldRed: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
      borderBottomColor: "red"
    }
  },
  summarySubQuestions: {
    backgroundColor: "#e1e1e1",
    width: "max-content",
    marginRight: 8,
    padding: 16,
    display: "block"
  }
});

export const saveButton = {
  backgroundColor: "red",
  borderRadius: "50px",
  color: "white",
  "&:hover": {
    backgroundColor: "#8b0000"
  }
};

export const questionnairesTheme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:before": {
            borderBottomColor: "red"
          }
        }
      }
    }
  }
});
