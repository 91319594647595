export const images = {
  pru_logo: require("./img/prulogo.png"),
  pru_menu_logo: require("./img/pru_menu_logo.jpg"),
  pru_menu_work_logo: require("./img/pruworks_main_logo.png"),
  pru_logo_face: require("./img/pru_logo_face.jpg"),
  background: require("./img/background.png")
};

export const icons = {};

export const menuIcons = {
  home: require("./img/menu/home.png"),
  viewPlan: require("./img/menu/view_plan.png"),
  viewDoc: require("./img/menu/view_doc.png"),
  contacts: require("./img/menu/contacts.png"),
  learn: require("./img/menu/learn.png"),
  readBenefits: require("./img/menu/read_benefits.png"),
  logout: require("./img/menu/log-out.png")
};

export const dashBoardImages = {
  background: require("./img/dashboard/dashboard-background.png"),
  updatePlan: require("./img/dashboard/update_plan.png"),
  viewPlan: require("./img/dashboard/view_plan.png"),
  viewDoc: require("./img/dashboard/view_doc.png"),
  bedImage: require("./img/dashboard/bed_img.png")
};

export const enrolment = {
  plans: require("./data/plans.js"),
  PACS: require("./data/PACS.js"),
  RIFixed: require("./data/RIFixed.js"),
  RIHybrid: require("./data/RIHybrid.js"),
  RI: require("./data/RI.js"),
  content: require("./data/staticContent.json"),
  hdfForm: require("./data/hdfForm.json")
};

export const benefitImages = {};

export const alert = {
  tip: require("./img/alert/tip.png")
};
