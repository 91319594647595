export class FemaleSpecificClass {
  constructor(
    testDate,
    testType,
    testResult,
    diagnosis,
    treatmentType,
    nameAddressOfDoctorConsulted
  ) {
    this.testDate = testDate;
    this.testType = testType;
    this.testResult = testResult;
    this.diagnosis = diagnosis;
    this.treatmentType = treatmentType;
    this.nameAddressOfDoctorConsulted = nameAddressOfDoctorConsulted;
  }

  getFemaleSpecificDetails = () => {
    return {
      testDate: this.testDate,
      testType: this.testType,
      testResult: this.testResult,
      diagnosis: this.diagnosis,
      treatmentType: this.treatmentType,
      nameAddressOfDoctorConsulted: this.nameAddressOfDoctorConsulted
    };
  };
}
