import { createSelector } from "reselect";

const selectHDF = state => state.hdf;
const hasHDFData = state => state.hasHDF;

export const selectHealthDeclarationOfUsers = createSelector(
  [selectHDF],
  hdfState => hdfState.users
);

export const selectHasHDF = createSelector(
  [hasHDFData],
  hasHDFState => hasHDFState
);
