import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { DrugAlcoholAddictionHistoryClass } from "../../../../model/DrugAlcoholAddictionHistory.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const DrugAlcoholAddictionQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed,
    datePicker
  } = questionnairesStyles();
  const { id } = props;

  const [nameOfSubstance, setNameOfSubstance] = useState("");
  const [dateOfTreatment, setDateOfTreatment] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [
    nameAddressOfConsultedDoctor,
    setNameAddressOfConsultedDoctor
  ] = useState("");

  const [nameOfSubstanceError, setNameOfSubstanceError] = useState(false);
  const [dateOfTreatmentError, setDateOfTreatmentError] = useState(false);
  const [treatmentTypeError, setTreatmentTypeError] = useState(false);
  const [
    nameAddressOfConsultedDoctorError,
    setNameAddressOfConsultedDoctorError
  ] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);
  useEffect(() => {
    user?.drugAlcoholAddictionHistory?.nameOfSubstance &&
      setNameOfSubstance(user.drugAlcoholAddictionHistory.nameOfSubstance);
    user?.drugAlcoholAddictionHistory?.dateOfTreatment &&
      setDateOfTreatment(user.drugAlcoholAddictionHistory.dateOfTreatment);
    user?.drugAlcoholAddictionHistory?.treatmentType &&
      setTreatmentType(user.drugAlcoholAddictionHistory.treatmentType);
    user?.drugAlcoholAddictionHistory?.nameAndAddressOfConsultedDoctor &&
      setNameAddressOfConsultedDoctor(
        user.drugAlcoholAddictionHistory.nameAndAddressOfConsultedDoctor
      );

    let validateSuccess = validateFields();

    if (user?.drugAlcoholAddictionHistory || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [
      nameOfSubstance,
      dateOfTreatment,
      treatmentType,
      nameAddressOfConsultedDoctor
    ];

  const validateFields = () => {
    let validateSuccess = true;

    setNameOfSubstanceError(!nameOfSubstance);
    setDateOfTreatmentError(!dateOfTreatment);
    setTreatmentTypeError(!treatmentType);
    setNameAddressOfConsultedDoctorError(!nameAddressOfConsultedDoctor);

    if (
      !nameOfSubstance ||
      !dateOfTreatment ||
      !treatmentType ||
      !nameAddressOfConsultedDoctor
    ) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const drugAlcoholAddictionHistory = new DrugAlcoholAddictionHistoryClass(
        nameOfSubstance,
        dateOfTreatment,
        treatmentType,
        nameAddressOfConsultedDoctor
      );

      const drugAlcoholAddictionHistoryObject = {};
      drugAlcoholAddictionHistoryObject[
        subKey
      ] = drugAlcoholAddictionHistory.getDrugAlcoholAddictionHistoryDetails();

      const updatedUser = {
        ...user,
        ...drugAlcoholAddictionHistoryObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Name of Substance</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={nameOfSubstanceError}
                helperText={nameOfSubstanceError && "This field is mandatory."}
                onChange={event => {
                  setNameOfSubstance(event.target.value);
                }}
                value={nameOfSubstance}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Date of Treatment</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <DatePicker
                key={dateOfTreatment}
                date={dateOfTreatment}
                onDateChange={event => {
                  setDateOfTreatment(event);
                }}
              />
            </Grid>
            {dateOfTreatmentError && (
              <FormHelperText style={{ color: "red" }}>
                This field is mandatory.
              </FormHelperText>
            )}
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of Treatment</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={treatmentTypeError}
                helperText={treatmentTypeError && "This field is mandatory."}
                onChange={event => {
                  setTreatmentType(event.target.value);
                }}
                value={treatmentType}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Name & Address of Doctor consulted</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                required
                error={nameAddressOfConsultedDoctorError}
                helperText={
                  nameAddressOfConsultedDoctorError &&
                  "This field is mandatory."
                }
                className={textFieldRed}
                value={nameAddressOfConsultedDoctor}
                onChange={event => {
                  setNameAddressOfConsultedDoctor(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
