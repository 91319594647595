import { useReducer } from "react";

/**
  @template {object} T
  @typedef {{
    data: T[]; validationList: boolean[]
  }} State
 */

/**
 * @template {object} T
 * @param {State<T>} state
 * @param {{ type: string; index: number; value: any; callback?: (state: State<T>) => void }} action
 * @returns
 */
const reducer = (state, action) => {
  if (action.type === "SET") {
    state = action.value;
  } else if (action.type === "ADD") {
    state.data.push(action.value);
    state.validationList.push(false);
    state = { ...state };
  } else if (action.type === "UPDATE") {
    state = { ...state };
    state.data[action.index] = action.value.data ?? state.data[action.index];
    state.validationList[action.index] =
      action.value.validationResult ?? state.validationList[action.index];

    action.callback?.(state);
  } else if (action.type === "Remove") {
    state = { ...state };
    state.data.splice(action.index, 1);
    state.validationList.splice(action.index, 1);
  }

  return state;
};

/**
 * @template {object} T
 * @param {State<T>|() => State<T>} initial
 * @returns
 */
const useActivityRecords = initial => {
  const [state, dispatch] = useReducer(
    reducer,
    null,
    typeof initial === "function" ? initial : () => initial
  );

  const add = value =>
    dispatch({
      type: "ADD",
      value
    });

  const set = value =>
    dispatch({
      type: "SET",
      value
    });

  /**
   *
   * @param {number} index
   * @param {{ data?: any; validationResult?: boolean }} value
   * @param {(state: State<T>) => void} callback
   * @returns
   */
  const update = (index, value, callback) =>
    dispatch({
      type: "UPDATE",
      index,
      value,
      callback
    });

  const remove = index =>
    dispatch({
      type: "Remove",
      index
    });

  return {
    state,
    dispatch,
    add,
    set,
    remove,
    update
  };
};

export default useActivityRecords;
