import * as actionTypes from "../actions/types";
import config from "../config/config";

const initialState = {
  type: config.app.type,
  build: config.app.build,
  scrollToTop: false
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_SCROLL:
      return {
        ...state,
        scrollToTop: true
      };
    case actionTypes.APP_SCROLL_FIN:
      return {
        ...state,
        scrollToTop: false
      };
    case actionTypes.APP_SET_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default: {
      return state;
    }
  }
};
