import { makeStyles } from "@mui/styles";

export const questionnairesStyles = makeStyles({
  subQuestionContainer: {
    marginBottom: 16
  },
  accordionBadge: {
    marginInline: 42
  },
  userHeaderTable: {
    textAlignLast: "center"
  },
  userHeaderTableRow: {
    display: "flex"
  },
  userHeaderTableCell: {
    flex: 1,
    textAlign: "left",
    paddingLeft: "5rem"
  },
  toggleComponentTable: {
    textAlignLast: "center",
    marginInline: 64
  },
  toggleComponentTableCell: {
    borderWidth: 0
  },
  accordion: {
    margin: 0
  },
  subQuestionTitle: {
    marginInline: 64,
    paddingLeft: "75px"
  },
  accordionTitle: {
    fontWeight: "bold",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  textFieldRed: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
      borderBottomColor: "red"
    }
  }
});
