import React from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import classNames from "classnames";

const SimpleConfirmDialog = ({
  name,
  title,
  description,
  open,
  closeHandler,
  okHandler,
  closeButtonText,
  okButtonText,
  classes
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      maxWidth="xs"
      aria-labelledby={`${name}-title`}
      aria-describedby={`${name}-description`}
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitle
        }}
        id={`${name}-title`}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText
          classes={{
            root: classes.description
          }}
          id={`${name}-description`}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActions
        }}
      >
        <Button
          onClick={closeHandler}
          variant="contained"
          className={classNames(classes.button, classes.cancelButton)}
        >
          {closeButtonText}
        </Button>
        <Button
          onClick={okHandler}
          variant="contained"
          color="primary"
          className={classNames(classes.button, classes.okButton)}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  paper: {
    padding: 30
  },
  dialogTitle: {
    paddingLeft: 0,
    fontSize: "1.1em",
    fontFamily: theme.typography.fontFamily
  },
  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  button: {
    minWidth: 150
  },
  description: {
    color: theme.colors.blackScale.black100
  },
  dialogContent: {
    paddingLeft: 0
  },
  dialogActions: {
    justifyContent: "center",
    marginTop: 20
  }
});

export default withStyles(styles, { withTheme: true })(SimpleConfirmDialog);
