import bowser from "bowser";

/**
 * Check if user is running on IE
 * Then we will additional root style for fixing some issues
 * @ bowser.msie => True is user is using IE
 * @ https://github.com/lancedikson/bowser
 *
 *
 * @type {import("@mui/material").Components<Omit<import("@mui/material").Theme, "components">>}
 */
const ieCompatible = bowser.msie
  ? {
      MuiInput: {
        styleOverrides: {
          root: {
            height: 31
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          multiline: {
            height: "100%"
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "center"
          }
        }
      }
    }
  : {};

export default ieCompatible;
