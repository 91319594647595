export class ExtremeSportsClass {
  constructor(country, frequency, depth, nameOfActivity, otherCountry) {
    this.country = country;
    this.frequency = frequency;
    this.depth = depth;
    this.nameOfActivity = nameOfActivity;
    this.otherCountry = otherCountry;
  }

  getExtremeSportsDetails = () => {
    return {
      country: this.country,
      frequency: this.frequency,
      depth: this.depth,
      nameOfActivity: this.nameOfActivity,
      otherCountry: this.otherCountry
    };
  };
}
