import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { OtherMedicalConditionClass } from "../../../../model/OtherMedicalCondition.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const OtherIllnessQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userOtherIllness, setUserOtherIllness] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.otherMedicalConditions?.otherIllness &&
      setUserOtherIllness(user.otherMedicalConditions.otherIllness);

    let validateSuccess = validateOtherIllness();
    let medicalCondition = user?.otherMedicalConditions?.otherIllness;

    if (!medicalCondition || medicalCondition.length == 0) {
      setError(true);
      handleAddOtherIllness([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserOtherIllness(medicalCondition);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    if (userOtherIllness.length > 0) {
      updateUserHealthDeclaration();
    }
  }, [userOtherIllness.length]);

  const handleAddOtherIllness = () => {
    setUserOtherIllness([
      ...userOtherIllness,
      {
        nameOfCondition: "",
        nameOfConditionError: false,
        startDate: "",
        startDateError: false,
        endDate: "",
        endDateError: false,
        testTypeAndResult: "",
        testTypeAndResultError: false,
        treatmentType: "",
        treatmentTypeError: false,
        nameAddressOfDoctorConsulted: "",
        nameAddressOfDoctorConsultedError: false
      }
    ]);
  };

  const handleUpdateField = (field, index, value) => {
    userOtherIllness[index][field] = value;
    setUserOtherIllness([...userOtherIllness]);
  };

  const handleUpdateDate = (field, index, event) => {
    userOtherIllness[index][field] = event;
    setUserOtherIllness([...userOtherIllness]);
  };

  const validateOtherIllness = () => {
    let validateSuccess = true;
    let errorDisplay = "";

    if (userOtherIllness.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, subKey, true);
      errorDisplay = "Please enter at least 1 medical condition";
    }

    //mark field error
    userOtherIllness.map(userOtherIllness => {
      userOtherIllness.nameOfConditionError = false;
      userOtherIllness.startDateError = false;
      userOtherIllness.endDateError = false;
      userOtherIllness.testTypeAndResultError = false;
      userOtherIllness.treatmentTypeError = false;
      userOtherIllness.nameAddressOfDoctorConsultedError = false;

      if (!userOtherIllness.nameOfCondition) {
        userOtherIllness.nameOfConditionError = true;
      }

      if (!userOtherIllness.startDate) {
        userOtherIllness.startDateError = true;
      }

      if (!userOtherIllness.endDate) {
        userOtherIllness.endDateError = true;
      }

      if (!userOtherIllness.testTypeAndResult) {
        userOtherIllness.testTypeAndResultError = true;
      }

      if (!userOtherIllness.treatmentType) {
        userOtherIllness.treatmentTypeError = true;
      }

      if (!userOtherIllness.nameAddressOfDoctorConsulted) {
        userOtherIllness.nameAddressOfDoctorConsultedError = true;
      }
    });

    let errorObject = userOtherIllness.find(userOtherIllness => {
      return (
        userOtherIllness.nameOfConditionError ||
        userOtherIllness.startDateError ||
        userOtherIllness.endDateError ||
        userOtherIllness.testTypeAndResultError ||
        userOtherIllness.treatmentTypeError ||
        userOtherIllness.nameAddressOfDoctorConsultedError
      );
    });

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserOtherIllness([...userOtherIllness]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateOtherIllness();
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, subKey, false);

      let otherIllness = [];
      let otherIllnessObject = [];

      userOtherIllness.map(userOtherIllness => {
        const medicalCondition = new OtherMedicalConditionClass(
          userOtherIllness.nameOfCondition,
          userOtherIllness.startDate,
          userOtherIllness.endDate,
          userOtherIllness.testTypeAndResult,
          userOtherIllness.treatmentType,
          userOtherIllness.nameAddressOfDoctorConsulted
        );
        otherIllnessObject = medicalCondition.getOtherMedicalConditionDetails();
        otherIllness.push(otherIllnessObject);
      });

      const updatedUser = {
        ...user,
        [key]: { ...user[key], otherIllness }
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  const handleRemove = index => {
    userOtherIllness.splice(index, 1);
    setUserOtherIllness([...userOtherIllness]);

    validateOtherIllness();
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      {userOtherIllness.map((otherIllness, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Medical Condition {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button onClick={e => handleRemove(index)}>
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Name of Condition</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={otherIllness.nameOfConditionError}
                      helperText={
                        otherIllness.nameOfConditionError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameOfCondition",
                          index,
                          event.target.value
                        );
                      }}
                      value={otherIllness.nameOfCondition}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Start Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={otherIllness.startDate}
                      date={otherIllness.startDate}
                      onDateChange={event => {
                        handleUpdateDate("startDate", index, event);
                      }}
                    />
                    {otherIllness.startDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">End Date</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <DatePicker
                      key={otherIllness.endDate}
                      date={otherIllness.endDate}
                      onDateChange={event => {
                        handleUpdateDate("endDate", index, event);
                      }}
                    />
                    {otherIllness.endDateError && (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of test(s) done and result</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={otherIllness.testTypeAndResultError}
                      helperText={
                        otherIllness.testTypeAndResultError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "testTypeAndResult",
                          index,
                          event.target.value
                        );
                      }}
                      value={otherIllness.testTypeAndResult}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Type of Treatment</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={otherIllness.treatmentTypeError}
                      helperText={
                        otherIllness.treatmentTypeError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "treatmentType",
                          index,
                          event.target.value
                        );
                      }}
                      value={otherIllness.treatmentType}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={2} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">
                      Name & Address of Doctors/ Clinics/ Hospitals
                    </Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      required
                      error={otherIllness.nameAddressOfDoctorConsultedError}
                      helperText={
                        otherIllness.nameAddressOfDoctorConsultedError &&
                        "This field is mandatory."
                      }
                      onChange={event => {
                        handleUpdateField(
                          "nameAddressOfDoctorConsulted",
                          index,
                          event.target.value
                        );
                      }}
                      value={otherIllness.nameAddressOfDoctorConsulted}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}

      <Button
        // className={addPrivateFlying}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddOtherIllness}
      >
        <FormHelperText
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "1em",
            textTransform: "none"
          }}
        >
          Add Medical Condition
        </FormHelperText>
      </Button>

      <br />
      <br />
      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>

      <TableRow>
        <br />
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>

      <br />
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
