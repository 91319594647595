import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Table,
  TableRow,
  TableCell,
  TextField,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { FamilyMedicalHistoryClass } from "../../../../model/FamilyMedicalHistory.class";
import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";
import { familyMedicalHistoryStyles } from "./FamilyMedicalHistoryQuestionnaire.styles";

export const FamilyMedicalHistoryQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { addFamilyMember } = familyMedicalHistoryStyles();

  const { id } = props;
  const { userId, key } = getDifferentiatedValues(id);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  const [userFamilyMedicalHistory, setUserFamilyMedicalhistory] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    user?.familyMedicalHistory &&
      setUserFamilyMedicalhistory(user.familyMedicalHistory);

    let validateSuccess = validateUserFamilyHistory();

    let history = user?.familyMedicalHistory;

    if (!history || history.length == 0) {
      setError(true);
      handleAddFamilyMember([]);
      validateSuccess = false;
    } else {
      setError(false);
      setUserFamilyMedicalhistory(history);
      validateSuccess = true;
    }

    props.handleRequiredFieldsError(key, null, !validateSuccess);
  }, []);

  const handleAddFamilyMember = () => {
    setUserFamilyMedicalhistory([
      ...userFamilyMedicalHistory,
      {
        relationship: "",
        relationshipError: false,
        causeOfDeath: "",
        causeOfDeathError: false,
        ageOfOnset: "",
        ageOfOnsetError: false,
        ageOfDeath: ""
      }
    ]);
  };

  const handleRemoveFamilyMember = index => {
    userFamilyMedicalHistory.splice(index, 1);
    setUserFamilyMedicalhistory([...userFamilyMedicalHistory]);

    validateUserFamilyHistory();
  };

  const handleUpdateField = (field, index, value) => {
    userFamilyMedicalHistory[index][field] = value;
    setUserFamilyMedicalhistory([...userFamilyMedicalHistory]);
  };

  const handleKeyDown = event => {
    // Disable input of "e" and "-"
    if (event.key === "e" || event.key === "-") {
      event.preventDefault();
    }
  };

  const validateUserFamilyHistory = () => {
    let validateSuccess = true;

    let errorDisplay = "";

    if (userFamilyMedicalHistory.length == 0) {
      setError(true);
      props.handleRequiredFieldsError(key, null, true);
      errorDisplay = "Please enter at least 1 family member";
    }

    // mark field error
    userFamilyMedicalHistory.map(userFamilyMedicalHistory => {
      userFamilyMedicalHistory.relationshipError = false;
      userFamilyMedicalHistory.causeOfDeathError = false;
      userFamilyMedicalHistory.ageOfOnsetError = false;

      if (!userFamilyMedicalHistory.relationship) {
        userFamilyMedicalHistory.relationshipError = true;
      }
      if (!userFamilyMedicalHistory.causeOfDeath) {
        userFamilyMedicalHistory.causeOfDeathError = true;
      }
      if (!userFamilyMedicalHistory.ageOfOnset) {
        userFamilyMedicalHistory.ageOfOnsetError = true;
      }
    });

    let errorObject = userFamilyMedicalHistory.find(
      userFamilyMedicalHistory => {
        return (
          userFamilyMedicalHistory.relationshipError ||
          userFamilyMedicalHistory.causeOfDeathError ||
          userFamilyMedicalHistory.ageOfOnsetError
        );
      }
    );

    if (errorDisplay || errorObject) {
      validateSuccess = false;
      setUserFamilyMedicalhistory([...userFamilyMedicalHistory]);
    }
    setError(errorDisplay);

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validateSuccess = validateUserFamilyHistory();

    if (validateSuccess) {
      props.handleRequiredFieldsError(key, null, false);

      let historyList = [];
      userFamilyMedicalHistory.map(userFamilyMedicalHistory => {
        const history = new FamilyMedicalHistoryClass(
          userFamilyMedicalHistory.relationship,
          userFamilyMedicalHistory.causeOfDeath,
          userFamilyMedicalHistory.ageOfOnset,
          userFamilyMedicalHistory.ageOfDeath
        );
        historyList.push(history.getFamilyMedicalHistoryDetails());
      });

      const updatedUser = {
        ...user,
        [key]: historyList
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      {userFamilyMedicalHistory.map((familyMedicalHistory, index) => {
        return (
          <Table key={index}>
            <TableRow>
              <TableCell colSpan={3} align="left">
                <Grid container item spacing={"2px"} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Family Member {index + 1}</Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Button
                  className={addFamilyMember}
                  onClick={e => handleRemoveFamilyMember(index)}
                >
                  <FormHelperText
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1em",
                      textTransform: "none"
                    }}
                  >
                    Remove
                  </FormHelperText>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={tableCell}>
                <Grid container item spacing={"2px"} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Relationship</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      error={familyMedicalHistory.relationshipError}
                      onChange={event => {
                        handleUpdateField(
                          "relationship",
                          index,
                          event.target.value
                        );
                      }}
                      value={familyMedicalHistory.relationship}
                    />
                    {familyMedicalHistory.relationshipError ? (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={"2px"} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Condition / Cause of Death</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      className={textFieldRed}
                      error={familyMedicalHistory.causeOfDeathError}
                      onChange={event => {
                        handleUpdateField(
                          "causeOfDeath",
                          index,
                          event.target.value
                        );
                      }}
                      value={familyMedicalHistory.causeOfDeath}
                    />
                    {familyMedicalHistory.causeOfDeathError ? (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={"2px"} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Age of Onset</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      type={"number"}
                      inputProps={{ min: 0, max: 999 }}
                      className={textFieldRed}
                      error={familyMedicalHistory.ageOfOnsetError}
                      onKeyDown={handleKeyDown}
                      onChange={event => {
                        handleUpdateField(
                          "ageOfOnset",
                          index,
                          event.target.value
                        );
                      }}
                      value={familyMedicalHistory.ageOfOnset}
                    />
                    {familyMedicalHistory.ageOfOnsetError ? (
                      <FormHelperText style={{ color: "red" }}>
                        This field is mandatory.
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={tableCell}>
                <Grid container item spacing={"2px"} flexDirection={"column"}>
                  <Grid item xs={5} className={infoGrid}>
                    <Box fontWeight="bold">Age of Death (if deceased)</Box>
                  </Grid>
                  <Grid item xs={7} className={infoGrid}>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      type={"number"}
                      inputProps={{ min: 0, max: 999 }}
                      className={textFieldRed}
                      onKeyDown={handleKeyDown}
                      onChange={event => {
                        handleUpdateField(
                          "ageOfDeath",
                          index,
                          event.target.value
                        );
                      }}
                      value={familyMedicalHistory.ageOfDeath}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        );
      })}
      <Button
        className={addFamilyMember}
        endIcon={<AddIcon style={{ color: "red" }} />}
        onClick={handleAddFamilyMember}
      >
        <FormHelperText
          style={{ color: "red", fontWeight: "bold", fontSize: "1em" }}
        >
          Add Family Member
        </FormHelperText>
      </Button>

      <br />
      <br />

      <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
      <br />

      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
