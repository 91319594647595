import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../../actions";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import UpdateDashboard from "./UpdateDashboard";
import ViewDashboard from "./ViewDashboard";
import _ from "lodash";

class DashboardMain extends Component {
  constructor(props) {
    super(props);
    this.route = props.route;
    this.state = {
      mountDash: ""
    };
  }

  getDashboard = status => {
    return status === "SUBMITTED" ? (
      !_.isEmpty(this.props.user.placeholderMap) && (
        <ViewDashboard history={this.props.history} />
      )
    ) : (
      <UpdateDashboard history={this.props.history} />
    );
  };

  render() {
    const { classes, history, user } = this.props;
    return (
      <main>
        {user.status && user.content && this.getDashboard(user.status)}
        {/* {this.state.mountDash} */}
      </main>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  }
});

function mapStateToProps(state, prop) {
  return { user: state.user, agentType: state.user.auth.pcode };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardMain));
