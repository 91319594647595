import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import path from "path-browserify";
import PDFViewer from "./components/pdfViewer";
import { document } from "../../../assets/data/documents";

class MyDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      fileName: undefined,
      open: false
    };
  }

  handleClick = (docUrl, fileName) => {
    this.setState({ pdf: docUrl, fileName: fileName, open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleWebClick = docUrl => {
    window.open(docUrl, "_blank", "noopener noreferrer");
  };

  render() {
    const { classes, pcode, user } = this.props;

    return (
      <div className={classes.root}>
        <main>
          <div className={classes.content}>
            <Typography
              className={classes.heading}
              variant="title"
              color="inherit"
            >
              Documents
            </Typography>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Filename</TableCell>
                  <TableCell className={classes.tableHead} />
                </TableRow>
              </TableHead>
              <TableBody>
                {// eslint-disable-next-line
                document.map((doc, index) => {
                  const { fileName, type, code, category, page, docType } = doc;

                  const docUrl = doc.file
                    ? window.location.href.split("/")[0] +
                      "/static/media/" +
                      path.basename(doc.file)
                    : doc.weblink;

                  if (
                    type &&
                    type.includes(user.client_name.client_name) &&
                    !(docType && docType === "weblink")
                  ) {
                    if (category ? category.includes(user.category) : true) {
                      if (page ? page.includes("DOCUMENT") : true) {
                        return (
                          <TableRow
                            key={index}
                            className={`${classes.tableRowDoc} ${classes.dlIcon} `}
                            onClick={() => {
                              this.handleClick(docUrl, fileName);
                            }}
                          >
                            <TableCell className={classes.tableCellDoc}>
                              {doc.desc}
                            </TableCell>
                            <TableCell className={classes.dlCell}>
                              <p className={classes.dlHolder}>
                                <Icon
                                  onClick={() => {
                                    this.handleClick(docUrl, fileName);
                                  }}
                                  className={classes.dlIcon}
                                >
                                  file_download
                                </Icon>
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }
                  } else if (
                    type &&
                    type.includes(user.client_name.client_name) &&
                    docType &&
                    docType === "weblink"
                  ) {
                    if (category ? category.includes(user.category) : true) {
                      if (page ? page.includes("DOCUMENT") : true) {
                        return (
                          <TableRow
                            key={index}
                            className={`${classes.tableRowDoc} ${classes.dlIcon} `}
                            onClick={() => {
                              this.handleWebClick(docUrl);
                            }}
                          >
                            <TableCell className={classes.tableCellDoc}>
                              {doc.desc}
                            </TableCell>
                            <TableCell className={classes.dlCell}>
                              <p className={classes.dlHolder}>
                                <Icon className={classes.dlIcon}></Icon>
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }
                  }
                })}
              </TableBody>
            </Table>
          </div>
          <PDFViewer
            open={this.state.open}
            handleClose={this.handleClose}
            pdf={this.state.pdf}
            fileName={this.state.fileName}
          />
        </main>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },
  maxWidth: {
    width: "100%"
  },
  maxHeight: {
    height: "100%"
  },
  heading: {
    fontSize: "28px",
    color: "#333333"
  },
  content: {
    padding: "10px 10px"
  },
  tableHead: {
    backgroundColor: "#333",
    fontSize: "14px",
    padding: "20px",
    color: theme.palette.common.white
  },
  tableRowDoc: {
    color: "#818181",
    backgroundColor: "white",
    height: "30px"
  },
  tableCellDoc: {
    padding: 0,
    paddingLeft: 25
  },
  clearCellStyle: {
    padding: 0,
    paddingRight: "0!important",
    border: 0
  },
  pdfViewModal: {
    overflowY: "scroll"
  },
  dlHolder: {
    textAlign: "right"
  },
  dlIcon: {
    "&:hover": {
      color: "#919191",
      cursor: "pointer"
    },
    color: "#b1b1b1"
  },
  dlCell: {
    padding: 0
  },
  pdfViewer: {
    height: "80%"
  },
  pdfViewHolder: {
    position: "relative",
    paddingTop: "50px",
    paddingBottom: "50px"
  },
  pdfActionsHolder: {
    position: "fixed",
    bottom: "20px"
  },
  prevAction: {
    float: "right",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  nextAction: {
    float: "left",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  dlAction: {
    textDecoration: "none",
    position: "fixed",
    top: "20px",
    right: "20px",
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  pdfZoomHolder: {
    position: "fixed",
    bottom: "20px",
    right: 0,
    maxWidth: "100px"
  },
  zoomIn: {
    float: "right",
    marginRight: "20px"
  },
  zoomOut: {
    float: "right",
    marginRight: "20px"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user,
    pcode: state.user.auth.pcode
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MyDocuments));
