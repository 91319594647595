import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import VerifyDetailsMain from "./MyBenefits/VerifyDetailsMain";
import { appActions, userActions } from "../../../../../../actions";
import { bindActionCreators } from "redux";

class VerifyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleTab } = this.props;
    return (
      <div>
        {/* -- Render Child pages */}

        <VerifyDetailsMain
          history={this.props.history}
          tabValue={this.props.tabValue}
          handleTab={this.handleTab}
        ></VerifyDetailsMain>
      </div>
    );
  }
}

const styles = theme => ({});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VerifyDetails));
