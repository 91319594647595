import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { STDHistoryClass } from "../../../../model/STDHistory.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const STDsHistoryQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;

  const [date, setDate] = useState("");
  const [relationship, setRelationship] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [treatment, setTreatment] = useState("");
  const [
    nameAddressOfDoctorConsulted,
    setNameAddressOfDoctorConsulted
  ] = useState("");

  const [dateError, setDateError] = useState(false);
  const [relationshipError, setRelationshipError] = useState(false);
  const [diagnosisError, setDiagnosisError] = useState(false);
  const [treatmentError, setTreatmentError] = useState(false);
  const [
    nameAddressOfDoctorConsultedError,
    setNameAddressOfDoctorConsultedError
  ] = useState(undefined);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const { userId, key, subKey } = getDifferentiatedValues(id);

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.sexuallyTransmittedDiseases?.date &&
      setDate(user.sexuallyTransmittedDiseases.date);
    user?.sexuallyTransmittedDiseases?.relationship &&
      setRelationship(user.sexuallyTransmittedDiseases.relationship);
    user?.sexuallyTransmittedDiseases?.diagnosis &&
      setDiagnosis(user.sexuallyTransmittedDiseases.diagnosis);
    user?.sexuallyTransmittedDiseases?.treatment &&
      setTreatment(user.sexuallyTransmittedDiseases.treatment);
    user?.sexuallyTransmittedDiseases?.nameAddressOfDoctorConsulted &&
      setNameAddressOfDoctorConsulted(
        user.sexuallyTransmittedDiseases.nameAddressOfDoctorConsulted
      );

    let validateSuccess = validateFields();

    if (user?.sexuallyTransmittedDiseases || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [date, relationship, diagnosis, treatment, nameAddressOfDoctorConsulted];

  const validateFields = () => {
    let validateSuccess = true;

    setDateError(!date);
    setRelationshipError(!relationship);
    setDiagnosisError(!diagnosis);
    setTreatmentError(!treatment);
    setNameAddressOfDoctorConsultedError(!nameAddressOfDoctorConsulted);

    if (
      !date ||
      !relationship ||
      !diagnosis ||
      !treatment ||
      !nameAddressOfDoctorConsulted
    ) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const stdHistory = new STDHistoryClass(
        date,
        relationship,
        diagnosis,
        treatment,
        nameAddressOfDoctorConsulted
      );

      const stdHistoryObject = {};
      stdHistoryObject[subKey] = stdHistory.getSTDHistoryDetails();

      const updatedUser = {
        ...user,
        ...stdHistoryObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>

      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Date</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <DatePicker
                key={date}
                date={date}
                onDateChange={event => {
                  setDate(event);
                }}
              />
            </Grid>
            {dateError && (
              <FormHelperText style={{ color: "red" }}>
                This field is mandatory.
              </FormHelperText>
            )}
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Relationship</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={relationshipError}
                helperText={relationshipError && "This field is mandatory."}
                onChange={event => {
                  setRelationship(event.target.value);
                }}
                value={relationship}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Diagnosis</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={diagnosisError}
                helperText={diagnosisError && "This field is mandatory."}
                onChange={event => {
                  setDiagnosis(event.target.value);
                }}
                value={diagnosis}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Treatment</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={treatmentError}
                helperText={treatmentError && "This field is mandatory."}
                onChange={event => {
                  setTreatment(event.target.value);
                }}
                value={treatment}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Name and Address of Doctor Consulted</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={nameAddressOfDoctorConsultedError}
                helperText={
                  nameAddressOfDoctorConsultedError &&
                  "This field is mandatory."
                }
                onChange={event => {
                  setNameAddressOfDoctorConsulted(event.target.value);
                }}
                value={nameAddressOfDoctorConsulted}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
