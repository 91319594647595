import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  TableRow,
  TableCell,
  TextField,
  Typography,
  FormHelperText,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee
} from "../../../../utils/HealthDeclarationForm.utils";

import { HealthScreeningHistoryClass } from "../../../../model/HealthScreeningHistory.class";

import { selectHealthDeclarationOfUsers } from "../../../../redux/health-declaration/hdf.selectors";
import { handleUpdateHealthDeclaration } from "../../../../redux/health-declaration/hdf.actions";

import { questionnairesStyles, saveButton } from "../questionnaire.styles";

import { DatePicker } from "../../date-picker/DatePicker.component";

export const HealthScreeningHistoryQuestionnaire = props => {
  const {
    container,
    username,
    tableCell,
    infoGrid,
    textFieldRed
  } = questionnairesStyles();
  const { id } = props;
  const { userId, key, subKey } = getDifferentiatedValues(id);

  const [testDate, setTestDate] = useState("");
  const [testType, setTestType] = useState("");
  const [testReason, setTestReason] = useState("");
  const [testResult, setTestResult] = useState("");
  const [typeOfTreatment, setTypeOfTreatment] = useState("");

  const [testDateError, setTestDateError] = useState(false);
  const [testTypeError, setTestTypeError] = useState(false);
  const [testReasonError, setTestReasonError] = useState(false);
  const [testResultError, setTestResultError] = useState(false);
  const [typeOfTreatmentError, setTypeOfTreatmentError] = useState(false);

  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);
  const dispatch = useDispatch();

  const userIndex = getUserIndex(healthDeclarationOfUsers, userId);
  const user = getUser(healthDeclarationOfUsers, userId);

  useEffect(() => {
    user?.healthScreeningHistory?.testDate &&
      setTestDate(user.healthScreeningHistory.testDate);
    user?.healthScreeningHistory?.testType &&
      setTestType(user.healthScreeningHistory.testType);
    user?.healthScreeningHistory?.testReason &&
      setTestReason(user.healthScreeningHistory.testReason);
    user?.healthScreeningHistory?.testResult &&
      setTestResult(user.healthScreeningHistory.testResult);
    user?.healthScreeningHistory?.typeOfTreatment &&
      setTypeOfTreatment(user.healthScreeningHistory.typeOfTreatment);

    let validateSuccess = validateFields();

    if (user?.healthScreeningHistory || validateSuccess) {
      validateSuccess = true;
    }
    props.handleRequiredFieldsError(key, subKey, !validateSuccess);
  }, []);

  useEffect(() => {
    validateFields();
  }),
    [testDate, testType, testReason, testResult, typeOfTreatment];

  const validateFields = () => {
    let validateSuccess = true;

    setTestDateError(!testDate);
    setTestTypeError(!testType);
    setTestReasonError(!testReason);
    setTestResultError(!testResult);
    setTypeOfTreatmentError(!typeOfTreatment);

    if (
      !testDate ||
      !testType ||
      !testReason ||
      !testResult ||
      !typeOfTreatment
    ) {
      validateSuccess = false;
    }

    return validateSuccess;
  };

  const updateUserHealthDeclaration = () => {
    let validationResult = validateFields();

    if (validationResult) {
      props.handleRequiredFieldsError(key, subKey, false);
      const healthScreeningHistory = new HealthScreeningHistoryClass(
        testDate,
        testType,
        testReason,
        testResult,
        typeOfTreatment
      );
      const healthScreeningHistoryObject = {};
      healthScreeningHistoryObject[
        subKey
      ] = healthScreeningHistory.getHealthScreeningHistoryDetails();

      const updatedUser = {
        ...user,
        ...healthScreeningHistoryObject
      };

      healthDeclarationOfUsers[userIndex] = updatedUser;
      dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
    }
  };

  return (
    <Box className={container}>
      <Typography className={username}>
        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ? "" : user?.full_name}
        {isOnlyAndMainEmployee(healthDeclarationOfUsers)
          ? ""
          : user.person_type === "EMPLOYEE"
          ? "(Self)"
          : user.person_type === "SPOUSE"
          ? "(Spouse)"
          : "(Child)"}
      </Typography>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Date of test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <DatePicker
                key={testDate}
                date={testDate}
                onDateChange={event => {
                  setTestDate(event);
                }}
              />
            </Grid>
            {testDateError && (
              <FormHelperText style={{ color: "red" }}>
                This field is mandatory.
              </FormHelperText>
            )}
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={testTypeError}
                helperText={testTypeError && "This field is mandatory."}
                onChange={event => {
                  setTestType(event.target.value);
                }}
                value={testType}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Reason for test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={testReasonError}
                helperText={testReasonError && "This field is mandatory."}
                onChange={event => {
                  setTestReason(event.target.value);
                }}
                value={testReason}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Result for test(s) done</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={testResultError}
                helperText={testResultError && "This field is mandatory."}
                onChange={event => {
                  setTestResult(event.target.value);
                }}
                value={testResult}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={tableCell}>
          <Grid container item spacing={"2px"} flexDirection={"column"}>
            <Grid item xs={5} className={infoGrid}>
              <Box fontWeight="bold">Type of Treatment</Box>
            </Grid>
            <Grid item xs={7} className={infoGrid}>
              <TextField
                id="standard-basic"
                variant="standard"
                className={textFieldRed}
                required
                error={typeOfTreatmentError}
                helperText={typeOfTreatmentError && "This field is mandatory."}
                onChange={event => {
                  setTypeOfTreatment(event.target.value);
                }}
                value={typeOfTreatment}
              />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <Grid item xs={5} className={infoGrid}>
          <Box fontWeight="bold">
            Please upload a copy of Medical Report if it is available.
          </Box>
        </Grid>
      </TableRow>
      <Button
        variant="outlined"
        sx={{ ...saveButton }}
        onClick={updateUserHealthDeclaration}
      >
        Save
      </Button>
    </Box>
  );
};
