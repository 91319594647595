/**
  @typedef {{
    value: number;
    unit: "days"|"years"
  }} AgeInfo
 */

/**
  @typedef {{
    min: AgeInfo;
    max: AgeInfo;
  }} AgeRange
 */

/**
  @typedef {{
    existingInsured: AgeRange;
    nonExistingInsured: AgeRange;
  }} AgeRangeWithInsuredType
 */

export const generalAgeRange = {
  dependant: {
    child: {
      min: {
        value: 15,
        unit: "days"
      },
      max: {
        value: 25,
        unit: "years"
      }
    },
    spouse: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 64,
        unit: "years"
      }
    }
  },
  employee: {
    min: {
      value: 16,
      unit: "years"
    },
    max: {
      value: 64,
      unit: "years"
    }
  }
};

export const pruAgencyAgeRange = {
  dependant: {
    spouse: {
      existingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 74,
          unit: "years"
        }
      },
      nonExistingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 65,
          unit: "years"
        }
      }
    }
  },
  employee: {
    existingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 74,
        unit: "years"
      }
    },
    nonExistingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 65,
        unit: "years"
      }
    }
  }
};

export const pruAgencyMedAgeRange = {
  dependant: {
    spouse: {
      existingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 74,
          unit: "years"
        }
      },
      nonExistingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 64,
          unit: "years"
        }
      }
    }
  },
  employee: {
    existingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 74,
        unit: "years"
      }
    },
    nonExistingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 64,
        unit: "years"
      }
    }
  }
};

export const siaAgeRange = {
  dependant: {
    spouse: {
      existingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 75,
          unit: "years"
        }
      },
      nonExistingInsured: {
        min: {
          value: 16,
          unit: "years"
        },
        max: {
          value: 65,
          unit: "years"
        }
      }
    },
    child: {
      general: {
        min: {
          value: 15,
          unit: "days"
        },
        max: {
          value: 26,
          unit: "years"
        }
      }
    }
  },
  employee: {
    existingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 75,
        unit: "years"
      }
    },
    nonExistingInsured: {
      min: {
        value: 16,
        unit: "years"
      },
      max: {
        value: 65,
        unit: "years"
      }
    }
  }
};
